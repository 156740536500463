import { getAmplifyConfig } from "./amplifyConfig";

const config = {
  apiGateway: {
    dev: {
      REGION: "us-east-1",
      mercuryApiKey: "AfZHBkwrQa1PA1zj9qYSL9vd93Wsx0Ud1xDvnWiD",
      mercuryApiUrl: "http://127.0.0.1:5000",
      typesenseServerEndpoint: "0za1pthslk.execute-api.us-east-1.amazonaws.com",
      typesenseServerPort: "443",
      typesenseServerProtocol: "https",
      // typesenseServerEndpoint: 'ec2-3-234-227-38.compute-1.amazonaws.com',
      // typesenseServerProtocol: 'http',
      // typesenseServerPort: '8108',
      typesenseServerApiKey: "4Gcqw2jFFLKir6hB8j96fhkrCLGSH7ACIJVFXog0WazFrO4m",
    },
    prod: {
      REGION: "us-east-1",
      mercuryApiUrl: "https://gfnwhx62v0.execute-api.us-east-1.amazonaws.com",
      mercuryApiKey: "AfZHBkwrQa1PA1zj9qYSL9vd93Wsx0Ud1xDvnWiD",
      typesenseServerEndpoint: "0za1pthslk.execute-api.us-east-1.amazonaws.com",
      typesenseServerPort: "443",
      typesenseServerProtocol: "https",
      typesenseServerApiKey: "4Gcqw2jFFLKir6hB8j96fhkrCLGSH7ACIJVFXog0WazFrO4m",
    },
  },
};

export default config;
export { getAmplifyConfig };
