import { useSnackbar } from "notistack";
import { Divider, ListItem, makeStyles } from "@material-ui/core";

import { emailToName, prettyPrintDate } from "../utilities/TextUtilities";
import { addCompaniesToPlaylist } from "../utilities/ApiFetcher";

const useStyles = makeStyles({
  button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonName: {
    fontSize: 20,
  },
});

export const ListItemLink = ({ name, id, owner, updatedAt, companies, setOpen }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    addCompaniesToPlaylist(id, companies);
    setOpen(false);
    enqueueSnackbar("Company(s) added to playlist");
  };

  return (
    <>
      <ListItem button onClick={() => handleClick()} className={classes.button}>
        <span className={classes.buttonName}>{name}</span>{" "}
        <span>
          {emailToName(owner)}
          <br />
          {prettyPrintDate(updatedAt)}
        </span>
      </ListItem>
      <Divider />
    </>
  );
};
