import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { prettyPrintDate, prettyPrintInFormat, displayGrowth } from "../../utilities/TextUtilities";

const get_total_raised_last_year = ({ total_raised, last_financing_size, last_financing_date_mths_ago }) => {
  if (last_financing_date_mths_ago && last_financing_date_mths_ago < 12) {
    return total_raised - last_financing_size;
  }

  return total_raised;
};

const get_arr_last_year = ({ combined_size, yoy_growth, predicted_growth_range }) => {
  if (combined_size && yoy_growth) {
    return combined_size / (yoy_growth / 100 + 1);
  }

  if (combined_size && predicted_growth_range) {
    return combined_size / (predicted_growth_range / 100 + 1);
  }

  return combined_size;
};

export const get_employees_last_year = ({ employees, yoy_emp_growth }) => {
  if (employees && yoy_emp_growth) {
    return employees / (yoy_emp_growth / 100 + 1);
  }

  return employees / 1.2;
};

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const get_growth_rate_last_year = ({ combined_size, yoy_growth, predicted_growth_range }) => {
  if (combined_size < 5000000 && yoy_growth > 100) {
    return displayGrowth(predicted_growth_range * 10 * 1.5); // Assume small high growth companies have slowed down a bit
  }

  if (combined_size < 5000000 && yoy_growth <= 100) {
    return displayGrowth(predicted_growth_range * 10 * 0.75); // Assume small low growth companies have sped up a bit
  }

  return displayGrowth(predicted_growth_range / (randomIntFromInterval(0, 150) / 100 + 1));
};

const getDataLabelConfig = (color, labelFormat) => ({
  display: true,
  color,
  formatter: (value, context) => {
    return prettyPrintInFormat(value, labelFormat);
  },
  anchor: "end",
  // offset: 10,
  align: "start",
  labels: {
    title: {
      font: {
        weight: color === "black" ? "bold" : "normal",
        size: color === "black" ? 16 : 12,
      },
    },
  },
});

const options = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
    },
  },
  scales: {
    y: {
      ticks: {
        display: false,
      },
    },
  },
};

export const CompanyCardChart = ({ company, statLabel, statFormat, error, loading }) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const currentStats = {
    "Total Raised": company.total_raised,
    ARR: company.combined_size,
    "Growth Rate": company.yoy_growth,
    Employees: company.employees,
  };

  const lastYearStats = {
    "Total Raised": get_total_raised_last_year(company),
    ARR: get_arr_last_year(company),
    "Growth Rate": get_growth_rate_last_year(company),
    Employees: get_employees_last_year(company),
  };

  const lastYearLabels = getDataLabelConfig("white", statFormat);
  const lastYearValue = lastYearStats[statLabel];
  const thisYearLabels = getDataLabelConfig("black", statFormat);
  const thisYearValue = currentStats[statLabel];
  const currentGrowth = displayGrowth(company.predicted_growth_range);

  const fullStatLabel =
    statLabel === "Total Raised" || statLabel === "Employees"
      ? `${statLabel} (a.o. ${prettyPrintDate(company.last_major_data_update)})`
      : `${statLabel} (Growth: ${currentGrowth})`;

  return (
    <Bar
      data={{
        labels: [fullStatLabel],
        datasets: [
          {
            label: "Last Year",
            data: [lastYearValue],
            backgroundColor: "#5E636C",
            datalabels: lastYearLabels,
          },
          { label: "Current", data: [thisYearValue], backgroundColor: "#EC7E7E", datalabels: thisYearLabels },
        ],
      }}
      options={options}
      plugins={[ChartDataLabels]}
    />
  );
};
