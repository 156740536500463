import { makeStyles } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";

const useStyles = makeStyles({
  container: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centerLabel: {
    textAlign: "center",
    position: "absolute",
    fontSize: 40,
    fontWeight: "bolder",
  },
  centerSubLabel: {
    textAlign: "center",
    fontSize: 12,
    marginTop: -10,
    fontWeight: "bold",
  },
});

export const DoughnutChart = ({ data, error, loading, label, backgroundColors, centerLabel, centerSubLabel }) => {
  const styles = useStyles();
  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>{error.message}</div>;
  }
  if (!centerLabel)
    return (
      <Doughnut
        options={{
          plugins: {
            legend: { display: false },
          },
        }}
        data={{
          labels: data.labels,
          datasets: [
            {
              data: data.values,
              label,
              backgroundColor: backgroundColors,
            },
          ],
        }}
      />
    );

  return (
    <div className={styles.container}>
      <span className={styles.centerLabel}>
        {centerLabel}
        <p className={styles.centerSubLabel}>{centerSubLabel}</p>
      </span>
      <Doughnut
        options={{
          cutout: "60%",
          plugins: {
            legend: { display: false },
          },
        }}
        data={{
          labels: data.labels,
          datasets: [
            {
              data: data.values,
              label,
              backgroundColor: backgroundColors,
            },
          ],
        }}
      />
    </div>
  );
};
