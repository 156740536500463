import MaterialTable from "material-table";

import { Card, CardContent, Link, makeStyles, Typography } from "@material-ui/core";

import { narrowCellStyle, narrowHeaderStyle, tableIcons, wideCellStyle, wideHeaderStyle } from "./tableTools";
import React, { useEffect, useState } from "react";
import { getMarketInvestors, handleResponseErrors } from "../../utilities/ApiFetcher";
import { prettyPrintDollarAmounts, titleCase } from "../../utilities/TextUtilities";

export const createInvestorLinkFromName = (name) => {
  return (
    <Link href={"/investor/" + encodeURIComponent(name.toLowerCase())} target="_blank">
      {titleCase(name)}
    </Link>
  );
};

const COLUMNS = [
  {
    title: "Investor Name",
    field: "index",
    render: (row) => createInvestorLinkFromName(row.index),
    cellStyle: wideCellStyle,
    headerStyle: wideHeaderStyle,
  },
  {
    title: "Est. Invested ($)",
    render: (row) => prettyPrintDollarAmounts(row.est_total_invested),
    cellStyle: narrowCellStyle,
    headerStyle: narrowHeaderStyle,
  },
];

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "60%",
  },
  cardContent: {
    textAlign: "left",
  },
  title: {
    fontSize: 18,
    textAlign: "left",
    marginBottom: 10,
  },
  outlined: {
    border: "1px solid black",
  },
  centered: {
    textAlign: "center",
    marginBottom: 20,
  },
}));

export const MarketInvestorTable = ({ fid }) => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getTableData = async () => {
    setLoading(true);

    getMarketInvestors(fid)
      .then(({ investors }) => {
        setData(investors);
        setLoading(false);
      })
      .catch((error) => {
        const errorState = handleResponseErrors(error);
        setError(errorState);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTableData();
  }, []);

  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" color="black" className={classes.title}>
          Investors in Market
        </Typography>
        <MaterialTable
          icons={tableIcons}
          columns={COLUMNS}
          data={data}
          options={{
            toolbar: false,
            search: false,
            sorting: false,
            draggable: false,
            filtering: false,
            exportButton: false,
            pageSize: 8,
            pageSizeOptions: [8, 10, 20],
          }}
        />
      </CardContent>
    </Card>
  );
};
