import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Configure, InstantSearch, SearchBox } from "react-instantsearch-hooks-web";
import { CustomHitsWrapper } from "./HomepageSearch";

import { TYPESENSE_SERVER_CONFIG } from "../utilities/typesenseUtils";
import { makeStyles } from "@material-ui/core";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy: "name,description",
    queryByWeights: "10,2",
    numTypos: 1,
    typoTokensThreshold: 1,
    sortBy: "_text_match(buckets: 10):desc",
  },
});

const useStyles = makeStyles({
  search: {
    width: "550px",
    marginLeft: ".25rem",
    "& input": {
      width: "100%",
      fontSize: "1.25rem",
      backgroundColor: "gainsboro",
      border: "none !important",
      borderRadius: "999px",
    },
    "& input:focus": {
      backgroundColor: "gainsboro",
    },
  },
});

export function PlaylistSearchBar({ searchTerm }) {
  const classes = useStyles();
  if (searchTerm === "") {
    return null;
  }

  return (
    <InstantSearch searchClient={typesenseInstantsearchAdapter.searchClient} indexName="companies" routing>
      <Configure hitsPerPage={6} />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "600px" }}>
        <span style={{ fontSize: "2rem" }}>🔍</span>
        <SearchBox className={classes.search} placeholder="Search Lists..." searchAsYouType={true} />{" "}
        <CustomHitsWrapper includePlaylists={true} includeCompanies={false} />
      </div>
    </InstantSearch>
  );
}
