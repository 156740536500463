import { Search as SearchIcon } from "@material-ui/icons";
import { useHistory, useLocation, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, makeStyles, Box } from "@material-ui/core";

import { useUserContext } from "../contexts";
import Logo from "../pages/assets/appbar-mercury-logo.png";
import { AppHeaderSearch } from "./AppHeaderSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#6565ee",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
  navLink: {
    textDecoration: "none",
    color: "rgba(255,255,255,0.85)",
    cursor: "pointer",
    display: "block",
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "rgba(255,255,255,0.7)",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 4),
    height: "100%",
  },
  littleSpace: {
    marginRight: theme.spacing(4),
    cursor: "pointer",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarSection: {
    display: "flex",
    alignItems: "center",
  },
}));

export function AppHeader() {
  const classes = useStyles();
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const { isAuthenticated, handleLogOutAttempt } = useUserContext();
  const history = useHistory();

  return (
    <AppBar position="static" sx={{ bgcolor: "#6565ee" }}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarSection}>
          <Box
            component="img"
            sx={{
              height: 64,
            }}
            alt="Mercury Logo"
            src={Logo}
            className={classes.littleSpace}
            onClick={() => history.push("/")}
          />
          <Link to="/all-recommended" className={classes.navLink}>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ color: location.pathname === "/all-recommended" ? "white" : "" }}
            >
              Recommended
            </Typography>
          </Link>
          <Link to="/playlists" className={classes.navLink}>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ color: location.pathname === "/playlists" ? "white" : "" }}
            >
              Lists
            </Typography>
          </Link>
          <Link to="/stats" className={classes.navLink}>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ color: location.pathname === "/stats" ? "white" : "" }}
            >
              Stats
            </Typography>
          </Link>
          <Link to="/search" className={classes.navLink}>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ color: location.pathname === "/search" ? "white" : "" }}
            >
              Search
            </Typography>
          </Link>
        </div>
        <div className={classes.toolbarSection}>
          <div className={classes.search}>
            {/* TODO: This is probably where the interactive search bar hooked up to typesense should go */}
            {/*<div className={classes.searchIcon} onClick={() => searchHandle("searchButton")}>*/}
            {/*  {isLoading ? <CircularProgress color="white" size="25px" /> : <SearchIcon />}*/}
            {/*</div>*/}
            <div className={classes.searchIcon}>
              {/*<Link to="/search" className={classes.navLink}>*/}
              {/*  <SearchIcon />*/}
              {/*</Link>*/}
              {!isHomePage && <AppHeaderSearch />}
            </div>
          </div>
          {isAuthenticated && (
            <Button color="inherit" onClick={handleLogOutAttempt}>
              Logout
            </Button>
          )}
          {!isAuthenticated && (
            <Button color="inherit" onClick={() => history.push("/login")}>
              Login
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}
