import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core";
import { Info as InfoIcon } from "@mui/icons-material";
import { createUrlLinkForKeyword } from "../utilities/navigationUtils";
import { getOrderedKeywords } from "./keywordChips";
import ColoredChip from "./ColoredChip";
import React, { useState } from "react";
import { SalesforceHistoryModalController } from "./SalesforceHistoryModal";

const useStyles = makeStyles({
  companyCardRoot: {
    padding: 10,
    paddingLeft: 20,
    margin: 30,
    color: "black",
  },
  standardFont: {
    fontFamily: "Mukta",
    fontWeight: 400,
  },
  keywordChips: {
    marginBottom: 30,
  },
  nameWithLink: {
    display: "inline-flex",
    marginTop: 10,
    padding: 5,
  },
  chartsTitle: {
    width: "30%",
    margin: "auto",
    textAlign: "center",
    marginBottom: 20,
  },
  actionStack: {
    marginTop: 40,
  },
  standardButton: {
    fontFamily: "Mukta",
    fontWeight: 700,
    color: "white",
  },
  apolloButton: {
    backgroundColor: "#faa960",
  },
});

const cleanChips = (ogChips, totalCount) => {
  const filteredChips = ogChips.filter((chip) => chip !== undefined);
  const dedupedChips = filteredChips.reduce((result, element) => {
    var normalize = (x) => (typeof x === "string" ? x.toLowerCase() : x);
    var normalizedElement = normalize(element.label);
    if (result.every((otherElement) => normalize(otherElement.label) !== normalizedElement)) result.push(element);

    return result;
  }, []);

  const slicedChips = dedupedChips.slice(0, totalCount);
  return slicedChips;
};

const getSalesforceChip = (company) => {
  const { sf_lead_status, latest_actual_arr } = company;
  if (!sf_lead_status || sf_lead_status === "None") {
    return undefined;
  }

  // N.B. Using this as a kind of a proxy for whether something was called vs. just emailed...
  if (latest_actual_arr) {
    return { label: "Called", color: "#184719", sfChip: true, hasSfHistory: true };
  }

  return { label: "Contacted", sfChip: true, color: "#4B8A4C" };
};

export const CompanyCardChips = ({ company, maxCount }) => {
  const businessModelChip =
    company.business_model === "Marketplace"
      ? { label: "Marketplace", color: "#011936" }
      : company.business_model === "SaaS"
      ? { label: "SaaS", color: "#5C5ADF" }
      : company.business_model === "Payments"
      ? { label: "Payments", color: "#8EE1B8" }
      : undefined;

  const bootstrappedChip = company?.total_raised < 1000000 ? { label: "Bootstrapped", color: "#EC7E7E" } : undefined;
  const salesforceChip = getSalesforceChip(company);
  const orderedKeywords = getOrderedKeywords(company.keywords, maxCount, 90);
  const companyKeywords = orderedKeywords.map((keyword) => ({ label: keyword }));
  const allChips = [businessModelChip, bootstrappedChip, salesforceChip, ...companyKeywords];
  const cleanedChips = cleanChips(allChips, maxCount);

  return (
    <Stack direction="row" spacing={1}>
      {cleanedChips.map((wordChip, index) =>
        wordChip.sfChip ? (
          <SalesforceChip
            company={company}
            label={wordChip.label}
            color={wordChip.color}
            key={index}
            hasSfHistory={wordChip.hasSfHistory}
          />
        ) : (
          <MyStandardChip label={wordChip.label} color={wordChip.color} key={index} />
        )
      )}
    </Stack>
  );
};

const MyStandardChip = ({ label, color }) => {
  const classes = useStyles();

  const chipColor = color ? color : "#5E636C";

  return (
    <ColoredChip
      className={classes.standardFont}
      label={label}
      component="a"
      href={createUrlLinkForKeyword(label)}
      clickable
      target="_blank"
      color={chipColor}
    />
  );
};

const SalesforceChip = ({ company, label, color, hasSfHistory }) => {
  const classes = useStyles();
  const [sfHistoryModalOpen, setSfHistoryModalOpen] = useState(false);
  const { fid, name } = company;

  const handleSalesforceChipClick = () => {
    setSfHistoryModalOpen(true);
  };

  return (
    <>
      <ColoredChip
        className={classes.standardFont}
        label={label}
        component="a"
        clickable
        color={color}
        onClick={hasSfHistory ? handleSalesforceChipClick : undefined}
        icon={hasSfHistory ? <InfoIcon /> : undefined}
      />
      <SalesforceHistoryModalController
        open={sfHistoryModalOpen}
        setOpen={setSfHistoryModalOpen}
        fid={fid}
        companyName={name}
      />
    </>
  );
};
