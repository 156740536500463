import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Configure, Index, InstantSearch, SearchBox, useHits } from "react-instantsearch-hooks-web";

import { TYPESENSE_SERVER_CONFIG } from "../utilities/typesenseUtils";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { handleLinkClick } from "../utilities/navigationUtils";

const typesenseInstantsearchCompanyAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy: "name,description",
    queryByWeights: "20,2",
    numTypos: 1,
    typoTokensThreshold: 1,
  },
});

const useStyles = makeStyles({
  search: {
    width: "550px",
    marginLeft: ".25rem",
    "& input": {
      width: "100%",
      fontSize: "1.25rem",
      backgroundColor: "gainsboro",
      border: "none !important",
      borderRadius: "999px",
    },
    "& input:focus": {
      backgroundColor: "gainsboro",
    },
  },
  centerColumn: { display: "flex", flexDirection: "column", alignItems: "center", marginTop: "1rem" },
  searchRow: { display: "flex", alignItems: "center", width: "100%", marginTop: "1rem" },
  customHitsWrapperRoot: {
    position: "absolute",
    zIndex: 100,
    backgroundColor: "white",
    color: "black",
    marginTop: "3.5rem",
    width: "550px",
    marginLeft: "50px",
    borderRadius: "2rem",
  },
  customHits: {
    color: "black",
    position: "absolute",
    zIndex: 100,
    backgroundColor: "white",
    marginTop: "4rem",
    width: "550px",
    marginLeft: "50px",
    padding: "1rem",
    borderRadius: "2rem",
  },
});

export function HomepageSearch({ searchTerm }) {
  const classes = useStyles();
  if (searchTerm === "") {
    return null;
  }

  return (
    <InstantSearch searchClient={typesenseInstantsearchCompanyAdapter.searchClient} indexName="companies" routing>
      <Configure hitsPerPage={6} />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "600px" }}>
        <span style={{ fontSize: "2rem" }}>🔍</span>
        <SearchBox className={classes.search} placeholder="Search Companies & Playlists..." searchAsYouType={true} />
        <CustomHitsWrapper includeCompanies={true} includePlaylists={true} />
      </div>
    </InstantSearch>
  );
}

export function CustomHitsWrapper(props) {
  const classes = useStyles();
  const { includeCompanies, includePlaylists } = props;

  return (
    <div className={classes.customHitsWrapperRoot}>
      {includeCompanies && (
        <Index indexName="companies">
          <CustomHits {...props} subtitle="Companies" showMore={true} isPlaylist={false} />
        </Index>
      )}
      {includePlaylists && (
        <Index indexName="playlists">
          <CustomHits {...props} subtitle="Playlists" isPlaylist={true} />
        </Index>
      )}
    </div>
  );
}

export function CustomHits(props) {
  const { hits, results } = useHits(props);
  if (results.query === "*") {
    return null;
  }

  if (hits.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "1rem",
      }}
    >
      <h3>{props.subtitle}</h3>
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        {hits.slice(0, 5).map((hit) => (
          <li key={hit.id}>
            <Hit hit={hit} {...props} />
          </li>
        ))}
        {hits.length > 5 && props.showMore && (
          <Link to={`/search?query=${results.query !== "*" ? results.query : ""}`}>
            <li style={{ fontWeight: "bold", fontStyle: "italic" }}>See More...</li>
          </Link>
        )}
      </ul>
    </div>
  );
}

function Hit({ hit, isPlaylist }) {
  const linkUrl = isPlaylist ? `/playlists/${hit.id}` : `/company/${hit.id}`;

  return (
    <>
      <Link onClick={handleLinkClick(linkUrl, false)}>
        <span>{hit.name}</span>
      </Link>
      <span>
        {" "}
        {hit.description.slice(0, 100)}
        {hit.description.length > 0 && "..."}
      </span>
    </>
  );
}
