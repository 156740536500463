import { Grid, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.h1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  buttons: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  headerGrid: {
    paddingBottom: theme.spacing(3),
  },
}));

export const PlaylistPageHeader = ({ setCreateModalOpen }) => {
  const classes = useStyles();

  return (
    <Grid container={true} className={classes.headerGrid}>
      <Grid item={true} xs={8} sx={8} md={8} lg={8} xl={8}>
        <div className={classes.intro}>
          <h1 className={classes.title}>Company Playlists</h1>
        </div>
      </Grid>
      <Grid className={classes.buttons} item={true} xs={4} sx={4} md={4} lg={4} xl={4}>
        <div className={classes.buttons}>
          <Button variant="contained" color="secondary" onClick={() => setCreateModalOpen(true)}>
            Create Playlist
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
