import { Pie } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core";

import { prettyPrintPercent } from "../../utilities/TextUtilities";

const useStyles = makeStyles((theme) => ({
  pieRoot: {
    textAlign: "center",
    padding: theme.spacing(2),
    width: 220,
    height: 100,
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(4),
    fontSize: 52,
  },
}));

// TODO: fix market_comps
const PIE_COLORS = ["#6565EE", "#001242", "#83C5BE", "#040F16", "#280385", "#F03A47"];

const OTHER_COLORS = ["#6565EE", "#001242", "#83C5BE", "#040F16", "#280385", "#F0D2D1"];

const PIE_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Estimated Market Share",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const { label, parsed } = context;
          return label + ": " + prettyPrintPercent(parsed * 100);
        },
      },
    },
  },

  elements: {
    arc: {
      borderWidth: 1,
      borderColor: "#040F16",
    },
  },
};

export const MarketShareChart = ({ original_co, stats }) => {
  const classes = useStyles();
  const { market_share } = stats;
  const { fid } = original_co;
  const ordered_market_share = market_share
    .filter((x) => x.fid !== fid)
    .concat(market_share.filter((x) => x.fid === fid));
  const hasOriginalIncluded = market_share.filter((x) => x.fid === fid).length > 0;

  const labels = ordered_market_share ? ordered_market_share.map((x) => x.name) : [];
  const values = ordered_market_share ? ordered_market_share.map((x) => x.market_share) : [];

  const data = {
    labels,
    maintainAspectRatio: true,
    responsive: true,
    datasets: [
      {
        label: "Market Share (by Revenue)",
        data: values,
        backgroundColor: hasOriginalIncluded ? PIE_COLORS : OTHER_COLORS,
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className={classes.pieRoot}>
      <Pie data={data} options={PIE_OPTIONS} />
    </div>
  );
};
