import { useState } from "react";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import { Grid, IconButton, makeStyles } from "@material-ui/core";

import { StandardText } from "./Text";

const useStyles = makeStyles((theme) => ({
  expandableRoot: {
    marginBottom: 10,
  },
}));

export function CollapsibleDescription({ description, sliceLength }) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const sliceEnd = sliceLength ? sliceLength : 120;
  const threshold = sliceLength * 0.8;

  if (!description) {
    return null;
  }

  const toggleOpenDescription = (event) => {
    event.preventDefault();
    setOpen(!isOpen);
  };

  const isLongDescription = description.length > threshold;
  const fullDescription = description ? description : "No Description Available";
  const descriptionText = isOpen || !isLongDescription ? fullDescription : fullDescription.slice(0, sliceEnd) + "...";

  return (
    <div className={classes.expandableRoot}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <StandardText text={descriptionText} />
        </Grid>

        {isLongDescription && (
          <Grid item xs={1}>
            <IconButton onClick={toggleOpenDescription} style={{ color: "black" }}>
              <MoreVertIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
