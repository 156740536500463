import { useState, useCallback, useEffect } from "react";

import { requestRecommendedLeads, handleResponseErrors } from "../utilities/ApiFetcher";
import { LoadingCircle } from "../components/loadingCircle";
import { LeadsList } from "../components/leadsList";

export function NewLeadsTable() {
  const [newLeads, setNewLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const doGetNewLeads = useCallback(async () => {
    setIsLoading(true);
    requestRecommendedLeads()
      .then((leads) => {
        if (leads.result) {
          setNewLeads(leads.result);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const errorState = handleResponseErrors(error);
        setError(errorState);
        setIsLoading(false);
      });
  }, []);

  const removeLeadsFromTable = (fids) => {
    const currentLeads = newLeads;
    const filteredLeads = currentLeads.filter((l) => !fids.includes(l.fid));
    setNewLeads(filteredLeads);
  };

  useEffect(() => {
    doGetNewLeads();
  }, []);

  if (isLoading) {
    return <LoadingCircle />;
  }

  if (!isLoading && error) {
    return <div>{error.message}</div>;
  }

  return <LeadsList listLeads={newLeads} removeLeadsFromTable={removeLeadsFromTable} />;
}
