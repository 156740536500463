import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Configure, Index, InstantSearch, SearchBox, useHits } from "react-instantsearch-hooks-web";

import { TYPESENSE_SERVER_CONFIG } from "../utilities/typesenseUtils";
import { makeStyles } from "@material-ui/core";
import { CustomHits } from "./HomepageSearch";

const typesenseInstantsearchCompanyAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy: "name,description",
    queryByWeights: "20,2",
    numTypos: 1,
    typoTokensThreshold: 1,
  },
});

const useStyles = makeStyles({
  search: {
    width: "250px",
    marginLeft: ".25rem",
    "& input": {
      width: "100%",
      fontSize: "1.25rem",
      backgroundColor: "gainsboro",
      border: "none !important",
      borderRadius: "999px",
    },
    "& input:focus": {
      backgroundColor: "gainsboro",
    },
  },
  appHeaderCustomHitsWrapperRoot: {
    position: "absolute",
    zIndex: 10,
    // left: -500,
    right: -20,
    backgroundColor: "white",
    color: "black",
    marginTop: "2rem",
    width: "500px",
    marginRight: "50px",
    borderRadius: "2rem",
  },
});

export function AppHeaderSearch({ searchTerm }) {
  const classes = useStyles();
  if (searchTerm === "") {
    return null;
  }

  return (
    <InstantSearch searchClient={typesenseInstantsearchCompanyAdapter.searchClient} indexName="companies" routing>
      <Configure hitsPerPage={6} />
      <div>
        <SearchBox className={classes.search} placeholder="Search..." searchAsYouType={true} />{" "}
        <CustomHitsWrapper includeCompanies={true} includePlaylists={true} />
      </div>
    </InstantSearch>
  );
}

export function CustomHitsWrapper(props) {
  const classes = useStyles();
  const { includeCompanies, includePlaylists } = props;

  return (
    <div className={classes.appHeaderCustomHitsWrapperRoot}>
      {includeCompanies && (
        <Index indexName="companies">
          <CustomHits {...props} subtitle="Companies" showMore={true} isPlaylist={false} />
        </Index>
      )}
      {includePlaylists && (
        <Index indexName="playlists">
          <CustomHits {...props} subtitle="Playlists" isPlaylist={true} />
        </Index>
      )}
    </div>
  );
}
