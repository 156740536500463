import { React, useState } from "react";
import { Avatar, Card, CardContent, CardHeader, Grid, IconButton, makeStyles } from "@material-ui/core";
import { KeyboardArrowDown as DownIcon, KeyboardArrowUp as UpIcon } from "@mui/icons-material";
import { Collapse, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { CollapsibleDescription } from "./collapsibleDescription";
import { SalesforceHistoryModalComponent as SFhistory } from "./SalesforceHistoryModal";
import { InspectableBigStatDisplay } from "./InspectableBigStatDisplay";
import { CompanySubtitle, Title } from "./Text";
import { prettyPrintDate, prettyPrintDollarAmounts, prettyPrintLocation } from "../utilities/TextUtilities";
import {
  CompanyNameWithLink,
  InvestorList,
  parseCommaSeparatedInvestorsIntoList,
  parseJsonInvestorsIntoList,
} from "./CompanyCard";
import { get_employees_last_year } from "./charts/CompanyCardChart";
import { CompanyHistoryChartContainer } from "../containers/charts/CompanyHistoryChartContainer";
import { CompanyCardChips } from "./CompanyCardChips";
import { ExternalCompanyLinks } from "./ExternalCompanyLinks";
import { CompanyTabComps as CompanyCompetitorsContainer } from "../containers/companyTabs/companyTabComps";

const useStyles = makeStyles({
  companyDetailsCard: {
    borderRadius: 10,
    fontSize: 16,
    fontFamily: "Mukta",
    alignItems: "center",
  },
});

export const CompanyCompetitorsPageComponent = ({ companyBasics }) => {
  const classes = useStyles();
  const { fid } = companyBasics;

  return (
    <Card className={classes.companyDetailsCard}>
      <CardContent>
        <CompanyCompetitorsContainer fid={fid} companyBasics={companyBasics} />
      </CardContent>
    </Card>
  );
};
