import { Pie } from "react-chartjs-2";

import { prettyPrintDollarAmounts } from "../../utilities/TextUtilities";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pieRoot: {
    textAlign: "center",
    padding: theme.spacing(2),
    width: 250,
    height: 120,
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(4),
    fontSize: 52,
  },
}));

// const PIE_COLORS = ["#6565EE", "#001242", "#83C5BE", "#040F16", "#280385", "#F03A47"];

const OTHER_COLORS = ["#6565EE", "#001242", "#83C5BE", "#040F16", "#280385", "#F0D2D1"];

export const PIE_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Investment by Country",
    },
    datalabels: {
      display: true,
      color: "white",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const { label, parsed } = context;
          return label + ": " + prettyPrintDollarAmounts(parsed);
        },
      },
    },
  },

  elements: {
    arc: {
      borderWidth: 1,
      borderColor: "#040F16",
    },
  },
};

export const InvestmentByCountryChart = ({ stats }) => {
  const classes = useStyles();
  const { investment_by_country } = stats;
  const labels = investment_by_country?.map((x) => x.country);
  const y_vals = investment_by_country?.map((x) => x.total_raised);

  const data = {
    labels,
    maintainAspectRatio: true,
    responsive: true,
    datasets: [
      {
        label: "Total Investment ($)",
        data: y_vals,
        backgroundColor: OTHER_COLORS,
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className={classes.pieRoot}>
      <Pie options={PIE_OPTIONS} data={data} />
    </div>
  );
};
