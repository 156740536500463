import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { getCompaniesList, getPlaylist, removePlaylistCompanies } from "../utilities/ApiFetcher";
import { PlaylistDetail as PlaylistDetailComponent } from "../components/PlaylistDetail";

export const PlaylistDetail = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [playlist, setPlaylist] = useState(null);
  const [playlistCompanies, setPlaylistCompanies] = useState([]);
  const [playlistUpdated, setPlaylistUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fn = async () => {
      const playlist = await getPlaylist(id);
      const locators = playlist["companies"];
      if (locators && locators.length > 0) {
        setPlaylistCompanies(await getCompaniesList(locators));
      }
      setPlaylist(playlist);
      setIsLoading(false);
      setPlaylistUpdated(false);
    };
    fn();
  }, [id, playlistUpdated]);

  const removeLeadsFromList = async (fidList) => {
    await removePlaylistCompanies(id, fidList);
    enqueueSnackbar("Successfully removed from playlist!", { variant: "success" });
    setPlaylistUpdated(true);
  };

  return (
    <PlaylistDetailComponent
      isLoading={isLoading}
      playlistCompanies={playlistCompanies}
      playlist={playlist}
      setPlaylistUpdated={setPlaylistUpdated}
      removeLeadsFromList={removeLeadsFromList}
    />
  );
};
