import MaterialTable from "material-table";

import { cellStyle, headerStyle } from "./tableTools";
import { useEffect, useState } from "react";
import { handleResponseErrors, requestFlagStatsTable } from "../../utilities/ApiFetcher";

const COLUMNS = [
  {
    title: "Recommendation Reason",
    field: "recommendation_reason",
    cellStyle,
    headerStyle,
  },
  {
    title: "Count",
    field: "count",
    cellStyle,
    headerStyle,
  },
];

export const FlagStatsTable = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getTableData = async () => {
    setLoading(true);
    try {
      const data = await requestFlagStatsTable();
      setData(data);
      setLoading(false);
    } catch (err) {
      setError(handleResponseErrors(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <MaterialTable
      columns={COLUMNS}
      data={data}
      options={{
        toolbar: false,
        showTitle: false,
        search: false,
        paging: false,
        sorting: false,
        draggable: false,
        filtering: false,
        exportButton: false,
      }}
    />
  );
};
