import { Scatter } from "react-chartjs-2";
import { prettyPrintDollarAmounts, prettyPrintPercent } from "../../utilities/TextUtilities";

const options = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Revenue x Growth",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const { raw } = context;
          const { name, x, y, total_raised, employees } = raw;
          const labels = [
            name,
            "",
            `Est. Rev: ${prettyPrintDollarAmounts(x)}`,
            `Est. Growth: ${prettyPrintPercent(y)}`,
            `Total Raised: ${prettyPrintDollarAmounts(total_raised)}`,
            `Employees: ${employees}`,
          ];
          return labels;
        },
      },
    },
  },
  scales: {
    left: {
      type: "linear",
      position: "bottom",
      title: {
        display: true,
        text: "Estimated Revenue",
      },
      ticks: {
        beginAtZero: true,
        callback: function (value) {
          return `$${value / 1000000}m`;
        },
        suggestedMin: 0,
      },
    },
    right: {
      type: "linear",
      position: "left",
      title: {
        display: true,
        text: "Estimated Growth",
      },
      ticks: {
        callback: function (value) {
          return `${value}%`;
        },
        suggestedMin: -25,
      },
    },
  },
};

export const RevenueAndGrowthScatterChart = ({ original_co, stats, height }) => {
  const { rev_and_growth } = stats;
  const revAndGrowthForChart = rev_and_growth?.map((x) => ({
    x: x.combined_size,
    y: x.predicted_growth_range,
    ...x,
  }));
  const original = revAndGrowthForChart?.filter((x) => x.fid === original_co.fid);
  const others = revAndGrowthForChart?.filter((x) => x.fid !== original_co.fid);

  const data = {
    datasets: [
      {
        data: others,
        backgroundColor: "#6565EE",
      },
    ],
  };

  if (original && original.length > 0) {
    data.datasets.push({
      data: original,
      backgroundColor: "#F03A47",
      pointRadius: 3,
    });
  }

  return <Scatter redraw={true} data={data} options={options} type="scatter" height={height} />;
};
