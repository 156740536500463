import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    fontFamily: "Mukta",
    fontSize: 20,
  },
  subtitle: {
    fontFamily: "Mukta",
    fontSize: 14,
  },
  standardText: {
    fontFamily: "Nunito",
    fontSize: 14,
  },
  miniText: {
    fontFamily: "Nunito",
    fontSize: 12,
  },
  bullet: {
    fontFamily: "Nunito",
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  companyTitle: {
    fontFamily: "Mukta",
    fontSize: 30,
  },
  companySubtitle: {
    fontFamily: "Mukta",
    fontSize: 18,
  },
});

export const Title = ({ text, children }) => {
  const classes = useStyles();
  const titleText = text ? text : children;

  return (
    <Typography variant="h5" className={classes.title}>
      {titleText}
    </Typography>
  );
};

export const Subtitle = ({ text, children }) => {
  const classes = useStyles();
  const subtitleText = text ? text : children;
  return (
    <Typography variant="subtitle2" className={classes.subtitle}>
      {subtitleText}
    </Typography>
  );
};

export const StandardText = ({ text }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.standardText} color="textPrimary" gutterBottom>
      {text}
    </Typography>
  );
};

export const MiniText = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.miniText} color="textPrimary" gutterBottom>
      {children}
    </Typography>
  );
};

export const BulletText = ({ text }) => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Typography className={classes.standardText}>
      {bull} {text}
    </Typography>
  );
};

export const CompanyTitle = ({ text, children }) => {
  const classes = useStyles();
  const companyTitle = text ? text : children;

  return (
    <Typography variant="h3" className={classes.companyTitle}>
      {companyTitle}
    </Typography>
  );
};

export const CompanySubtitle = ({ text, children }) => {
  const classes = useStyles();
  const companySubtitle = text ? text : children;

  return (
    <Typography variant="h3" className={classes.companySubtitle}>
      {companySubtitle}
    </Typography>
  );
};
