import { makeStyles } from "@material-ui/core";

import { Subtitle, MiniText } from "./Text";
import { prettyPrintPercent } from "../utilities/TextUtilities";

const useStyles = makeStyles((_theme) => ({
  root: {
    marginLeft: 8,
    marginTop: 5,
    textAlign: "right",
  },
}));

export function MercuryHitRateStats({ stats }) {
  const classes = useStyles();

  const combined = stats["combined"];
  const selfFound = stats["Self Found"];
  const mercury = stats["Mercury"];
  const referrals = stats["Referral"];

  return (
    <div className={classes.root}>
      <Subtitle>
        <strong>Trailing 3mth Hit Rate</strong>
      </Subtitle>
      {combined && (
        <MiniText>
          All Leads Count: <strong>{combined.total_leads}</strong>, Hit Rate:{" "}
          <strong>{prettyPrintPercent(combined.percent_in_box)}</strong>
        </MiniText>
      )}
      {mercury && (
        <MiniText>
          Mercury Count: <strong>{mercury.num_leads}</strong>, Hit Rate:{" "}
          <strong>{prettyPrintPercent(mercury.percent_in_box)}</strong>
        </MiniText>
      )}
      {selfFound && (
        <MiniText>
          Self Found Count: <strong>{selfFound.num_leads}</strong>, Hit Rate:{" "}
          <strong>{prettyPrintPercent(selfFound.percent_in_box)}</strong>
        </MiniText>
      )}
      {selfFound && (
        <MiniText>
          All Referrals Count: <strong>{referrals.num_leads}</strong>, Hit Rate:{" "}
          <strong>{prettyPrintPercent(referrals.percent_in_box)}</strong>
        </MiniText>
      )}
    </div>
  );
}
