import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import { Title } from "./Text";

export function TimedLoadingBar({ stages, expectedDuration, size }) {
  const [clockTimer, setClockTimer] = React.useState(0);
  const frequency = expectedDuration * 10; // Shorthand for (duration * 1000) = duration_ms, duration_ms / 100 stages

  React.useEffect(() => {
    const timer = setInterval(() => {
      setClockTimer((prevProgress) => (prevProgress >= 98 ? 98 : prevProgress + 1));
    }, frequency);
    return () => {
      clearInterval(timer);
    };
  }, [frequency]);

  const currentStage = stages.filter((value) => value.start <= clockTimer).at(-1);
  const { label } = currentStage;

  return (
    <div>
      <Title text={label} />
      <LinearProgressWithLabel value={clockTimer} />
    </div>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1, color: "primary" }}>
        <LinearProgress color="primary" variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="primary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
