import { React, useState } from "react";
import { Avatar, Card, CardContent, CardHeader, Grid, IconButton, makeStyles } from "@material-ui/core";
import { KeyboardArrowDown as DownIcon, KeyboardArrowUp as UpIcon } from "@mui/icons-material";
import { Collapse, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { CollapsibleDescription } from "./collapsibleDescription";
import { SalesforceHistoryModalComponent as SFhistory } from "./SalesforceHistoryModal";
import { InspectableBigStatDisplay } from "./InspectableBigStatDisplay";
import { CompanySubtitle, Title } from "./Text";
import {
  prettyPrintDate,
  prettyPrintDollarAmounts,
  prettyPrintLocation,
  displayGrowth,
  displayRevenueRange,
} from "../utilities/TextUtilities";
import {
  CompanyNameWithLink,
  InvestorList,
  parseCommaSeparatedInvestorsIntoList,
  parseJsonInvestorsIntoList,
} from "./CompanyCard";
import { get_employees_last_year } from "./charts/CompanyCardChart";
import { CompanyHistoryChartContainer } from "../containers/charts/CompanyHistoryChartContainer";
import { CompanyCardChips } from "./CompanyCardChips";
import { ExternalCompanyLinks } from "./ExternalCompanyLinks";

const useStyles = makeStyles({
  companyDetailsCard: {
    padding: 40,
    borderRadius: 10,
    fontSize: 16,
    fontFamily: "Mukta",
    alignItems: "center",
  },
  cardHeaderRight: {
    marginTop: 10,
    marginLeft: 50,
    alignItems: "right",
    justifyContent: "center",
  },
  companyCardChips: {
    marginTop: 25,
    marginBottom: 25,
  },
  basicInfoModule: {
    width: "90%",
    marginTop: 25,
    marginBottom: 25,
    padding: 20,
    backgroundColor: "#D9D9D9",
    borderRadius: 10,
  },
  fundingInfoModule: {
    width: "90%",
    marginTop: 25,
    padding: 20,
    backgroundColor: "#D9D9D9",
    borderRadius: 10,
  },
  statSection: {
    marginTop: 15,
  },
  historyChartModule: {
    margin: "auto",
    padding: "auto",
    borderRadius: 10,
    minHeight: 250,
    minWidth: 500,
    alignItems: "center",
    backgroundColor: "white",
    border: "1px solid #D9D9D9",
    float: "center",
  },
  interactionHistoryModule: {
    width: "95%",
    marginTop: 25,
    marginLeft: 25,
    padding: 20,
    borderRadius: 10,
    backgroundColor: "#D9D9D9",
    alignItems: "center",
  },
  statInvestorSection: {
    marginTop: 20,
  },
});

export const CompanyDetailsPageComponent = ({ companyBasics, companyDeals }) => {
  const classes = useStyles();
  const { fid, name, description, mercury_status, last_major_data_update, logo_url, business_model } = companyBasics;
  const { latest_actual_arr, latest_valid_arr_year, combined_size, predicted_growth_range, yoy_growth } = companyBasics;
  const { sf_lead_id, sf_lead_status, last_sf_update } = companyBasics;

  const companyDealsArray = companyDeals ? companyDeals.deals : [];

  const displayGrowthEstimate = displayGrowth(predicted_growth_range);
  const displayLastDataUpdate = prettyPrintDate(last_major_data_update);
  const displayLocationText = prettyPrintLocation(companyBasics);

  const [displayGrowthRate, displayGrowthLabel, displayGrowthSubLabel, growthFieldName] =
    yoy_growth && new Date().getFullYear - latest_valid_arr_year < 1
      ? [yoy_growth, "Actual Growth Rate", prettyPrintDate(last_sf_update), "yoy_growth"]
      : [
          displayGrowthEstimate,
          "Estimated Growth Range",
          prettyPrintDate(last_major_data_update),
          "predicted_growth_range",
        ];

  const [displayRevenue, displayRevenueLabel, displayRevenueSubLabel, revenueFieldName] =
    latest_actual_arr && new Date().getFullYear() - latest_valid_arr_year < 1
      ? [displayRevenueRange(latest_actual_arr), "Actual Revenue", prettyPrintDate(last_sf_update), "latest_actual_arr"]
      : [
          displayRevenueRange(combined_size),
          "Estimated Revenue",
          prettyPrintDate(last_major_data_update),
          "combined_size",
        ];

  return (
    <Card className={classes.companyDetailsCard}>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <CardHeader
            avatar={<Avatar alt={name} src={logo_url} />}
            title={<CompanyNameWithLink company={companyBasics} />}
            subheader={<i>{displayLocationText}</i>}
          />
        </Grid>
        <Grid item xs={7}>
          <div className={classes.cardHeaderRight}>
            <Stack direction="row" spacing={0} justifyContent="space-evenly">
              <InspectableBigStatDisplay
                company={companyBasics}
                stat={displayRevenue}
                format={"currency"}
                label={displayRevenueLabel}
                sublabel={displayRevenueSubLabel}
                field_name={revenueFieldName}
              />
              <InspectableBigStatDisplay
                company={companyBasics}
                stat={displayGrowthRate}
                format={"string"}
                label={displayGrowthLabel}
                sublabel={displayGrowthSubLabel}
                field_name={growthFieldName}
              />
              <div>
                <CompanySubtitle>
                  Full In Status: <b>{mercury_status}</b>
                </CompanySubtitle>
                <CompanySubtitle>
                  Salesforce Status: <b>{sf_lead_status ? sf_lead_status : "-"}</b>
                </CompanySubtitle>
                <CompanySubtitle>
                  Business Model: <b>{business_model ? business_model : "-"}</b>
                </CompanySubtitle>
                <CompanySubtitle>
                  Last Data Update: <b>{displayLastDataUpdate}</b>
                </CompanySubtitle>
              </div>
            </Stack>
          </div>
        </Grid>
      </Grid>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6} flex-direction="column">
            <CollapsibleDescription description={description} sliceLength={500} />
            <div className={classes.companyCardChips}>
              <CompanyCardChips company={companyBasics} maxCount={6} />
            </div>
            <EmployeeModule companyBasics={companyBasics} />
            <FundingInfoModule companyBasics={companyBasics} companyDealsArray={companyDealsArray} />
            {latest_actual_arr ? <CallDataModule companyBasics={companyBasics} /> : null}
          </Grid>
          <Grid item xs={6} flex-direction="column">
            <div className={classes.historyChartModule}>
              <CompanyHistoryChartContainer fid={fid} showTitle={true} />
            </div>
            <ExternalCompanyLinks
              companyBasics={companyBasics}
              linkSpacing={4}
              justifyContent="space-evenly"
              align="center"
            />
            {sf_lead_id ? <InteractionHistoryModule fid={fid} companyName={name} /> : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const CallDataModule = ({ companyBasics }) => {
  const classes = useStyles();
  const { last_sf_update, latest_actual_arr, latest_valid_arr_year, yoy_growth, burn_rate } = companyBasics;

  return (
    <div className={classes.basicInfoModule}>
      <Title>
        <b>Actual Call Data</b> (last touched: {prettyPrintDate(last_sf_update)})
      </Title>
      <div className={classes.statSection}>
        <Stack direction="row" spacing={5} justifyContent="space-evenly">
          <InspectableBigStatDisplay
            company={companyBasics}
            stat={latest_actual_arr}
            format="currency"
            label="Last Confirmed ARR"
            sublabel={latest_valid_arr_year}
            field_name="latest_actual_arr"
          />
          <InspectableBigStatDisplay
            company={companyBasics}
            stat={yoy_growth}
            format="percent"
            label="Last Confirmed Growth"
            sublabel={latest_valid_arr_year}
            field_name="yoy_growth"
          />
          <InspectableBigStatDisplay
            company={companyBasics}
            stat={burn_rate}
            format="currency"
            label="Burn Rate"
            sublabel={latest_valid_arr_year}
            field_name="burn_rate"
          />
        </Stack>
      </div>
    </div>
  );
};

const InteractionHistoryModule = ({ fid, companyName }) => {
  const classes = useStyles();

  return (
    <div className={classes.interactionHistoryModule}>
      <Title>
        <b>Interaction History</b>
      </Title>
      <SFhistory fid={fid} companyName={companyName} />
    </div>
  );
};

const EmployeeModule = ({ companyBasics }) => {
  const classes = useStyles();
  const { employees, yoy_emp_growth, last_major_data_update } = companyBasics;
  const last_year_employees = get_employees_last_year({ employees, yoy_emp_growth });

  return (
    <div className={classes.basicInfoModule}>
      <Title>
        <b>Employee Info</b>
      </Title>
      <div className={classes.statSection}>
        <Stack direction="row" spacing={5} justifyContent="space-evenly">
          <InspectableBigStatDisplay
            company={companyBasics}
            stat={employees}
            format="integer"
            label={`# Employees`}
            sublabel={`(as of ${prettyPrintDate(last_major_data_update)})`}
            field_name={"employees"}
          />
          {yoy_emp_growth && (
            <InspectableBigStatDisplay
              company={companyBasics}
              stat={last_year_employees}
              format="integer"
              label={`Last Year Employees`}
              // sublabel={`(as of ${prettyPrintDate(last_major_data_update)})`}
            />
          )}
          {yoy_emp_growth && (
            <InspectableBigStatDisplay
              company={companyBasics}
              stat={yoy_emp_growth}
              format="percent"
              label={`YoY Employee Growth`}
              sublabel={`(as of ${prettyPrintDate(last_major_data_update)})`}
              field_name={"yoy_emp_growth"}
            />
          )}
        </Stack>
      </div>
    </div>
  );
};

const FundingInfoModule = ({ companyBasics, companyDealsArray }) => {
  const classes = useStyles();
  const {
    investors,
    total_raised,
    last_financing_date,
    last_financing_size,
    prior_financing_size,
    prior_financing_date,
    last_major_data_update,
  } = companyBasics;
  const parsedInvestorListAll = parseJsonInvestorsIntoList(investors);
  const hasInvestors = parsedInvestorListAll.length > 0;

  const reversedDeals = companyDealsArray.slice().reverse();

  return (
    <div className={classes.fundingInfoModule}>
      <Title>
        <b>Funding Information</b>
      </Title>
      <div className={classes.statSection}>
        <Stack direction="row" spacing={5} justifyContent="space-evenly">
          <InspectableBigStatDisplay
            company={companyBasics}
            stat={total_raised ? total_raised : 0}
            format="currency"
            label="Total Funding Amount"
            sublabel={`(as of ${prettyPrintDate(last_major_data_update)})`}
            field_name="total_raised"
          />
          {last_financing_size && (
            <InspectableBigStatDisplay
              company={companyBasics}
              stat={last_financing_size}
              format="currency"
              label={`Last Funding Amount`}
              sublabel={`(on ${prettyPrintDate(last_financing_date)})`}
            />
          )}
          {prior_financing_size && (
            <InspectableBigStatDisplay
              company={companyBasics}
              stat={prior_financing_size}
              format="currency"
              label={`Prior Funding Amount`}
              sublabel={`(on ${prettyPrintDate(prior_financing_date)})`}
            />
          )}
        </Stack>
      </div>
      {hasInvestors && (
        <div className={classes.statInvestorSection}>
          <b>Investors: </b>
          <InvestorList investorList={parsedInvestorListAll} showCount={20} />
        </div>
      )}

      {hasInvestors && (
        <div className={classes.dealTable}>
          <CompanyDealsTable orderedCompanyDeals={reversedDeals} />
        </div>
      )}
    </div>
  );
};

const CompanyDealsTable = ({ orderedCompanyDeals }) => {
  const [open, setOpen] = useState(false);
  const companyDeals = orderedCompanyDeals ? orderedCompanyDeals : [];

  return (
    <div>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell sx={{ padding: 0 }}>
          <b>Recorded Deal History</b>
        </TableCell>
        <IconButton aria-label="expand row" size="medium">
          {open ? <UpIcon /> : <DownIcon />}
        </IconButton>
      </TableRow>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ columnWidth: "250px" }}>
                <b>Deal Date</b>
              </TableCell>
              <TableCell align="center" sx={{ columnWidth: "250px" }}>
                <b>Deal Size</b>
              </TableCell>
              <TableCell align="center" sx={{ columnWidth: "250px" }}>
                <b>Raised to Date</b>
              </TableCell>
              <TableCell align="center" sx={{ columnWidth: "1000px" }}>
                <b>Investors</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyDeals.map((deal) => (
              <DealTableRow deal={deal} />
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </div>
  );
};

const DealTableRow = ({ deal }) => {
  const { deal_date, deal_size, raised_to_date, investors } = deal;
  const parsedInvestorList = parseCommaSeparatedInvestorsIntoList(investors);

  return (
    <TableRow key={deal_date}>
      <TableCell component="th" scope="row" align="center">
        {prettyPrintDate(deal_date)}
      </TableCell>
      <TableCell align="center">{prettyPrintDollarAmounts(deal_size)}</TableCell>
      <TableCell align="center">{prettyPrintDollarAmounts(raised_to_date)}</TableCell>
      <TableCell align="center">
        <InvestorList investorList={parsedInvestorList} showCount={6} />
      </TableCell>
    </TableRow>
  );
};
