import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

import "../styles/globals.scss";
import { SearchWidget } from "../components/SearchWidget";
import { Page as PageComponent } from "../components/page";
import { TYPESENSE_SERVER_CONFIG } from "../utilities/typesenseUtils";
import { useHistory, useLocation } from "react-router-dom";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy: "name,domain,keywords,description",
    queryByWeights: "20,8,3,2",
    numTypos: 1,
    typoTokensThreshold: 1,
    sortBy: "_text_match(buckets: 10):desc,weighted_score:desc",
    // groupBy: "categories",
    // groupLimit: 1
    // pinnedHits: "23:2"
  },
});

export function SearchPage({
  searchClient = typesenseInstantsearchAdapter.searchClient,
  searchState,
  resultsState,
  onSearchParameters,
  widgetsCollector,
  ...props
}) {
  const location = useLocation();
  const history = useHistory();

  return (
    <PageComponent>
      <main>
        <SearchWidget
          indexName="companies"
          {...props}
          searchClient={searchClient}
          location={location}
          history={history}
          searchState={searchState}
          resultsState={resultsState}
          onSearchParameters={onSearchParameters}
          widgetsCollector={widgetsCollector}
        />
      </main>
    </PageComponent>
  );
}
