import { useEffect, useState } from "react";
import { TitledList } from "../components/TitledList";
import { getPlaylists } from "../utilities/ApiFetcher";

export function PlaylistFivePack({ title, rulesets }) {
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getPlaylists();
      const interestingPlaylists = response.filter((list) => list.playlist_source !== "manual").slice(0, 5);
      setPlaylists(interestingPlaylists);
    })();
  }, [rulesets]);

  return (
    <TitledList title={title} items={playlists} typeSlug="playlists" showDescriptions={true} titleUrl="/playlists" />
  );
}
