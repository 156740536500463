import { Page as PageComponent } from "../components/page";
import { PlaylistDetail } from "../containers/PlaylistDetail";

export function PlaylistDetailsPage() {
  return (
    <PageComponent>
      <PlaylistDetail />
    </PageComponent>
  );
}
