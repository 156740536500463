import { Grid, makeStyles } from "@material-ui/core";
import { Subtitle, Title } from "../Text";
import classNames from "classnames";
import { CompetitorTable, MarketInvestorTable } from "../tables";
import { TimedLoadingBar } from "../timedLoadingBar";
import { CollapsibleDescription } from "../collapsibleDescription";
import { GrowthStageGrowthHistogram, MarketStatsDisplay } from "../charts";
import { InvestmentByCountryChart, InvestmentByTimeChart, RevenueAndGrowthScatterChart } from "../charts";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    padding: theme.spacing(5),
  },
  skinnyRoot: {
    textAlign: "left",
    padding: theme.spacing(4),
  },
  titleSection: {
    marginBottom: theme.spacing(3),
    marginRight: "auto",
  },
  titleDesc: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    maxWidth: "60%",
  },
  skinnyTitleSection: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    margin: 10,
    marginTop: 30,
  },
  description: {
    marginTop: theme.spacing(3),
    maxWidth: "90%",
  },
  progressSection: {
    marginTop: theme.spacing(5),
    width: "60%",
    margin: "auto",
    fontSize: 20,
  },
  refreshButton: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const LOADING_STAGES = {
  LOADING_COMPS_LIST: 0,
  LOADED_COMPS_LIST: 1,
  LOADING_COMPETITOR_DATA: 2,
  LOADED_COMPETITOR_DATA: 3,
  FAILED: -1,
};

export function MarketTabDataChecker(props) {
  const { companyBasics, comps, compStats, loadingStage, skinnyPage, errorState } = props;

  if (compStats && comps.length > 0) {
    return (
      <CompanyTabComps
        companyBasics={companyBasics}
        comps={comps}
        compStats={compStats}
        loadingStage={loadingStage}
        skinnyPage={skinnyPage}
        errorState={errorState}
      />
    );
  } else {
    return <NoCompetitorsPanel companyBasics={companyBasics} />;
  }
}

export function CompanyTabComps(props) {
  const { companyBasics, comps, compStats, loadingStage, skinnyPage, errorState } = props;
  const classes = useStyles();
  const { name, description } = companyBasics;

  const hasLoadedComps = comps && loadingStage === LOADING_STAGES.LOADED_COMPS_LIST;
  const tableComps = hasLoadedComps ? comps : [];
  const loadedCompsNotEmpty = hasLoadedComps && tableComps.length > 0;

  const rootClass = classNames({
    [classes.skinnyRoot]: skinnyPage,
    [classes.root]: !skinnyPage,
  });

  const titleClass = classNames({
    [classes.skinnyTitleSection]: skinnyPage,
    [classes.titleSection]: !skinnyPage,
  });

  return (
    <div className={rootClass}>
      <div className={titleClass}>
        <Title text={name + " Market Data"} />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CollapsibleDescription description={description} sliceLength={500} />
            </Grid>

            <Grid item xs={6}>
              <RevenueAndGrowthScatterChart original_co={companyBasics} stats={compStats} />
            </Grid>

            <Grid item xs={6}>
              {loadedCompsNotEmpty && <InvestmentByTimeChart original_co={companyBasics} stats={compStats} />}
            </Grid>

            <Grid item xs={6}>
              <GrowthStageGrowthHistogram stats={compStats} />
            </Grid>

            <Grid item xs={6}>
              <InvestmentByCountryChart original_co={companyBasics} stats={compStats} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MarketStatsDisplay original_co={companyBasics} stats={compStats} />
            </Grid>
            <Grid item xs={12}>
              {loadedCompsNotEmpty && <MarketInvestorTable fid={companyBasics?.fid} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!hasLoadedComps && (
        <div className={classes.progressSection}>
          {!errorState && (
            <TimedLoadingBar
              expectedDuration={5}
              stages={[
                { start: 0, label: "Searching for Competitors..." },
                { start: 66, label: "Analyzing Company History..." },
                { start: 74, label: "Analyzing Employment History..." },
                { start: 98, label: "Finishing up..." },
              ]}
            />
          )}
          {errorState && (
            <div>
              <Subtitle text={`Had error: ${errorState.status}`} />
              <Subtitle text={`Had error: ${errorState.message}`} />
            </div>
          )}
        </div>
      )}

      {loadedCompsNotEmpty && (
        <CompetitorTable competitors={tableComps} isLoading={loadingStage === LOADING_STAGES.LOADING_COMPETITOR_DATA} />
      )}
    </div>
  );
}

function NoCompetitorsPanel({ companyBasics }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Subtitle text="Unable to find any competitors for this company. Sorry!" />
    </div>
  );
}
