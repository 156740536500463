import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";

import { Routes } from "./Routes";
import { UserContextProvider } from "../contexts";

export function App() {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "left" }} autoHideDuration={1000}>
      <UserContextProvider>
        <Router>
          <Routes />
        </Router>
      </UserContextProvider>
    </SnackbarProvider>
  );
}
