/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsConfig = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id: "us-east-1:976e10c3-7c2e-410f-8a75-dbeafeb39eb3",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_nTcWjlpdO",
  aws_user_pools_web_client_id: "7f23216dn7dmkjjqnscb5ru9ev",
  oauth: {
    domain: "mercurysite16eac298-16eac298-dev.auth.us-east-1.amazoncognito.com",
    scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "http://localhost:3000/,https://mercury.fullinpartners.com/",
    redirectSignOut: "http://localhost:3000/login,https://mercury.fullinpartners.com/login",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export default awsConfig;
