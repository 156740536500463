import { Bar } from "react-chartjs-2";

const options = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Growth Rate Histogram",
    },
  },
};


const DEFAULT_LABELS = ["<0%", "0-25%", "25-50%", "50-100%", "100-200%", ">200%"];

export const GrowthStageGrowthHistogram = ({ stats, error, loading }) => {
  const { growth_stage_growth_histogram } = stats;
  const values = growth_stage_growth_histogram ? growth_stage_growth_histogram.map((x) => x.combined_size) : []; // Really combined_isze is just counts

  const chartData = {
    labels: DEFAULT_LABELS,
    datasets: [
      {
        data: values,
        backgroundColor: "#6565ee",
      },
    ],
  };

  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>{error.message}</div>;
  }

  return <Bar data={chartData} options={options} />;
};
