import { useHistory } from "react-router-dom";

import { deletePlaylist } from "../utilities/ApiFetcher";
import { DeletePlaylistModal as DeletePlaylistModalComponent } from "../components/DeletePlaylistModal";

export const DeletePlaylistModal = ({ open, setOpen, playlistId }) => {
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await deletePlaylist(playlistId);
    history.push(`/playlists`);
  };

  return <DeletePlaylistModalComponent open={open} setOpen={setOpen} handleSubmit={handleSubmit} />;
};
