import { Bar } from "react-chartjs-2";

const options = {
  plugins: {
    title: {
      display: true,
      text: "Deal Size Histogram (over last 5 years)",
    },
  },
  scales: {
    yAxis: {
      ticks: {
        suggestedMin: 0,
        suggestedMax: 50,
      },
    },
  },
};

const BUCKETS_AND_LABELS = [
  {
    min: 0,
    max: 5000000,
    label: "$0-$5m",
  },
  {
    min: 5000000,
    max: 10000000,
    label: "$5m-$10m",
  },
  {
    min: 10000000,
    max: 50000000,
    label: "$10m-$50m",
  },
  {
    min: 50000000,
    max: 100000000,
    label: "$50m-$100m",
  },
  {
    min: 100000000,
    max: 250000000,
    label: "$100m-$250m",
  },
  {
    min: 250000000,
    label: "$250m+", // No max
  },
];

const getDealCountsBySize = (deals) => {
  const counts = {};
  for (const deal of deals) {
    const dealInvestment = deal.deal_size;
    const relevantBucket = BUCKETS_AND_LABELS.find((bucket) => {
      const result = dealInvestment >= bucket.min && (!bucket.max || dealInvestment < bucket.max);
      return result;
    });

    counts[relevantBucket.label] = counts[relevantBucket.label] ? counts[relevantBucket.label] + 1 : 1;
  }

  return counts;
};

export const InvestorDealsHistogram = ({ deals }) => {
  const relevantDeals = deals.filter((deal) => {
    const today = new Date();
    const five_yrs_ago = today.setFullYear(today.getFullYear() - 5);
    return Date.parse(deal.deal_date) > five_yrs_ago;
  });

  const deal_counts = getDealCountsBySize(relevantDeals);
  const bucketLabels = BUCKETS_AND_LABELS.map((bucket) => bucket.label);

  const chartData = {
    labels: bucketLabels,
    datasets: [
      {
        label: "# Deals",
        data: bucketLabels.map((y) => (deal_counts[y] ? deal_counts[y] : 0)),
        backgroundColor: "#6565ee",
      },
    ],
  };

  return <Bar data={chartData} options={options} />;
};
