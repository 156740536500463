import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Card } from "@material-ui/core";
import { Highlight } from "react-instantsearch-dom";

import { SearchDynamicTable } from "./SearchDynamicTable";
import { PlaylistPickerModal } from "../PlaylistPicker";
import { CreateFillPlaylistModal } from "../../containers/CreateFillPlaylistModal";
import { actionsNames, ALL_COLUMNS, cellStyle, headerStyle, tableActions } from "./tableTools";

// N.B. Have to duplicate some of those columns because of highlights and snippets and other
//      typesense-related UX stuff that we want to see here but not elsewhere
const COLUMNS = [
  {
    title: "Company",
    field: "name",
    render: (hit) => (
      <Link to={`/company/${hit.fid}`} target="_blank">
        <Highlight attribute="name" hit={hit} tagName="mark" />
      </Link>
    ),
    cellStyle,
    headerStyle,
  },
  {
    title: "Website",
    field: "domain",
    filtering: false,
    render: (hit) => (
      <a href={`https://${hit.domain}`} target="_blank" rel="noopener noreferrer">
        <Highlight attribute="domain" hit={hit} tagName="mark" />
      </a>
    ),
    headerStyle,
  },
  // {
  //   title: "Description",
  //   field: "description",
  //   filtering: false,
  //   render: (hit) => {
  //     if (hit._snippetResult.description.matchLevel === "none")
  //       return hit.description.replace(/^(.{65}[^\s]*).*/, "$1"); // Truncate to 65 characters wihtout cutting words
  //     else return <Snippet attribute="description" hit={hit} tagName="mark" />;
  //   },
  //   cellStyle: Object.assign({}, cellStyle, { maxWidth: 500 }),
  //   headerStyle,
  // },
  ALL_COLUMNS.LOCATION,
  ALL_COLUMNS.MERCURY_STATUS,
  ALL_COLUMNS.NUM_EMPLS,
  ALL_COLUMNS.TOTAL_RAISED,
  ALL_COLUMNS.LEAD_LAST_OWNER,
];

export const SearchTable = ({ companies }) => {
  const [playlistPickerShown, setPlaylistPickerShown] = useState(false);
  const [createFillShown, setCreateFillShown] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  const translatedCompanies = companies.map((company) => ({
    fid: parseInt(company.id),
    ...company,
  }));

  const actions = [
    tableActions[actionsNames.ADD_TO_PLAYLIST](setPlaylistPickerShown, setSelectedCompanies),
    tableActions[actionsNames.CREATE_PLAYLIST](setCreateFillShown, setSelectedCompanies),
  ];

  return (
    <Card>
      <CreateFillPlaylistModal open={createFillShown} setOpen={setCreateFillShown} companies={selectedCompanies} />
      <PlaylistPickerModal open={playlistPickerShown} setOpen={setPlaylistPickerShown} companies={selectedCompanies} />
      <SearchDynamicTable actions={actions} columns={COLUMNS} data={translatedCompanies} />
    </Card>
  );
};
