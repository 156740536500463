import { React } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Page as PageComponent } from "../components/page";
import { CompanyDetailsContainer } from "../containers/companyDetailsContainer";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function CompanyDetailsPage() {
  const { fid } = useParams();
  const queryParams = useQuery();
  const showHeader = queryParams.get("showHeader") !== "false";

  return (
    <PageComponent showHeader={showHeader}>
      <CompanyDetailsContainer fid={fid} />
    </PageComponent>
  );
}
