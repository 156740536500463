import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 100,
    width: "80%",
  },
}));

export function LoadingCircle({ size }) {
  const classes = useStyles();
  const useSize = size ? size : 60;

  return (
    <div className={classes.root}>
      <CircularProgress size={useSize} thickness={4.5} className={classes.progress} />
    </div>
  );
}
