import { App as AppContainer } from "./containers/App";

function App() {
  return (
    <div style={{ margin: 0, padding: 0, backgroundColor: "#e5e4ec" }}>
      <AppContainer />
    </div>
  );
}

export default App;
