import { useEffect, useState } from "react";
import { handleResponseErrors, requestCompanyCountByStatus } from "../../utilities/ApiFetcher";
import MaterialTable from "material-table";
import { cellStyle, headerStyle } from "./tableTools";

const COLUMNS = [
  {
    title: "Mercury Status",
    field: "label",
    cellStyle,
    headerStyle,
  },
  {
    title: "Count",
    field: "count",
    cellStyle,
    headerStyle,
  },
];

export const CompanyCountsByStatusTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getChartData = async () => {
    setLoading(true);
    try {
      const rawData = await requestCompanyCountByStatus();
      setData(rawData);
      setLoading(false);
    } catch (err) {
      setError(handleResponseErrors(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    getChartData();
  }, []);

  return (
    <MaterialTable
      columns={COLUMNS}
      data={data}
      options={{
        toolbar: false,
        showTitle: false,
        search: false,
        paging: false,
        sorting: false,
        draggable: false,
        filtering: false,
        exportButton: false,
      }}
      isLoading={loading}
      error={error}
    />
  );
};
