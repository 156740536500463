/**
 * Takes the month & count data from the API and returns the data in the format that the chart expects.
 * @param {{month: string; num_companies: number;}[]} input
 * @returns {{labels: string[]; values: number[];}}
 */
export const processUpdateBreakdownData = (input) => {
  const UTC_OFFSET = new Date().getTimezoneOffset() * 60 * 1000;
  const labels = input.map(({ month }) =>
    new Date(Date.parse(month) + UTC_OFFSET).toLocaleDateString("en-US", { month: "short", year: "2-digit" })
  );
  const values = input.map(({ num_companies }) => num_companies);

  return { labels, values };
};

export const processLabelAndCountData = (input) => {
  const labels = input.map(({ label }) => label);
  const values = input.map(({ count }) => count);

  return { labels, values };
};
