import { useState } from "react";
import { useSnackbar } from "notistack";

import { useUserContext } from "../contexts";
import { CreatePlaylistModal } from "../components/CreatePlaylistModal";
import { addCompaniesToPlaylist, createPlaylist } from "../utilities/ApiFetcher";

export const CreateFillPlaylistModal = ({ open, setOpen, companies }) => {
  const user = useUserContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = (await user.getUser())["attributes"]["email"];
    const { id } = await createPlaylist(email, name, description);
    enqueueSnackbar("Playlist created");
    setOpen(false);
    await addCompaniesToPlaylist(id, companies);
  };

  return (
    <CreatePlaylistModal
      open={open}
      setOpen={setOpen}
      handleSubmit={handleSubmit}
      description={description}
      setDescription={setDescription}
      name={name}
      setName={setName}
    />
  );
};
