import { useEffect, useState } from "react";
import { connectRange } from "react-instantsearch-dom";
import { Box, Slider, Typography } from "@mui/material";
import { Button, makeStyles, styled } from "@material-ui/core";
import { ArrowDropDownCircleOutlined, ArrowDropUpOutlined } from "@material-ui/icons";

import { prettyPrintInFormat } from "../utilities/TextUtilities";

const useStyles = makeStyles({
  slider: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  textInputWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 24,
    background: "unset",
    padding: 4,
    backgroundColor: "#3f51b5",
  },
});

export const RangeSliderDropdown = ({ label, children }) => {
  const [open, setOpen] = useState(false);
  const outerLabel = "Filter By " + label;
  const styles = useStyles();

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        variant={open ? "contained" : "outlined"}
        color="primary"
        fullWidth={true}
        className="mb-3 d-flex justify-content-between"
      >
        {outerLabel}
        {open ? <ArrowDropUpOutlined /> : <ArrowDropDownCircleOutlined />}
      </Button>
      {open && <div className={styles.slider}>{children}</div>}
    </>
  );
};

const valueLabelFormat = (format) => (value) => {
  if (typeof value === "number") {
    const label = prettyPrintInFormat(value, format);
    return label;
  }

  const [min, max] = value;
  const label = `${prettyPrintInFormat(min, format)} - ${prettyPrintInFormat(max, format)}`;
  return label;
};

function getIndexToIns(arr, num) {
  return arr
    .concat(num)
    .sort((a, b) => a - b)
    .indexOf(num);
}

export default function NonLinearSlider({ label, increments, min, max, currentRefinement, canRefine, refine, format }) {
  const incrementsMaxIndex = increments.length - 1;
  const [minMaxIndices, setMinMaxIndices] = useState([0, incrementsMaxIndex]);

  useEffect(() => {
    if (canRefine) {
      const newMinIndex = getIndexToIns(increments, currentRefinement.min);
      const newMaxIndex = getIndexToIns(increments, currentRefinement.max);
      setMinMaxIndices([newMinIndex, newMaxIndex]);
    }
  }, [currentRefinement.min, currentRefinement.max, canRefine, increments]);

  const handleChange = (event, newIndices) => {
    setMinMaxIndices(newIndices);
  };

  const handleChangeCommitted = (event, newIndices) => {
    const [newMinIndex, newMaxIndex] = newIndices;
    const [newMin, newMax] = [increments[newMinIndex], increments[newMaxIndex]];
    if (currentRefinement.min !== newMin || currentRefinement.max !== newMax) {
      refine({ min: newMin, max: newMax });
    }
  };

  const calculateValue = (indices) => {
    if (typeof indices === "number") {
      const value = increments[indices];
      return value;
    }

    const [min, max] = indices;
    const values = [increments[min], increments[max]];
    return values;
  };

  if (min === max) {
    return null;
  }

  return (
    <Box sx={{ marginLeft: 1, marginRight: 1 }}>
      <Typography id="non-linear-slider" gutterBottom>
        {label}: <b>{valueLabelFormat(format)(calculateValue(minMaxIndices))}</b>
      </Typography>
      <StyledSlider
        value={minMaxIndices}
        min={0}
        step={1}
        max={incrementsMaxIndex}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat(format)}
        valueLabelFormat={valueLabelFormat(format)}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
        disableSwap
      />
    </Box>
  );
}

export const ConnectedNonLinearSlider = connectRange(NonLinearSlider);
