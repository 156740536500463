import { makeStyles } from "@material-ui/core";

import { Subtitle } from "../Text";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(5),
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(4),
    fontSize: 52,
  },
}));

export const SummaryCountDisplay = ({ stats }) => {
  const classes = useStyles();
  const { summary_stats } = stats;
  const { num_comps, num_recommended_comps } = summary_stats;

  return (
    <div className={classes.root}>
      <Subtitle>
        <b>{num_comps}</b> Total Companies Found{" "}
      </Subtitle>
      <Subtitle>
        <b>{num_recommended_comps}</b> Recommended Companies Found{" "}
      </Subtitle>
    </div>
  );
};
