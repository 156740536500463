import { useEffect, useState } from "react";
import { TitledList } from "../components/TitledList";
import { requestRecommendedLeads } from "../utilities/ApiFetcher";

export function RecommendedFivePack({ title }) {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    (async () => {
      const { result } = await requestRecommendedLeads(5);
      const resultWithIds = result.map((company) => ({ ...company, id: company.fid }));
      setCompanies(resultWithIds);
    })();
  }, []);

  return (
    <TitledList
      title={title}
      items={companies}
      typeSlug="company"
      showDescriptions={true}
      titleUrl="/all-recommended"
    />
  );
}
