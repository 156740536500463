import React from "react";
import MaterialTable from "material-table";
import { tableIcons, useTableStyles } from "./tableTools";
import { TableDetailPanel } from "./tableDetailPanel";

const convertToDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date;
};

const convertSearchHitIntoDetailCompany = (hit) => ({
  ...hit,
  fid: parseInt(hit.id),
  timestamp: convertToDate(hit.last_major_data_update),
  last_major_data_update: convertToDate(hit.last_major_data_update),
});

const SEARCH_TABLE_OPTIONS = {
  selection: true,
  // toolbar: false,
  paging: false,
  search: false,
  pageSize: 20,
  pageSizeOptions: [20],
  exportButton: true,
  columnWidth: "fit-content",
};

export function SearchDynamicTable({ columns, data, isLoading, actions, updateTableData }) {
  const classes = useTableStyles();
  const { table } = classes;

  return (
    <div className={table}>
      <MaterialTable
        icons={tableIcons}
        options={SEARCH_TABLE_OPTIONS}
        isLoading={isLoading}
        actions={actions}
        columns={columns}
        data={data}
        title=""
        detailPanel={(rowData) => <TableDetailPanel company={convertSearchHitIntoDetailCompany(rowData)} />}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </div>
  );
}
