import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "60%",
    margin: "auto",
    textAlign: "center",
    padding: theme.spacing(4),
  },
  buttonContainer: {
    width: "60%",
    margin: "auto",
    padding: 10,
    textAlign: "center",
  },
  button: {
    margin: 10,
    width: 200,
    height: 50,
    textAlign: "center",
  },
}));

export function Login({ handleLogInAttempt }) {
  const classes = useStyles();

  return (
    <div>
      <section className={classes.header}>
        <img width="90" height="90" src="mercury-logo.png" alt="Mercury" title="Mercury" />
        <h1 className="ms-fontSize-su ms-fontWeight-light ms-fontColor-neutralPrimary">Welcome</h1>
      </section>

      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogInAttempt}
          className={classes.button}
          size="large"
        >
          Login
        </Button>
      </div>
    </div>
  );
}
