import React, { useState } from "react";
import { Card, makeStyles } from "@material-ui/core";

import { DynamicTable } from "./dynamicTable";
import { DEFAULT_COLUMNS, ALL_COLUMNS, actionsNames, tableActions } from "./tableTools";
import { PlaylistPickerModal } from "../PlaylistPicker";
import { CreateFillPlaylistModal } from "../../containers/CreateFillPlaylistModal";

const useStyles = makeStyles((theme) => ({
  newLeadsCard: {
    // marginTop: theme.spacing(1),
    // marginLeft: theme.spacing(4),
    boxShadow: "none",
  },
  "@global": {
    ".MuiTableBody-root>tr.MuiTableRow-root:not([index])": {
      // display: "none",
    },
  },
}));

const COMPETITOR_COLUMNS = () => {
  const myCols = [...DEFAULT_COLUMNS];
  myCols[2] = ALL_COLUMNS.MERCURY_STATUS;
  return myCols;
};

export function CompetitorTable({ competitors, isLoading, error }) {
  const classes = useStyles();
  const [playlistPickerShown, setPlaylistPickerShown] = useState(false);
  const [createFillShown, setCreateFillShown] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  const compColumns = COMPETITOR_COLUMNS();
  const sortedCompetitors = competitors.sort((a, b) => {
    return a.mercury_status === "Recommended" ? -1 : 1;
  });

  const actions = [
    tableActions[actionsNames.ADD_TO_PLAYLIST](setPlaylistPickerShown, setSelectedCompanies),
    tableActions[actionsNames.CREATE_PLAYLIST](setCreateFillShown, setSelectedCompanies),
  ];

  return (
    <Card className={classes.newLeadsCard}>
      <CreateFillPlaylistModal open={createFillShown} setOpen={setCreateFillShown} companies={selectedCompanies} />
      <PlaylistPickerModal open={playlistPickerShown} setOpen={setPlaylistPickerShown} companies={selectedCompanies} />
      <DynamicTable
        actions={actions}
        data={sortedCompetitors}
        columns={compColumns}
        isLoading={isLoading}
        initialState={{ sorting: [{ id: "mercury_status", desc: false }] }}
      />
    </Card>
  );
}
