import { useEffect, useState } from "react";
import { BarChart } from "../../components/charts/BarChart";
import { handleResponseErrors, requestDataSourceUpdateBreakdown } from "../../utilities/ApiFetcher";
import { processUpdateBreakdownData } from "../../utilities/chartHelpers";

export const DataSourceUpdatedChartContainer = ({ source }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const getChartData = async (source) => {
    setLoading(true);
    try {
      const rawData = await requestDataSourceUpdateBreakdown(source);
      setData(processUpdateBreakdownData(rawData));
      setLoading(false);
    } catch (err) {
      setError(handleResponseErrors(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    getChartData(source);
  }, [source]);

  return (
    <BarChart
      data={data}
      options={options}
      error={error}
      isLoading={loading}
      // label="Companies Last Updated This Month"
    />
  );
};
