import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { Avatar, Button, Card, CardContent, CardHeader, Grid, IconButton, makeStyles } from "@material-ui/core";
import {
  Launch as LaunchIcon,
  Link as LinkIcon,
  ThumbDown as ThumbDownIcon,
  ThumbUp as ThumbUpIcon,
} from "@material-ui/icons";
import {
  ApolloButton,
  CrunchbaseButton,
  SalesforceButton,
  LinkedInButton,
  UnqualifiedButton,
  PlaylistButton,
} from "../ExternalCompanyLinks";
import { Title } from "../Text";
import { CompanyCardChips } from "../CompanyCardChips";
import { CompanyCardChart } from "../charts";
import { CollapsibleDescription } from "../collapsibleDescription";
import { PlaylistPickerModal } from "../PlaylistPicker";
import { markUnqualified } from "../../utilities/ApiFetcher";
import { handleLinkClick } from "../../utilities/navigationUtils";
import { prettyPrintLocation } from "../../utilities/TextUtilities";

const useStyles = makeStyles({
  companyCardRoot: {
    padding: 10,
    paddingLeft: 20,
    // margin: 30,
    color: "black",
  },
  standardFont: {
    fontFamily: "Mukta",
    fontWeight: 400,
  },
  keywordChips: {
    marginBottom: 30,
  },
  nameWithLink: {
    display: "inline-flex",
    marginTop: 10,
    padding: 5,
  },
  chartsTitle: {
    width: "50%",
    margin: "auto",
    textAlign: "center",
    marginBottom: 20,
  },
  actionStack: {
    // marginTop: 40,
  },
});

export function TableDetailPanel({ company }) {
  const classes = useStyles();

  const [pPModalOpen, setPPModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { fid, name, mercury_status, description, cb_url, logo_url, linkedin_url, apollo_url, sf_lead_id } = company;

  const handleLinkClick = (url) => (event) => {
    event.preventDefault();
    if (url) {
      window.open(url, "_blank");
    }
  };

  const handleUnqualifiedClick = async (event, target) => {
    event.preventDefault();

    const fidList = [fid];
    await markUnqualified(fidList);
    enqueueSnackbar("Marked as Unqualified!", { variant: "success" });
  };

  return (
    <Card className={classes.companyCardRoot}>
      <CardHeader
        avatar={<Avatar alt={name} src={logo_url} />}
        action={
          <Button endIcon={<LaunchIcon />} onClick={handleLinkClick("/company/" + fid)}>
            {mercury_status}
          </Button>
        }
        title={<CompanyNameWithLink company={company} />}
        subheader={<i>{prettyPrintLocation(company)}</i>}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <div className={classes.keywordChips}>
              <CompanyCardChips company={company} maxCount={8} />
            </div>

            <CollapsibleDescription description={description} sliceLength={450} />

            <div className={classes.actionStack}>
              <Stack direction="row" spacing={1}>
                <CrunchbaseButton cb_url={cb_url} />
                <LinkedInButton linkedin_url={linkedin_url} />
                <SalesforceButton sf_lead_id={sf_lead_id} />
                <ApolloButton apollo_url={apollo_url} />
                <UnqualifiedButton action={handleUnqualifiedClick} />
                <PlaylistButton action={setPPModalOpen} />

                <IconButton aria-label="like">
                  <ThumbUpIcon />
                </IconButton>
                <IconButton aria-label="dislike">
                  <ThumbDownIcon />
                </IconButton>
              </Stack>
            </div>
          </Grid>

          <Grid item xs={5}>
            <div className={classes.chartsTitle}>
              <Title>
                <i>Last Year v. Today</i>
              </Title>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <CompanyCardChart company={company} statLabel={"ARR"} statFormat={"currency"} />
              </Grid>
              <Grid item xs={4}>
                <CompanyCardChart company={company} statLabel={"Total Raised"} statFormat={"currency"} />
              </Grid>
              <Grid item xs={4}>
                <CompanyCardChart company={company} statLabel={"Employees"} statFormat={"integer"} />
              </Grid>
              {/*<Grid item xs={"auto"}>*/}
              {/*  <CompanyCardChart company={company} statLabel={"Growth Rate"} statFormat={"percent"}/>*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
        </Grid>
        <PlaylistPickerModal open={pPModalOpen} setOpen={setPPModalOpen} companies={[company]} />
      </CardContent>
    </Card>
  );
}

export const CompanyNameWithLink = ({ company }) => {
  const companyUrl = "https://" + company.domain;

  return (
    <Button
      endIcon={<LinkIcon />}
      sx={{ ml: 6, textTransform: "capitalize" }}
      color="black"
      aria-label="go to link"
      onClick={handleLinkClick(companyUrl)}
    >
      <Title>
        <b>{company.name}</b>
      </Title>
    </Button>
  );
};
