import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

import { Page as PageComponent } from "../components/page";
import { PlaylistPageHeader } from "../components/PlaylistPageHeader";
import { PlaylistIndexCards } from "../containers/PlaylistIndexCards";
import { CreatePlaylistModal } from "../containers/CreatePlaylistModal";
import { PlaylistSearchBar } from "../containers/PlaylistSearchBar";

const useStyles = makeStyles((theme) => ({
  pagePadding: {
    padding: theme.spacing(4),
  },
  searchBar: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
}));

export function PlaylistIndexPage() {
  const classes = useStyles();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  return (
    <PageComponent>
      <div className={classes.pagePadding}>
        <PlaylistPageHeader setCreateModalOpen={setCreateModalOpen} />
        <div className={classes.searchBar}>
          <PlaylistSearchBar />
        </div>

        <PlaylistIndexCards />
        <CreatePlaylistModal open={createModalOpen} setOpen={setCreateModalOpen} />
      </div>
    </PageComponent>
  );
}
