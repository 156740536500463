import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useUserContext } from "../contexts";
import { createPlaylist } from "../utilities/ApiFetcher";
import { CreatePlaylistModal as CreatePlaylistModalComponent } from "../components/CreatePlaylistModal";

export const CreatePlaylistModal = ({ open, setOpen }) => {
  const history = useHistory();
  const user = useUserContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = (await user.getUser())["attributes"]["email"];
    const { id } = await createPlaylist(email, name, description);
    history.push(`/playlists/${id}`);
  };

  return (
    <CreatePlaylistModalComponent
      open={open}
      setOpen={setOpen}
      handleSubmit={handleSubmit}
      description={description}
      setDescription={setDescription}
      name={name}
      setName={setName}
    />
  );
};
