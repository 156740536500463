import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { CompanyCard } from "./CompanyCard";
import { Subtitle } from "./Text";
import { PageSelectionComponent } from "./pageSelectionComponent";
import { PageSizeSelectionComponent } from "./pageSizeSelectionComponent";

const useStyles = makeStyles({
  companyContainer: {
    marginTop: 30,
  },
  button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonName: {
    fontSize: 20,
  },
  pageSizeControl: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
    marginRight: 30,
  },
  pageControl: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
    marginTop: 20,
    marginRight: 30,
  },
  pageControlBottom: {
    width: "20%",
    margin: "auto",
    textAlign: "center",
    marginTop: 20,
    paddingBottom: 40,
  },
});

export const LeadsList = ({ listLeads, removeLeadsFromTable }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setPageSize] = React.useState(10);

  const minIndex = (currentPage - 1) * currentPageSize; // Starting at 1
  const pageLeads = listLeads.slice(minIndex, minIndex + currentPageSize);

  const maxPage = Math.ceil(listLeads.length / currentPageSize) - 1;
  const pageRange = Array.from({ length: maxPage }, (_, i) => i + 1);
  const hasMoreLeads = currentPage < maxPage;

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (pageNum) => (event) => {
    setCurrentPage(pageNum);
  };

  return (
    <div className={classes.companyContainer}>
      <div className={classes.pageSizeControl}>
        <Box sx={{ minWidth: 120, maxWidth: 150 }}>
          <PageSizeSelectionComponent pageSize={currentPageSize} handlePageSizeChange={handlePageSizeChange} />
        </Box>
      </div>

      <div className={classes.pageControl}>
        <PageSelectionComponent pageRange={pageRange} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>

      {pageLeads.map((lead, index) => (
        <CompanyCard key={index} company={lead} removeLeadsFromList={removeLeadsFromTable} />
      ))}

      <div className={classes.pageControlBottom}>
        {hasMoreLeads && <Subtitle text={`See More`} />}

        <PageSelectionComponent pageRange={pageRange} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>
    </div>
  );
};
