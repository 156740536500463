import Stack from "@mui/material/Stack";
import { Chip, makeStyles } from "@material-ui/core";
import { createURL, createBaseSearchState, searchStateToUrl } from "../utilities/typesenseUtils";
import { conditionalParseJSON } from "../utilities/TextUtilities";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
  },
  standardFont: {
    fontFamily: "Mukta",
    fontWeight: 400,
    fontSize: 16,
  },
}));

const createUrlLinkForKeyword = (keyword) => {
  const keywords = [keyword];
  const searchState = createBaseSearchState({ keywords });
  const urlLink = "/search" + searchStateToUrl(searchState);
  return urlLink;
};

const cutListAfterCharCount = (keywordList, maxCharCount) => {
  let totalCount = 0;
  let newList = [];
  for (const keywords of keywordList) {
    totalCount += keywords.length;
    if (totalCount < maxCharCount) {
      newList.push(keywords);
    }
  }
  return newList;
};

export const getOrderedKeywords = (keywords, maxWordCount, maxCharCount) => {
  let newKeywords = !keywords ? [] : conditionalParseJSON(keywords);
  newKeywords = newKeywords.filter((word) => word.length > 5);
  newKeywords = newKeywords.sort((a, b) => a.length - b.length);
  newKeywords = cutListAfterCharCount(newKeywords, maxCharCount);
  newKeywords = newKeywords.slice(0, maxWordCount);
  return newKeywords;
};

export function KeywordChips({ keywords, maxWordCount }) {
  const classes = useStyles();

  const sliceKeywords = getOrderedKeywords(keywords, maxWordCount, 90);
  const hasKeywords = sliceKeywords && sliceKeywords.length > 0;

  return (
    <div className={classes.root}>
      {hasKeywords && (
        <Stack direction="row" spacing={1}>
          {sliceKeywords.map((word, index) => (
            <Chip
              className={classes.standardFont}
              label={word}
              key={index}
              component="a"
              href={createUrlLinkForKeyword(word)}
              clickable
              target="_blank"
            />
          ))}
        </Stack>
      )}
    </div>
  );
}
