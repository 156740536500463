import { useEffect, useState } from "react";
import { BarChart } from "../../components/charts/BarChart";
import { handleResponseErrors, requestCompanyUpdateBreakdown } from "../../utilities/ApiFetcher";
import { processUpdateBreakdownData } from "../../utilities/chartHelpers";

export const CompanyUpdatedChartContainer = (fid) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const getChartData = async () => {
    setLoading(true);
    try {
      const rawData = await requestCompanyUpdateBreakdown();
      setData(processUpdateBreakdownData(rawData));
      setLoading(false);
    } catch (err) {
      setError(handleResponseErrors(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    getChartData();
  }, []);

  return <BarChart data={data} error={error} isLoading={loading} options={options} />;
};
