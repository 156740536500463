export const prettyPrintInFormat = (value, format) => {
  if (value && (value === undefined || value === null)) {
    return "None";
  }

  if (format === "currency") {
    return prettyPrintDollarAmounts(value);
  }

  if (format === "number") {
    return prettyPrintDecimal(value);
  }

  if (format === "integer") {
    return Math.round(value);
  }

  if (format === "percent") {
    return prettyPrintPercent(value);
  }

  // TODO: Get this right across client and here
  if (format === "date") {
    return prettyPrintDate(value);
  }

  if (format === "text") {
    return value;
  }

  if (format === "bool") {
    return capitalizeFirstLetter(value);
  }

  return value;
};

function capitalizeFirstLetter(value) {
  let string = value.toString();
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}

export const prettyPrintDollarAmounts = (amount) => {
  if (!amount && amount !== 0) {
    return "-";
  }

  if (isNaN(amount)) {
    return amount;
  }

  const isNegative = amount < 0;

  if (amount >= 1000000000 || amount <= -1000000000) {
    const billions = (amount / 1000000000).toFixed(2);
    const value = isNegative ? `-$${billions * -1}B` : `$${billions}B`;
    return value;
  }

  if (amount >= 1000000 || amount <= -1000000) {
    const millions = (amount / 1000000).toFixed(0);
    const value = isNegative ? `-$${millions * -1}M` : `$${millions}M`;
    return value;
  }

  if (amount >= 1000 || amount <= -1000) {
    const thousands = (amount / 1000).toFixed(2);
    const value = isNegative ? `-$${thousands * -1}K` : `$${thousands}K`;
    return value;
  }

  return `$${amount.toFixed(2)}`;
};

export const prettyPrintDecimal = (decimal) => {
  if (!decimal || isNaN(decimal)) {
    return decimal;
  }

  return decimal.toFixed(2);
};

export const prettyPrintPercent = (decimal) => {
  if (!decimal && decimal !== 0) {
    return "-";
  }

  const myDecimal = prettyPrintDecimal(decimal, 0);
  const myInt = Math.round(myDecimal);
  return `${myInt}%`;
};

export const prettyPrintLocation = (company) => {
  if (!company) {
    return "";
  }

  const { location, city, country } = company;
  if (city && city !== "" && country && country !== "") {
    return `${city}, ${country}`;
  }

  if (country && country !== "") {
    return country;
  }

  if (city && city !== "") {
    return city;
  }

  return location;
};

export const prettyPrintDate = (value) => {
  const printedDate = !value
    ? ""
    : typeof value === "number"
    ? value
    : typeof value === "string"
    ? value.substring(0, 10)
    : value.toISOString().substring(0, 10);
  return printedDate;
};

export const emailToName = (email) => `${email.charAt(0).toUpperCase()}${email.split("@")[0].slice(1)}`;

export const sliceStringAtMaxLength = (string, maxLength) => {
  const slicedString = string && maxLength < string.length ? string.slice(0, maxLength - 3) + "..." : string;
  return slicedString;
};

export const combineCityAndCountry = (city, country) => {
  return `${city}, ${country}`;
};

export const snakeCaseToTitleCase = (text) =>
  text.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : " " + d.toUpperCase()));

export const conditionalParseJSON = (kw) => {
  try {
    const parsedKeywords = JSON.parse(kw);
    if (typeof parsedKeywords === "object") return parsedKeywords;
  } catch {}
  return kw;
};

// displayGrowth(predicted_growth_range) converts the predicted_growth_range to a string of the form "X% to Y%" where X is lower bound and Y is upper bound
export const displayGrowth = (predicted_growth_range) => {
  if (!predicted_growth_range || isNaN(predicted_growth_range || predicted_growth_range == null)) return "N/A";
  if (predicted_growth_range < 0) return "< 0%";
  if (0 <= predicted_growth_range && predicted_growth_range < 40) return "0% - 50%";
  if (40 <= predicted_growth_range && predicted_growth_range < 70) return "25% - 75%";
  if (70 <= predicted_growth_range && predicted_growth_range < 100) return "50% - 120%";
  if (100 <= predicted_growth_range && predicted_growth_range < 150) return "80% - 150%";
  if (150 <= predicted_growth_range && predicted_growth_range < 200) return "125% - 225%";
  if (200 <= predicted_growth_range && predicted_growth_range < 300) return "150% - 300%";
  return "> 300%";
};

export const displayRevenueRange = (revenue_estimate) => {
  const THOUS = 1000;
  const MIL = 1000000;
  if (isNaN(revenue_estimate) || revenue_estimate === null || revenue_estimate === undefined) return "N/A";
  if (revenue_estimate < 500 * THOUS) return "$0 - $1m";
  if (revenue_estimate < 4 * MIL) return "$1m - $4m";
  if (revenue_estimate < 6 * MIL) return "$4m - $7m";
  if (revenue_estimate < 10 * MIL) return "$5m - $10m";
  if (revenue_estimate < 20 * MIL) return "$10m - $20m";
  if (revenue_estimate < 40 * MIL) return "$20m - $50m";
  if (revenue_estimate < 80 * MIL) return "$50m - $100m";
  return "$75m+";
};
