import { makeStyles } from "@material-ui/core";

import { MiniText } from "./Text";

const useStyles = makeStyles((_theme) => ({
  root: {
    marginLeft: 8,
    marginTop: 5,
  },
}));

export function MercuryMiniStats({ stats }) {
  const { total_count, recommended_count, tracking_count, latest_update, oldest_update } = stats;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {recommended_count && (
        <MiniText>
          Showing <strong>100</strong> out of <strong>{recommended_count}</strong> currently recommended leads
        </MiniText>
      )}
      {recommended_count && (
        <MiniText>
          Tracking an additional <strong>{tracking_count}</strong> leads ({total_count} total in system)
        </MiniText>
      )}
      {recommended_count && (
        <MiniText>
          Most recent lead: <strong>{latest_update.slice(0, 10)}</strong>, Oldest lead:{" "}
          <strong>{oldest_update.slice(0, 10)}</strong>
        </MiniText>
      )}
    </div>
  );
}
