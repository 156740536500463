import { useEffect, useState } from "react";
import { List, Dialog, makeStyles } from "@material-ui/core";

import { ListItemLink } from "./ListItemLink";
import { getPlaylists } from "../utilities/ApiFetcher";

export const PlaylistPickerModal = ({ open, setOpen, companies }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <PlaylistPickerDisplay setOpen={setOpen} companies={companies} />
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    height: 800,
    width: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "column",
    overflow: "scroll",
  },
  dialogTitle: {
    padding: 0,
    margin: 0,
    fontSize: 20,
    color: "white",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dialogHeader: {
    backgroundColor: theme.palette.primary.dark,
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  list: {
    width: "100%",
    padding: 0,
  },
}));

export const PlaylistPickerDisplay = ({ companies, setOpen }) => {
  const classes = useStyles();
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    const fn = async () => {
      setPlaylists(await getPlaylists());
    };
    fn();
  }, []);

  return (
    <div className={classes.dialogBody}>
      <div className={classes.dialogHeader}>
        <h2 className={classes.dialogTitle}>Pick a Playlist</h2>
      </div>
      <List className={classes.list}>
        {playlists &&
          playlists.map((playlist) => (
            <ListItemLink
              key={playlist.id}
              id={playlist.id}
              name={playlist.name}
              owner={playlist.owner}
              updatedAt={playlist.updated_at}
              companies={companies}
              setOpen={setOpen}
            />
          ))}
      </List>
    </div>
  );
};
