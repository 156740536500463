import { Login as LoginContainer } from "../containers";
import { Page as PageComponent } from "../components/page";

export function LoginPage() {
  return (
    <PageComponent showHeader={false} skinnyPage={false}>
      <LoginContainer />
    </PageComponent>
  );
}
