import { useState } from "react";
import { Card, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { DynamicTable } from "./dynamicTable";
import { PlaylistPickerModal } from "../PlaylistPicker";
import {
  actionsNames,
  DEFAULT_COLUMNS,
  tableActions,
  translateSfStatus,
  updateTableDataWithApiActions,
} from "./tableTools";
import { CreateFillPlaylistModal } from "../../containers/CreateFillPlaylistModal";

const useStyles = makeStyles((theme) => ({
  newLeadsCard: {
    // marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    boxShadow: "none",
  },
  "@global": {
    ".MuiTableBody-root>tr.MuiTableRow-root:not([index])": {
      // display: "none",
    },
  },
}));

function translateSingleLead(lead) {
  return {
    ...lead,
    mercury_status: lead.mercury_status ? lead.mercury_status : "Recommended", // TODO: Probably better just to include in recommended list
    last_major_data_update: lead.last_major_data_update ? new Date(lead.last_major_data_update) : undefined,
    revenueEstimate: lead.revenueEstimate, // Don't translate here so we can sort later
    sfStatus: translateSfStatus(lead), // Translate here so its easier to filter later
    keywords: JSON.parse(lead.keywords),
  };
}

export function NewLeadsTable({ newLeads, isLoading, removeLeadsFromTable }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const data = newLeads.map(translateSingleLead);
  const columns = DEFAULT_COLUMNS;

  const [playlistPickerShown, setPlaylistPickerShown] = useState(false);
  const [createFillShown, setCreateFillShown] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState(null);

  const updateTableData = async (data, status) => {
    const fidArr = data.map((el) => el["fid"]);
    const params = { fidList: fidArr, enqueueSnackbar, action: status };
    await updateTableDataWithApiActions(params);
    removeLeadsFromTable(fidArr);
  };

  const actions = [
    tableActions[actionsNames.NOT_INTERESTED](updateTableData),
    tableActions[actionsNames.SNOOZE](updateTableData),
    tableActions[actionsNames.ADD_TO_PLAYLIST](setPlaylistPickerShown, setSelectedCompanies),
    tableActions[actionsNames.CREATE_PLAYLIST](setCreateFillShown, setSelectedCompanies),
  ];

  return (
    <Card className={classes.newLeadsCard}>
      <CreateFillPlaylistModal open={createFillShown} setOpen={setCreateFillShown} companies={selectedCompanies} />
      <PlaylistPickerModal open={playlistPickerShown} setOpen={setPlaylistPickerShown} companies={selectedCompanies} />
      <DynamicTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        actions={actions}
        updateTableData={updateTableData}
      />
    </Card>
  );
}
