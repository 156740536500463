import { useState, useEffect } from "react";

import { requestMercuryHitRateStats, handleResponseErrors } from "../utilities/ApiFetcher";
import { MercuryHitRateStats as MercuryHitRateStatsComponent } from "../components/MercuryHitRateStats";

export function MercuryHitRateStats() {
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setError] = useState(undefined);

  const doGetStats = () => {
    setIsLoading(true);
    requestMercuryHitRateStats()
      .then((stats) => {
        if (stats) setStats(stats);
        setIsLoading(false);
      })
      .catch((error) => {
        const errorState = handleResponseErrors(error);
        setError(errorState);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    doGetStats();
  }, []);

  return <MercuryHitRateStatsComponent isLoading={isLoading} stats={stats} error={errors} />;
}
