import * as React from "react";
import MaterialTable from "material-table";
import { useCallback, useEffect, useState } from "react";
import { getRulesetDiags } from "../../utilities/ApiFetcher";
import { LoadingCircle } from "../loadingCircle";
import { makeStyles } from "@material-ui/core";
import { Subtitle, Title } from "../Text";
import { prettyPrintInFormat } from "../../utilities/TextUtilities";
import { v4 } from "uuid";
import { ChevronRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    padding: theme.spacing(5),
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  diagsTableSection: {
    minHeight: "40em",
    margin: 20,
  },
  diagsTableTitleSection: {
    marginBottom: 20,
  },
}));

const ruleToSimpleName = (rule_name, attributes) => {
  let simpleName;
  let simpleDescription;
  const {
    field_name,
    outcome,
    min_val,
    max_val,
    threshold,
    equals_value,
    inclusion_set,
    source_name,
    tableData,
    value,
  } = attributes;
  if (tableData.path.length !== 1)
    return { simpleName: source_name, simpleDescription: value === null ? "-" : String(value) };
  if (rule_name === undefined) return "";

  // eslint-disable-next-line default-case
  switch (rule_name) {
    case "<class 'core.rules_engine.rules.Outcome'>":
      simpleName = "Outcome:";
      simpleDescription = outcome;
      break;

    case "<class 'core.rules_engine.rules.InRange'>":
      simpleName = "In Range";
      simpleDescription = `${min_val} <= ${field_name} <= ${max_val}`;
      break;

    case "<class 'core.rules_engine.rules.GreaterThan'>":
      simpleName = "Greater Than";
      simpleDescription = `${field_name} >= ${threshold}`;
      break;

    case "<class 'core.rules_engine.rules.LessThan'>":
      simpleName = "Less Than";
      simpleDescription = `${field_name} <= ${threshold}`;
      break;

    case "<class 'core.rules_engine.rules.Equals'>":
      simpleName = "Equals";
      simpleDescription = `${field_name} == ${equals_value}`;
      break;

    case "<class 'core.rules_engine.rules.NotEquals'>":
      simpleName = "Not Equals";
      simpleDescription = `${field_name} != ${equals_value}`;
      break;

    case "<class 'core.rules_engine.rules.NotNull'>":
      simpleName = "Not Null";
      simpleDescription = `${field_name} not null`;
      break;

    case "<class 'core.rules_engine.rules.InSet'>":
      simpleName = "In Set";
      simpleDescription = `${field_name} in set of ${inclusion_set.length}`;
      break;
  }

  return { simpleName, simpleDescription };
};

const getNodeColor = ({ success, notes }) => {
  const isSuccess = success === true;
  const isFailure = success === false;
  const isNull = success === null;
  // const isNull = isNaN(success) || (notes && !success); // N.B. I think this is happening because object['a'] = null removes 'a' from object in JS

  const color = isSuccess ? "lightgreen" : isFailure ? "#ffcccb" : isNull ? "lightyellow" : "white";

  return color;
};

const RulesetDiagsColumns = [
  {
    render: (row) => {
      const { simpleName } = ruleToSimpleName(row["rule"], row);
      return simpleName;
    },
    // cellStyle: narrowCellStyle,
    // headerStyle: narrowHeaderStyle,
    width: "10%",
  },
  {
    title: "Field",
    field: "field_name",
    // cellStyle: narrowCellStyle,
    // headerStyle: narrowHeaderStyle,
    width: "20%",
  },
  {
    title: "Description",
    render: (row) => {
      const { simpleDescription } = ruleToSimpleName(row["rule"], row);
      return simpleDescription;
    },
    // cellStyle,
    // headerStyle,
    width: "30%",
  },
  {
    title: "Notes",
    type: "string",
    render: ({ notes, timestamp }) => {
      const extra_data = notes ? notes : prettyPrintInFormat(timestamp, "date");
      return extra_data;
    },
    // cellStyle: wideCellStyle,
    // headerStyle: wideHeaderStyle,
    width: "40%",
  },
];

export function RulesetTableDisplay({ fid, ruleset }) {
  const classes = useStyles();
  const [rulesetDiags, setRulesetDiags] = useState(undefined);
  const [syncLoadId, setSyncLoadId] = useState(null); // used to avoid stale data from async calls
  const [asyncLoadId, setAsyncLoadId] = useState(null);
  const [loadError, setLoadError] = useState(undefined);
  const { label, timestamp, result, ruleset_id } = ruleset;

  const doGetRulesetDiags = useCallback(async () => {
    const loadId = v4();
    setSyncLoadId(loadId);

    if (!fid) {
      setAsyncLoadId(null);
      return;
    }

    getRulesetDiags(fid, ruleset_id)
      .then((rulesetDiags) => {
        rulesetDiags.diags = flattenDiagValuesAndSources(rulesetDiags.diags);
        setRulesetDiags(rulesetDiags);
        setAsyncLoadId(loadId);
      })
      .catch((error) => {
        console.log(error);
        setLoadError(error);
        setAsyncLoadId(null);
      });
  }, [fid, setRulesetDiags, setSyncLoadId, setAsyncLoadId, setLoadError, ruleset_id]);

  useEffect(() => {
    doGetRulesetDiags();
  }, [doGetRulesetDiags]);

  const hasDiags = syncLoadId === asyncLoadId && !loadError && rulesetDiags;
  const isLoading = syncLoadId !== asyncLoadId && !loadError;

  return (
    <div className={classes.diagsTableSection}>
      <div className={classes.diagsTableTitleSection}>
        <Title text={label} />
        <Subtitle text={`Result = ${result}`} />
        <Subtitle text={prettyPrintInFormat(timestamp, "date")} />
      </div>
      {isLoading && <LoadingCircle />}
      {hasDiags && <RulesetDiagsTable diags={rulesetDiags?.diags} loading={isLoading} error={loadError} />}
    </div>
  );
}

export function RulesetDiagsTable({ diags, loading, error }) {
  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <MaterialTable
      icons={{ DetailPanel: () => <ChevronRight /> }}
      title={"Ruleset Diagnostic Table"}
      columns={RulesetDiagsColumns}
      data={diags}
      options={{
        selection: false,
        toolbar: false,
        showTitle: true,
        search: false,
        paging: false,
        sorting: false,
        draggable: false,
        filtering: false,
        exportButton: false,
        // tableLayout: "fixed",
        rowStyle: (rowData) => ({
          backgroundColor: getNodeColor(rowData),
        }),
      }}
      parentChildData={(row, rows) => rows.find((a) => a.nodeId === row.parentId)}
    />
  );
}

export function flattenDiagValuesAndSources(diagArray) {
  const tableData = [];

  function traverseDiagNode(node, parentId) {
    let nodeId = v4();

    tableData.push({ ...node, nodeId, parentId });

    if (node?.inputs?.inputs) {
      node.inputs.inputs.forEach((source) => {
        traverseDiagNode(source, nodeId);
      });
    } else if (node?.inputs?.forEach) {
      node.inputs.forEach((source) => {
        traverseDiagNode(source, nodeId);
      });
    }
  }

  diagArray.forEach((diag) => traverseDiagNode(diag, undefined));

  return tableData;
}
