import { Line } from "react-chartjs-2";

import { prettyPrintDollarAmounts } from "../../utilities/TextUtilities";

const options = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Market Investment",
    },
    tooltips: {
      callbacks: {
        label: (context) => {
          const { parsed } = context;
          const { y } = parsed;
          return prettyPrintDollarAmounts(y);
        },
      },
    },
  },
  scales: {
    dollars: {
      type: "linear",
      position: "right",
      ticks: {
        callback: function (value) {
          return `$${value / 1000000}m`;
        },
        suggestedMin: 0,
      },
    },
  },
};

export const InvestmentByTimeChart = ({ stats }) => {
  const { investment_over_time } = stats;
  const labels = investment_over_time.map(({ month }) => month.substring(0, 10));
  const y_values = investment_over_time.map(({ total_funding }) => total_funding);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total Market Investment",
        data: y_values,
        fill: true,
        borderColor: "#040F16",
        borderWidth: 1,
        pointBorderWidth: 0,
        pointRadius: 0,
        backgroundColor: "#6565ee",
        tension: 0.1,
      },
    ],
  };

  return <Line data={data} options={options} type="line" />;
};
