import { useState, useCallback, useEffect } from "react";

import { requestMercuryStats, handleResponseErrors } from "../utilities/ApiFetcher";
import { MercuryMiniStats as MercuryMiniStatsComponent } from "../components/MercuryMiniStats";

export function MercuryMiniStats() {
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setError] = useState(undefined);

  const doGetStats = useCallback(async () => {
    setIsLoading(true);
    requestMercuryStats()
      .then((stats) => {
        if (stats) setStats(stats);
        setIsLoading(false);
      })
      .catch((error) => {
        const errorState = handleResponseErrors(error);
        setError(errorState);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    doGetStats();
  }, [doGetStats]);

  return <MercuryMiniStatsComponent isLoading={isLoading} stats={stats} error={errors} />;
}
