import Stack from "@mui/material/Stack";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Title, Subtitle, StandardText } from "./Text";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { LoadingCircle } from "./loadingCircle";
import { getSalesforceHistory } from "../utilities/ApiFetcher";

const useStyles = makeStyles({
  historyRoot: {
    margin: 25,
    color: "black",
  },
  historyList: {
    margin: "auto",
    borderRadius: 10,
  },
});

const testHistory = [
  {
    subject: "Hello",
    activityDate: "2021-10-01",
    owner: "Sean Hager",
    description: "This is a test",
  },
  {
    subject: "First Call",
    activityDate: "2022-10-01",
    owner: "Sean Hager",
    description: "This is a test",
  },
  {
    subject: "Reminder",
    activityDate: "2022-06-01",
    owner: "Sean Hager",
    description: "This is a test",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#c9c9c9",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const SalesforceHistoryModalController = ({ open, setOpen, fid, companyName }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Title variant={"h6"}>Salesforce History for {companyName}</Title>
      <SalesforceHistoryModalComponent fid={fid} companyName={companyName} />
    </Dialog>
  );
};

export const SalesforceHistoryModalComponent = ({ fid, companyName }) => {
  const classes = useStyles();

  const [history, setHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [loadError, setLoadError] = useState(null);

  const getCompanySalesforceHistory = useCallback(async () => {
    setHistoryLoading(true);

    getSalesforceHistory(fid)
      .then((history) => {
        setHistory(history);
        setHistoryLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadError(error);
        setHistoryLoading(false);
      });
  }, [fid]);

  useEffect(() => {
    getCompanySalesforceHistory();
  }, []);

  if (loadError) {
    return <Typography variant={"body1"}>Unable to load interaction history.</Typography>;
  }

  const sortedHistory =
    history.length === 0
      ? []
      : history.sort((a, b) => {
          const aComps = a.activityDate.split("-");
          const bComps = b.activityDate.split("-");
          const aDate = new Date(parseInt(aComps[0]), parseInt(aComps[1]) - 1, parseInt(aComps[2]));
          const bDate = new Date(parseInt(bComps[0]), parseInt(bComps[1]) - 1, parseInt(bComps[2]));
          return bDate.getTime() - aDate.getTime();
        });

  return (
    <div className={classes.historyRoot}>
      {!historyLoading && (
        <div className={classes.historyList}>
          <Stack spacing={2}>
            {sortedHistory.map((activity, index) => (
              <SalesforceActivityDisplay key={index} activity={activity} />
            ))}
          </Stack>
        </div>
      )}
    </div>
  );
};

const SalesforceActivityDisplay = ({ activity }) => {
  const classes = useStyles();
  const { subject, callNotes, activityDate, ownerId } = activity;

  return (
    <Accordion>
      <AccordionSummary>
        <Typography variant="subtitle1">
          {activityDate}
          <b> {subject}</b> -- {ownerId}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <StandardText text={callNotes} />
      </AccordionDetails>
    </Accordion>
  );
};
