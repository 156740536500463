import { Grid, makeStyles } from "@material-ui/core";

import { Page as PageComponent } from "../components/page";
import { HomepageSearch } from "../containers/HomepageSearch";
import { RulesetFivePack } from "../containers/RulesetFivePack";
import { PlaylistFivePack } from "../containers/PlaylistFivePack";
import { RecommendedFivePack } from "../containers/RecommendedFivePack";

const useStyles = makeStyles({
  homeRoot: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 30,
  },
  topGrid: {
    marginTop: 40,
  },
  centerStrip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15vh",
    gap: "1rem",
  },
  bottomGrid: {
    marginTop: 80,
    padding: 30,
    paddingBottom: 0,
    flex: 1,
  },
});

export function HomePage() {
  const classes = useStyles();

  return (
    <PageComponent>
      <div className={classes.homeRoot}>
        <Grid container className={classes.topGrid}>
          <Grid item xs={4} />
          <Grid item xs={4} className={classes.centerStrip}>
            <img src="/mercury_logo_no_background.png" alt="" style={{ width: 150 }} />
            <HomepageSearch />
          </Grid>
          <Grid item xs={4} />
        </Grid>

        <Grid container className={classes.bottomGrid}>
          <Grid item xs={4}>
            <RulesetFivePack
              title="Bootstrapped Companies"
              rulesets={["Bootstrapped Enterprise SaaS", "Bootstrapped Marketplace"]}
            />
          </Grid>
          <Grid item xs={4}>
            <RecommendedFivePack title="Recommended Leads" />
          </Grid>
          <Grid item xs={4}>
            <PlaylistFivePack title="Interesting Playlists" />
          </Grid>
        </Grid>
      </div>
    </PageComponent>
  );
}
