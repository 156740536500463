import { useState, useEffect } from "react";

import { LoadingCircle } from "../../components/loadingCircle";
import { getCompanyHistory, handleResponseErrors } from "../../utilities/ApiFetcher";
import { CompanyHistoryChart } from "../../components/charts";

export const CompanyHistoryChartContainer = ({ fid, showTitle }) => {
  const [companyHistory, setCompanyHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const doGetCompanyHistory = async () => {
      setIsLoading(true);

      getCompanyHistory(fid)
        .then(({ history }) => {
          setCompanyHistory(history);
          setIsLoading(false);
          return history;
        })
        .catch((error) => {
          handleResponseErrors(error);
          setIsLoading(false);
        });
    };

    doGetCompanyHistory();
  }, [fid]);

  const readyToRender = companyHistory && !isLoading;

  return (
    <>
      {isLoading && <LoadingCircle />}
      {readyToRender && <CompanyHistoryChart companyHistory={companyHistory} height={150} showTitle={showTitle} />}
    </>
  );
};
