import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import React, { StrictMode } from "react";
import { Provider, ErrorBoundary } from "@rollbar/react";

import "./index.css";
import App from "./App";
import { getAmplifyConfig } from "./config";
import awsConfig from "./config/modified-aws-exports";
// import { makeServer } from "./testServer";
//
// // TODO: Need to comment these makeServer lines out in order to get typesense search to work locally :(
// const isRunningInDevelopment = process.env.NODE_ENV === "development";
// if (isRunningInDevelopment) {
//   makeServer({ environment: "development" });
// }

const amplifyConfig = getAmplifyConfig(awsConfig);

const environmentIsNotProd = process.env.NODE_ENV !== "production";

const rollbarConfig = {
  accessToken: "a92dcac324ae44a69bb0715f217a29ce",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: "production",
  },
};

Amplify.configure(amplifyConfig);

ReactDOM.render(
  <StrictMode>
    {environmentIsNotProd && <App />}
    {!environmentIsNotProd && (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>
    )}
  </StrictMode>,
  document.getElementById("root")
);
