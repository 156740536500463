import React, { forwardRef } from "react";
import { Link, makeStyles } from "@material-ui/core";
import {
  Add,
  AddBox,
  AddToQueue,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Delete,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  NotInterested,
  Remove,
  SaveAlt,
  Search,
  Snooze,
  ViewColumn,
} from "@material-ui/icons";
import {
  prettyPrintDollarAmounts,
  prettyPrintLocation,
  prettyPrintPercent,
  displayRevenueRange,
} from "../../utilities/TextUtilities";
import { IconButton } from "../IconButton";
import {
  addCompaniesToPlaylist,
  markUnqualified,
  removePlaylistCompanies,
  sequenceLeads,
  snoozeLeads,
} from "../../utilities/ApiFetcher";

export const cellStyle = {
  minWidth: 40,
  maxWidth: 80,
  fontFamily: "Mukta",
  fontSize: 12,
  fontWeight: 400,
  textAlign: "left",
};

export const headerStyle = {
  fontSize: 12,
  fontFamily: "Mukta",
  minWidth: 40,
  maxWidth: 80,
  fontWeight: 700,
  textAlign: "left",
};

export const narrowCellStyle = {
  minWidth: 30,
  maxWidth: 50,
  fontSize: 12,
  fontFamily: "Mukta",
  fontWeight: 400,
  textAlign: "center",
};

export const narrowHeaderStyle = {
  minWidth: 30,
  maxWidth: 50,
  fontSize: 12,
  fontFamily: "Mukta",
  fontWeight: 700,
  textAlign: "center",
};

export const wideCellStyle = {
  minWidth: 20,
  maxWidth: 300,
  fontFamily: "Mukta",
  fontSize: 12,
  fontWeight: 400,
  textAlign: "left",
};

export const wideHeaderStyle = {
  fontSize: 12,
  fontFamily: "Mukta",
  minWidth: 250,
  maxWidth: 300,
  fontWeight: 700,
  textAlign: "left",
};

export const actionsNames = {
  NOT_INTERESTED: "Not Interested",
  SNOOZE: "Snooze",
  SEQUENCE: "Sequence",
  ADD_TO_PLAYLIST: "Add to Playlist",
  CREATE_PLAYLIST: "Create Playlist",
  REMOVE_FROM_PLAYLIST: "Remove from Playlist",
};

export const updateTableDataWithApiActions = async ({ fidList, playlistId, action, enqueueSnackbar }) => {
  if (action === actionsNames.NOT_INTERESTED) {
    await markUnqualified(fidList);
    enqueueSnackbar("Successfully Passed!", { variant: "success" });
  } else if (action === actionsNames.SNOOZE) {
    await snoozeLeads(fidList);
    enqueueSnackbar("Successfully Snoozed!", { variant: "success" });
  } else if (action === actionsNames.SEQUENCE) {
    await sequenceLeads(fidList);
    enqueueSnackbar("Successfully Sequenced!", { variant: "success" });
  } else if (action === actionsNames.ADD_TO_PLAYLIST) {
    await addCompaniesToPlaylist(playlistId, fidList);
    enqueueSnackbar("Successfully added companies to playlist!", { variant: "success" });
  } else if (action === actionsNames.REMOVE_FROM_PLAYLIST) {
    await removePlaylistCompanies(playlistId, fidList);
    enqueueSnackbar("Successfully removed from playlist!", { variant: "success" });
  } else {
    console.error("Unexpected table action name: " + action);
    enqueueSnackbar("Unsuccessful table operation: " + action, { variant: "error" });
  }
};

export const tableActions = {
  [actionsNames.NOT_INTERESTED]: (updateTableData) => ({
    tooltip: "Not Interested",
    icon: () => <IconButton icon={<NotInterested />} label="Not Interested" color="secondary" />,
    onClick: (_evt, data) => updateTableData(data, actionsNames.NOT_INTERESTED),
  }),
  [actionsNames.SNOOZE]: (updateTableData) => ({
    tooltip: "Snooze",
    icon: () => <IconButton icon={<Snooze />} label="Snooze" />,
    onClick: (_evt, data) => updateTableData(data, actionsNames.SNOOZE),
  }),
  [actionsNames.ADD_TO_PLAYLIST]: (setPlaylistPickerShown, setSelectedCompanies) => ({
    tooltip: "Add to Playlist",
    icon: () => <IconButton icon={<Add />} label="Add to Playlist" color="secondary" />,
    onClick: (_evt, data) => {
      setPlaylistPickerShown(true);
      setSelectedCompanies(data);
    },
  }),
  [actionsNames.CREATE_PLAYLIST]: (setCreateFillShown, setSelectedCompanies) => ({
    tooltip: "Create Playlist",
    icon: () => <IconButton icon={<AddToQueue />} label="Create Playlist" color="secondary" />,
    onClick: (_evt, data) => {
      setCreateFillShown(true);
      setSelectedCompanies(data);
    },
  }),
  [actionsNames.REMOVE_FROM_PLAYLIST]: (updateTableData) => ({
    tooltip: "Remove",
    icon: () => <IconButton icon={<Delete />} label="Remove" color="secondary" />,
    onClick: (_evt, data) => updateTableData(data, actionsNames.REMOVE_FROM_PLAYLIST),
  }),
};

export function translateSfStatus(lead) {
  const leadStatus = lead.leadStatus;
  const leadOwner = lead.leadOwnerId;

  if (!leadStatus) {
    return "None";
  }
  if (leadStatus === "Unqualified") {
    return "Unqualified";
  }
  if (leadOwner === "00G4P000005dO6gUAE") {
    return "Backlog";
  }
  if (leadOwner === "G4P000005esVnUAI") {
    return "Backlog";
  }
  if (leadStatus === "Mercury") {
    return "Backlog";
  }

  return leadStatus;
}

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const useTableStyles = makeStyles((theme) => ({
  table: {
    maxWidth: "100%",
    justifyContent: "center",
    fontSize: 12,
    paddingTop: "1%",
    "& > div:nth-child(1)": {
      boxShadow: "none",
    },
    "& > tr.MuiTableRow-root": {
      height: "2px",
    },
  },
}));

export const ALL_COLUMNS = {
  COMPANY: {
    title: "Company",
    field: "name",
    render: (row) => (
      <Link href={"/company/" + row.fid} target="_blank">
        {row.name}
      </Link>
    ),
    cellStyle,
    headerStyle,
  },
  WEBSITE: {
    title: "Website",
    field: "domain",
    filtering: false,
    render: (row) => (
      <Link href={"https://" + row.domain} target="_blank">
        {row.domain}
      </Link>
    ),
    cellStyle,
    headerStyle,
  },
  LOCATION: {
    title: "Location",
    type: "string",
    render: (row) => prettyPrintLocation(row),
    cellStyle,
    headerStyle,
  },
  MERCURY_STATUS: {
    title: "Status",
    field: "mercury_status",
    type: "string",
    render: (row) =>
      row.mercury_status === "Recommended"
        ? row.mercury_status + ` - ` + row.recommendation_reason
        : row.mercury_status,
    cellStyle,
    headerStyle,
  },
  LEAD_OWNERSHIP: {
    title: "Lead Ownership",
    field: "sfStatus",
    type: "string",
    cellStyle,
    headerStyle,
  },
  LEAD_LAST_OWNER: {
    title: "Recent Lead Owner",
    field: "lead_owner_last",
    type: "string",
    cellStyle,
    headerStyle,
  },
  LAST_SF_UPDATE: {
    title: "Most Recent Salesforce Update",
    field: "last_sf_update",
    type: "date",
    filtering: false,
    cellStyle,
    headerStyle,
  },
  LAST_DATA_UPDATE: {
    title: "Last Data Update",
    field: "last_major_data_update",
    type: "date",
    filtering: false,
    cellStyle,
    headerStyle,
  },
  NUM_EMPLS: {
    title: "Num Empls",
    field: "employees",
    type: "numeric",
    filtering: false,
    cellStyle: narrowCellStyle,
    headerStyle: narrowHeaderStyle,
  },
  YOY_EMP_GROWTH: {
    title: "YoY Emp. Growth",
    field: "yoy_emp_growth",
    render: (lead) => prettyPrintPercent(lead.yoy_emp_growth),
    filtering: false,
    cellStyle,
    headerStyle,
  },
  TOTAL_RAISED: {
    title: "Total Raised",
    field: "total_raised",
    render: (lead) => prettyPrintDollarAmounts(lead.total_raised),
    filtering: false,
    cellStyle,
    headerStyle,
  },
  LAST_RAISE_AMOUNT: {
    title: "Last Raise",
    field: "last_financing_size",
    render: (lead) => prettyPrintDollarAmounts(lead.last_financing_size),
    filtering: false,
    cellStyle,
    headerStyle,
  },
  LAST_RAISE_DATE: {
    title: "Last Raise Date",
    field: "last_financing_date",
    type: "date",
    filtering: false,
    cellStyle,
    headerStyle,
  },
  GROWTH_ESTIMATE: {
    title: "Growth Estimate",
    field: "mercury_growth",
    render: (lead) => prettyPrintPercent(lead.predicted_growth_range),
    filtering: false,
    cellStyle,
    headerStyle,
  },
  REVENUE_ESTIMATE: {
    title: "Revenue Estimate",
    field: "mercury_revenue",
    render: (lead) => displayRevenueRange(lead.combined_size),
    filtering: false,
    cellStyle,
    headerStyle,
  },
  STAGE: {
    title: "Stage",
    field: "company_stage",
    type: "string",
    cellStyle,
    headerStyle,
  },
};

export const DEFAULT_COLUMNS = [
  ALL_COLUMNS.COMPANY,
  // ALL_COLUMNS.WEBSITE,
  ALL_COLUMNS.LOCATION,
  ALL_COLUMNS.RECOMMENDATION_REASON,
  // ALL_COLUMNS.MERCURY_STATUS
  // ALL_COLUMNS.LEAD_OWNERSHIP,
  ALL_COLUMNS.LEAD_LAST_OWNER,
  ALL_COLUMNS.LAST_SF_UPDATE,
  // ALL_COLUMNS.LAST_DATA_UPDATE,
  ALL_COLUMNS.NUM_EMPLS,
  ALL_COLUMNS.YOY_EMP_GROWTH,
  ALL_COLUMNS.TOTAL_RAISED,
  ALL_COLUMNS.LAST_RAISE_AMOUNT,
  ALL_COLUMNS.LAST_RAISE_DATE,
  ALL_COLUMNS.GROWTH_ESTIMATE,
  ALL_COLUMNS.REVENUE_ESTIMATE,
];
