import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export const PageSizeSelectionComponent = ({ pageSize, pageSizeOptions, handlePageSizeChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="page-size-select-label">Page Size</InputLabel>
      <Select
        labelId="page-size-select-label"
        id="page-size-select"
        value={pageSize}
        label="PageSize"
        onChange={handlePageSizeChange}
      >
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={30}>30</MenuItem>
      </Select>
    </FormControl>
  );
};
