import { Route, Switch } from "react-router-dom";

import { useUserContext } from "../contexts";
import { ProtectedRoute } from "./ProtectedRoute";
import { LoadingCircle } from "../components/loadingCircle";
import {
  HomePage,
  StatsPage,
  LoginPage,
  SearchPage,
  CompanyDetailsPage,
  NewLeadsPage,
  PlaylistIndexPage,
  PlaylistDetailsPage,
  InvestorPage,
} from "../pages";
import { RulesetLeadsPage } from "../pages/RulesetLeadsPage";

export function Routes() {
  const { isAuthenticated, authLoading } = useUserContext();

  return (
    <>
      {authLoading && <LoadingCircle />}
      {!authLoading && (
        <Switch>
          <Route path="/login" exact={true} component={LoginPage} />
          <ProtectedRoute path="/company/:fid" isAuthenticated={isAuthenticated}>
            <CompanyDetailsPage />
          </ProtectedRoute>
          <ProtectedRoute path="/search" isAuthenticated={isAuthenticated}>
            <SearchPage />
          </ProtectedRoute>
          <ProtectedRoute path="/stats" isAuthenticated={isAuthenticated}>
            <StatsPage />
          </ProtectedRoute>
          <ProtectedRoute path="/playlists/:id" isAuthenticated={isAuthenticated}>
            <PlaylistDetailsPage />
          </ProtectedRoute>
          <ProtectedRoute path="/playlists" isAuthenticated={isAuthenticated}>
            <PlaylistIndexPage />
          </ProtectedRoute>
          <ProtectedRoute path="/investor/:investor_name" isAuthenticated={isAuthenticated}>
            <InvestorPage />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path="/all-recommended" isAuthenticated={isAuthenticated}>
            <NewLeadsPage />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path="/ruleset-leads/:rulesets" isAuthenticated={isAuthenticated}>
            <RulesetLeadsPage />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path="/" isAuthenticated={isAuthenticated}>
            <HomePage />
          </ProtectedRoute>
        </Switch>
      )}
      ;
    </>
  );
}
