import { useState, useEffect } from "react";

import { handleResponseErrors, getPlaylists } from "../utilities/ApiFetcher";
import { PlaylistIndexCards as PlaylistIndexCardsComponent } from "../components/PlaylistIndexCards";

export const PlaylistIndexCards = () => {
  const [playlists, setPlaylists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getAllPlaylists = async () => {
    setIsLoading(true);
    try {
      setPlaylists(await getPlaylists());
      setIsLoading(false);
    } catch (err) {
      setError(handleResponseErrors(err));
    }
  };

  useEffect(() => {
    getAllPlaylists();
  }, []);

  return <PlaylistIndexCardsComponent playlists={playlists} isLoading={isLoading} error={error} />;
};
