import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { prettyPrintDollarAmounts } from "../../utilities/TextUtilities";

ChartJS.register(CategoryScale, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const getChartOptions = (showTitle) => ({
  responsive: true,
  interaction: {
    mode: "index",
    intersect: true,
  },
  plugins: {
    title: {
      display: showTitle,
      text: "Company History",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const { raw, dataset } = context;
          const { y } = raw;
          const { label } = dataset;

          if (y > 2000) {
            return `${label}: ${prettyPrintDollarAmounts(y)}`;
          }

          return `${label}: ${y}`;
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        text: "As Of Month",
        display: true,
      },
      position: "bottom",
      type: "time",
      time: {
        unit: "month",
      },
      maxRotation: 30,
      minRotation: 30,
    },
    yScalar: {
      title: {
        text: "Employee Count",
        display: true,
      },
      type: "linear",
      position: "left",
      display: true,
      grid: {
        drawOnChartArea: false,
      },
    },
    yCurrency: {
      title: {
        text: "Total Raised",
        display: true,
      },
      type: "linear",
      position: "right",
      display: true,
      beginAtZero: true,
    },
  },
});

const getDateFromAsOfMonth = (asof_month) => {
  const monthString = asof_month.toString();
  const dateString = monthString.slice(0, 4) + "-" + monthString.slice(4, 6) + "-01";
  return new Date(dateString);
};

export const CompanyHistoryChart = ({ companyHistory, height, showTitle }) => {
  const companyHistoryRecent = companyHistory.filter((month) => month.asof_month >= 202301);
  const convertHistoryToXY = (history, field_name) => {
    return history.map((month) => ({
      x: getDateFromAsOfMonth(month.asof_month),
      y: month[field_name],
    }));
  };

  const months = companyHistoryRecent.map((x) => getDateFromAsOfMonth(x.asof_month));
  const employeesXY = convertHistoryToXY(companyHistoryRecent, "employees");
  const fundingXY = convertHistoryToXY(companyHistoryRecent, "total_raised");
  const revenueXY = convertHistoryToXY(companyHistoryRecent, "combined_size");

  const options = getChartOptions(showTitle);

  const data = {
    labels: months,
    datasets: [
      {
        label: "Employee Count",
        data: employeesXY,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "yScalar",
      },
      {
        label: "Revenue",
        data: revenueXY,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "yCurrency",
      },
      {
        label: "Total Raised",
        // type: 'bar',
        data: fundingXY,
        borderColor: "green",
        backgroundColor: "limegreen",
        yAxisID: "yCurrency",
      },
    ],
  };

  return <Line options={options} data={data} height={height} />;
};
