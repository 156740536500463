import config from "../config";
import qs from "qs";

const isRunningInDevelopment = process.env.NODE_ENV === "development";

function assembleTypesenseServerConfig() {
  const currentConfig = isRunningInDevelopment ? config.apiGateway.dev : config.apiGateway.prod;

  let TYPESENSE_SERVER_CONFIG = {
    apiKey: currentConfig.typesenseServerApiKey,
    nodes: [
      {
        host: currentConfig.typesenseServerEndpoint,
        port: currentConfig.typesenseServerPort,
        protocol: currentConfig.typesenseServerProtocol,
      },
    ],
    numRetries: 8,
    connectionTimeoutSeconds: 1,
  };

  if (process.env[`TYPESENSE_HOST_2`]) {
    TYPESENSE_SERVER_CONFIG.nodes.push({
      host: process.env[`TYPESENSE_HOST_2`],
      port: process.env.NEXT_PUBLIC_TYPESENSE_PORT,
      protocol: process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL,
    });
  }

  if (process.env[`TYPESENSE_HOST_3`]) {
    TYPESENSE_SERVER_CONFIG.nodes.push({
      host: process.env[`TYPESENSE_HOST_3`],
      port: process.env.NEXT_PUBLIC_TYPESENSE_PORT,
      protocol: process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL,
    });
  }

  if (process.env[`TYPESENSE_HOST_NEAREST`]) {
    TYPESENSE_SERVER_CONFIG["nearestNode"] = {
      host: process.env[`TYPESENSE_HOST_NEAREST`],
      port: process.env.NEXT_PUBLIC_TYPESENSE_PORT,
      protocol: process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL,
    };
  }

  return TYPESENSE_SERVER_CONFIG;
}

export const TYPESENSE_SERVER_CONFIG = assembleTypesenseServerConfig();

export const createURL = (state) => {
  const result = `?${qs.stringify(state)}`;
  return result;
};

export const createBaseSearchState = ({ keywords }) => {
  const searchKeywords = keywords ? keywords : [];
  const baseSearchState = {
    configure: { hitsPerPage: 50 },
    page: 1,
    refinementList: {
      keywords: searchKeywords,
    },
  };

  return baseSearchState;
};

export const searchStateToUrl = (searchState) => {
  const result = searchState ? createURL(searchState) : "";
  return result;
};

export const urlToSearchState = ({ search }) => {
  const result = qs.parse(search.slice(1));
  return result;
};
