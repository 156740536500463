import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";

export const PageSelectionComponent = ({ pageRange, currentPage, handlePageChange }) => {
  return (
    <div>
      <ButtonGroup variant="text" aria-label="text button group">
        {pageRange.map((pageNum, index) => (
          <Button key={index} onClick={handlePageChange(pageNum)} disabled={currentPage === pageNum}>
            {pageNum}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};
