import { Bar } from "react-chartjs-2";

/**
 * Generic Bar Chart courtesy of react-chartjs-2. Pay heed to the data format as defined in this jsdoc.
 * @param {{data: { labels: string[]; values: number[] }; label: string; loading: boolean; error?: { message: string; }}} props
 * @returns {JSX.Element}
 */
export const BarChart = ({ data, error, loading, label, options }) => {
  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Bar
      data={{
        labels: data.labels,
        datasets: [{ data: data.values, backgroundColor: "indigo", label }],
      }}
      options={{
        minBarLength: 5,
        ...options,
      }}
    />
  );
};
