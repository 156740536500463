import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";

export const UserContext = createContext(null);

export function UserContextProvider({ children }) {
  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState(null);

  async function handleLogOutAttempt(event) {
    event.preventDefault();
    setAuthLoading(true);
    await Auth.signOut();
    setAuthLoading(false);
  }

  function handleLogInAttempt(event) {
    event.preventDefault();

    try {
      console.log("Trying to log in with O365...");
      setAuthLoading(true);
      Auth.federatedSignIn();
    } catch (e) {
      alert("Could not log user in: " + e.message);
    }
  }

  const addAuthListener = useCallback(async () => {
    Hub.listen("auth", async ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          console.log("Sign in!");
          break;

        case "cognitoHostedUI":
          console.log("HostedUI!");
          getUser()
            .then((userData) => setUser(userData))
            .then(() => setAuthLoading(false));
          break;

        case "signOut":
          setUser(null);
          setAuthLoading(false);
          break;

        case "signIn_failure":
          console.log("Sign in failure", data);
          setAuthLoading(false);
          break;

        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          setAuthLoading(false);
          break;

        default:
          break;
      }
    });
  }, []);

  const refreshSession = useCallback(() => {
    setAuthLoading(true);
    getUser()
      .then((cognitoUser) => {
        setUser(cognitoUser);
        setAuthLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAuthLoading(false);
      });
  }, []);

  async function getUser() {
    return Auth.currentAuthenticatedUser();
  }

  useEffect(() => {
    refreshSession();
    addAuthListener();
  }, [addAuthListener, refreshSession]);

  const isAuthenticated = user && !authLoading;

  return (
    <UserContext.Provider
      value={{
        getUser,
        isAuthenticated,
        authLoading,
        handleLogInAttempt,
        handleLogOutAttempt,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
