import { API } from "aws-amplify";

import config from "../config";

const isRunningInDevelopment = process.env.NODE_ENV === "development";
const apiKey = isRunningInDevelopment ? config.apiGateway.dev.mercuryApiKey : config.apiGateway.prod.mercuryApiKey;

function makeApiRequest(path, requestBody, method = "post") {
  const body = method === "post" || method === "patch" || method === "put" ? requestBody : undefined;
  const queryStringParameters = method === "get" ? requestBody : undefined;

  const apiConfigs = {
    headers: {
      "x-api-key": apiKey,
      Accept: "*/*",
      "Content-Type": " application/json",
    },
    body: body,
    queryStringParameters: queryStringParameters,
  };

  return API[method]("mercury-company-api", path, apiConfigs);
}

export function handleResponseErrors(error) {
  console.log(error);
  const errorType = "API";
  const { response } = error;
  if (!response) {
    return {
      status: "Unknown",
      errorType,
      message: "Unknown Issue. No Response.",
    };
  }

  const { status } = response;
  if (status === 400) {
    return {
      status,
      errorType,
      message: "Bad Request. Issue with Chrome Extension",
    };
  }
  if (status === 403) {
    return { status, errorType, message: "Authentication Error" };
  }
  if (status === 404) {
    return { status, errorType, message: "Not Found!" };
  }
  if (status === 500) {
    return { status, errorType, message: "Server Error. Issue with APIs" };
  }
  if (status === 503) {
    return { status, errorType, message: "Server Error. Unable to respond" };
  }
  if (status === 504) {
    return {
      status,
      errorType,
      message: "Server Timeout. Try Refreshing Extension",
    };
  }

  return { status, errorType, message: `Status: ${status}. Not sure...` };
}

export function getCompanyBasics(fid) {
  return makeApiRequest("/getCompanyData", { fid });
}

export function fetchCompanyDeals(fid) {
  return makeApiRequest("/getCompanyDeals", { fid });
}

export function markUnqualified(fidList) {
  return makeApiRequest("/mark-unqualified", { fids: fidList });
}

export function snoozeLeads(fidList) {
  return makeApiRequest("/snooze-leads", { fids: fidList });
}

export function sequenceLeads(fidList) {
  return makeApiRequest("/sequence-leads", { fids: fidList });
}

export function getCompanyDiags(fid) {
  return makeApiRequest("/companyDiagnostics", { fid });
}

export function getRulesetDiags(fid, ruleset_id) {
  return makeApiRequest(`/ruleset-diags/${fid}`, { ruleset_id }, "get");
}

export function getCompanyFieldDiags(fid, fieldName) {
  return makeApiRequest(`/field-diags`, { fid, field_name: fieldName });
}

export function getAllRulesets(fid) {
  return makeApiRequest(`/all-rulesets/${fid}`, {}, "get");
}

export function requestRecommendedLeads(limit = 100) {
  return makeApiRequest("/getNextLeads", { num_requested: limit });
}

export function requestRecommendedIndustries() {
  return makeApiRequest("/get-recommended-industries", { num_requested: 100 });
}

export function getIndustryLeads(iid) {
  return makeApiRequest("/get-industry-companies", { iid });
}

export function getIndustryDetails(iid) {
  return makeApiRequest("/get-industry-details", { iid });
}

export function requestMercuryStats() {
  return makeApiRequest("/stats", {}, "get");
}

export function requestMercuryHitRateStats() {
  return makeApiRequest("/stats/hit-rates", {}, "get");
}

export function requestCompanyUpdateBreakdown() {
  return makeApiRequest("/stats/company-update-breakdown", {}, "get");
}

export function requestCompanyCountByStatus() {
  return makeApiRequest("/stats/company-count-by-status", {}, "get");
}

export function requestDataSourceUpdateBreakdown(dataSource) {
  return makeApiRequest(`/stats/data-source-update-breakdown/${dataSource}`, {}, "get");
}

export function requestDataSourceLastThreeMonthsUpdateCounts() {
  return makeApiRequest("/stats/last-three-months-data-source-updated-counts", {}, "get");
}

export function requestFlagStatsTable() {
  return makeApiRequest("/stats/aggregate-table", {}, "get");
}

export function getCompanyHistory(fid) {
  return makeApiRequest("/getCompanyHistory", { fid });
}

export function requestCompanyCompetitors(fid) {
  return makeApiRequest(`/competitors/${fid}`, {}, "get");
}

export function getCompaniesList(locators) {
  return makeApiRequest("/getListCompanies", { locators });
}

export function getPlaylist(id) {
  return makeApiRequest(`/playlists/${id}`, null, "get");
}

export function createPlaylist(owner, name, description) {
  return makeApiRequest(`/playlists`, { owner, name, description }, "post");
}

export function deletePlaylist(id) {
  return makeApiRequest(`/playlists/${id}`, null, "del");
}

export function putPlaylist(id, playlistData) {
  return makeApiRequest(`/playlists/${id}`, playlistData, "put");
}

export function addCompaniesToPlaylist(playlistId, companies) {
  return makeApiRequest(`/playlists/${playlistId}`, companies, "post");
}

export function removePlaylistCompanies(playlistId, fids) {
  return makeApiRequest(`/playlists/${playlistId}`, fids, "patch");
}

export function getPlaylists(limit = 300) {
  return makeApiRequest(`/playlists?limit=${limit}`, null, "get");
}

export function getMarketInvestors(og_fid) {
  return makeApiRequest(`/competitors/investors/${og_fid}`, null, "get");
}

export function getDealsForInvestorName(investor_name) {
  return makeApiRequest(`/investors/${encodeURIComponent(investor_name)}`, null, "get");
}

export function getRulesetLeads(rulesets, limit = 5) {
  const url = `/ruleset-leads?${rulesets
    .map((name) => `ruleset=${encodeURIComponent(name)}`)
    .join("&")}&limit=${limit}`;
  return makeApiRequest(url, null, "get");
}

export function getSalesforceHistory(fid) {
  return makeApiRequest(`/company/sfHistory/${fid}`, null, "get");
}
