import { React, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Card, CardContent, CardHeader, Dialog, Grid, IconButton, makeStyles } from "@material-ui/core";
import { KeyboardArrowDown as DownIcon, KeyboardArrowUp as UpIcon } from "@mui/icons-material";
import { Box, Collapse, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { CollapsibleDescription } from "./collapsibleDescription";

import { CompanySubtitle, Title } from "./Text";
import { prettyPrintDate, prettyPrintInFormat } from "../utilities/TextUtilities";

import { getCompanyFieldDiags, getSalesforceHistory } from "../utilities/ApiFetcher";
import { FieldDiagsTable } from "./tables/FieldDiagsTable";
import { Info } from "@material-ui/icons";

const useStyles = makeStyles({
  statDisplayWrapper: {
    textAlign: "center",
    color: "black",
  },
  modalComponentRoot: {
    minWidth: "50%",
    minHeight: "50%",
    padding: 40,
  },
  finalStatDisplay: {
    marginTop: 20,
    marginBottom: 20,
  },
});

export const InspectableBigStatDisplay = ({ company, stat, format, label, sublabel, field_name }) => {
  const classes = useStyles();
  const hasInspectionField = field_name !== undefined;
  const [modalOpen, setModalOpen] = useState(false);
  const { fid, name } = company;

  const handleIconClick = () => {
    setModalOpen(true);
  };

  return (
    <div className={classes.statDisplayWrapper}>
      <Button endIcon={<Info />} color="black" onClick={hasInspectionField ? handleIconClick : () => {}}>
        <Typography variant="h5">
          <b>{prettyPrintInFormat(stat, format)}</b>
        </Typography>
      </Button>

      <Typography variant="subtitle1">{label}</Typography>
      <Typography variant="subtitle2">
        <i>{sublabel}</i>
      </Typography>

      <InspectableFieldDiagsModalContainer
        open={modalOpen}
        setOpen={setModalOpen}
        fid={fid}
        companyName={name}
        field_name={field_name}
      />
    </div>
  );
};

export const InspectableFieldDiagsModalContainer = ({ open, setOpen, fid, companyName, field_name }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={"sm"}>
      <InspectableFieldDiagsComponent fid={fid} companyName={companyName} field_name={field_name} />
    </Dialog>
  );
};

const InspectableFieldDiagsComponent = ({ fid, companyName, field_name }) => {
  const classes = useStyles();

  const [fieldDiags, setFieldDiags] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState(null);

  const getDiagsForField = useCallback(async () => {
    setIsLoading(true);

    getCompanyFieldDiags(fid, field_name)
      .then((response) => {
        setFieldDiags(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadError(error);
        setIsLoading(false);
      });
  }, [fid]);

  useEffect(() => {
    getDiagsForField();
  }, []);

  const fieldInputs = fieldDiags.inputs ? fieldDiags.inputs : [];

  return (
    <div className={classes.modalComponentRoot}>
      <Title>
        Underlying Stats for Field <b>{field_name}</b> on Company: <b>{companyName}</b>
      </Title>

      <div className={classes.finalStatDisplay}>
        <Typography variant="body1">
          Final Result: <b>{fieldDiags.value}</b>{" "}
        </Typography>
        <Typography variant="body1">
          As of: <b>{prettyPrintDate(fieldDiags.timestamp)}</b>
        </Typography>
      </div>

      <FieldDiagsTable diags={fieldInputs} loading={isLoading} error={loadError} />
    </div>
  );
};
