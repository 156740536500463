import { Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";

import { Subtitle } from "../Text";
import { prettyPrintDollarAmounts } from "../../utilities/TextUtilities";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "60%",
  },
  cardContent: {
    textAlign: "left",
    // padding: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    textAlign: "center",
  },
  outlined: {
    border: "1px solid black",
  },
  centered: {
    textAlign: "center",
    marginBottom: 20,
  },
}));

export const MarketStatsDisplay = ({ stats }) => {
  const classes = useStyles();
  const summary_stats = stats.summary_stats ? stats.summary_stats : {};
  const { total_investment, total_investment_in_past_year, num_comps, num_recommended_comps } = summary_stats;
  const displayStats = [
    {
      value: num_comps,
      label: "# Companies Included",
    },
    {
      value: num_recommended_comps,
      label: "# Recommended Companies",
    },
    {
      value: prettyPrintDollarAmounts(total_investment),
      label: "Total Invested in Market",
    },
    {
      value: prettyPrintDollarAmounts(total_investment_in_past_year),
      label: "Invested in Past 2 Years",
    },
  ];

  if (!summary_stats || Object.keys(summary_stats).length === 0) {
    return <div></div>;
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={0}>
          {displayStats.map((stat, index) => (
            <>
              <Grid item xs={6}>
                <Typography variant="h1" className={classes.title}>
                  <b>{stat.value}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Subtitle text={stat.label.toString()} />
              </Grid>
            </>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
