import { useRef, useState, useEffect } from "react";
import {
  ClearRefinements,
  Configure,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
  Stats,
  connectHits,
} from "react-instantsearch-dom";
import { createURL, urlToSearchState, searchStateToUrl } from "../utilities/typesenseUtils";

import { SearchTable } from "./tables";
import { RangeSliderDropdown, ConnectedNonLinearSlider } from "./RangeSlider";

const MILLION = 1000000;
const DEBOUNCE_TIME = 800;

export function SearchWidget({
  searchClient,
  history,
  location,
  // searchState,
  resultsState,
  onSearchParameters,
  widgetsCollector,
  ...props
}) {
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const debouncedSetStateRef = useRef(null);

  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      history.push(searchStateToUrl(updatedSearchState));
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  return (
    <div>
      <InstantSearch
        indexName="companies"
        {...props}
        searchClient={searchClient}
        searchState={searchState}
        resultsState={resultsState}
        onSearchParameters={onSearchParameters}
        widgetsCollector={widgetsCollector}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Configure hitsPerPage={50} />

        <div className="container-fluid px-md-3 pt-4">
          <div className="row d-flex">
            <div className="col-md-2 d-none d-md-block">
              <SearchBox searchAsYouType={true} showLoadingIndicator={true} />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-2 pr-md-4">
              {/*<h5>Browse by Categories</h5>*/}
              {/*<HierarchicalMenu*/}
              {/*  className="mt-3"*/}
              {/*  attributes={[*/}
              {/*    'industries.lvl0',*/}
              {/*    'industries.lvl1',*/}
              {/*    'industries.lvl2',*/}
              {/*    'industries.lvl3',*/}
              {/*  ]}*/}
              {/*  showParentLevel={true}*/}
              {/*  rootPath={"Cell Phones"}*/}
              {/*  limit={50}*/}
              {/*/>*/}
              <h5 className="mt-3">Filter by Mercury Status</h5>
              <RefinementList
                className="mt-3"
                attribute="mercury_status"
                label="Mercury Status"
                limit={5}
                showMore={true}
                showMoreLimit={10}
              />
              <h5 className="mt-3">Filter by Business Model</h5>
              <RefinementList
                className="mt-3"
                attribute="business_model"
                label="Business Model"
                limit={5}
                showMore={true}
                showMoreLimit={10}
                value={true}
              />
              {/* <h5 className="mt-3">Filter by Company Stage</h5> */}
              {/* <RefinementList className="mt-3" attribute="company_stage" label="Stage" limit={10} value={true} /> */}
              <h5 className="mt-3">Filter by Lead Owner</h5>
              <RefinementList
                className="mt-3"
                attribute="lead_owner_last"
                label="Lead Owner"
                limit={5}
                showMore={true}
                showMoreLimit={10}
                value={true}
              />
              <h5 className="mt-3">Filter by Reason Recommended</h5>
              <RefinementList
                className="mt-3"
                attribute="recommendation_reason"
                label="Recommendation Reason"
                limit={5}
                showMore={true}
                showMoreLimit={15}
                value={true}
              />
              <h5 className="mt-3">Filter by Keyword</h5>
              <RefinementList
                className="mt-3"
                attribute="keywords"
                label="Keywords"
                limit={3}
                showMore={true}
                showMoreLimit={10}
                searchable={true}
                sortBy={["isRefined:desc", "count:desc"]}
                // value={true}
              />
              <h5 className="mt-3">Filter by Country</h5>
              <RefinementList
                className="mt-3"
                attribute="country"
                limit={5}
                showMore={true}
                showMoreLimit={20}
                searchable={true}
                transformItems={(items) => items.sort((a, b) => (a.count < b.count ? 1 : -1))}
              />
              <h5 className="mt-3">Filter by City</h5>
              <RefinementList
                className="mt-3"
                attribute="city"
                limit={5}
                showMore={true}
                showMoreLimit={20}
                searchable={true}
                transformItems={(items) => items.sort((a, b) => (a.count < b.count ? 1 : -1))}
              />
              <div className="mt-3" />
              <RangeSliderDropdown label="Company Age">
                <ConnectedNonLinearSlider
                  label="Company Age"
                  attribute="age"
                  format="integer"
                  increments={[1, 3, 5, 10, 15, 25, 50, 100]}
                />
              </RangeSliderDropdown>
              <RangeSliderDropdown label="Empl. Count">
                <ConnectedNonLinearSlider
                  label="Employee Count"
                  attribute="employees"
                  format="integer"
                  increments={[-100, -50, 0, 10, 25, 50, 75, 100, 150, 200, 500]}
                />
              </RangeSliderDropdown>
              <RangeSliderDropdown label="Empl. Growth">
                <ConnectedNonLinearSlider
                  label="Employee Growth"
                  attribute="yoy_emp_growth"
                  format="percent"
                  increments={[-100, -50, 0, 15, 30, 50, 75, 100, 125, 150, 200, 300, 500]}
                />
              </RangeSliderDropdown>
              <RangeSliderDropdown label="Total Raised">
                <ConnectedNonLinearSlider
                  label="Total Raised"
                  attribute="total_raised"
                  format="currency"
                  increments={[
                    0,
                    1 * MILLION,
                    3 * MILLION,
                    5 * MILLION,
                    10 * MILLION,
                    20 * MILLION,
                    30 * MILLION,
                    40 * MILLION,
                    50 * MILLION,
                    75 * MILLION,
                    100 * MILLION,
                    250 * MILLION,
                    500 * MILLION,
                    1000 * MILLION,
                    5000 * MILLION,
                  ]}
                />
              </RangeSliderDropdown>{" "}
              <RangeSliderDropdown label="Last Raise Size">
                <ConnectedNonLinearSlider
                  label="Last Raised Amount"
                  attribute="last_financing_size"
                  format="currency"
                  increments={[
                    0,
                    1 * MILLION,
                    3 * MILLION,
                    5 * MILLION,
                    10 * MILLION,
                    20 * MILLION,
                    30 * MILLION,
                    40 * MILLION,
                    50 * MILLION,
                    75 * MILLION,
                    100 * MILLION,
                    250 * MILLION,
                    500 * MILLION,
                    1000 * MILLION,
                    5000 * MILLION,
                  ]}
                />
              </RangeSliderDropdown>
              <ClearRefinements className="mt-3" />
            </div>

            <div className="col-md">
              <div className="row mt-5 mt-md-0">
                <div className="col-md">
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8 d-flex justify-content-end align-items-center">
                      <Stats
                        translations={{
                          stats(nbHits, processingTimeMS) {
                            let hitCountPhrase;
                            if (nbHits === 0) {
                              hitCountPhrase = "No companies";
                            } else if (nbHits === 1) {
                              hitCountPhrase = "1 company";
                            } else {
                              hitCountPhrase = `${nbHits.toLocaleString()} companies`;
                            }
                            return `${hitCountPhrase} found in ${processingTimeMS.toLocaleString()}ms`;
                          },
                        }}
                      />
                      {/* <HitsPerPage
                        className="ms-4"
                        items={[
                          { label: "20 per page", value: 20 },
                        ]}
                        defaultRefinement={20}
                      /> */}
                      {/*<SortBy*/}
                      {/*  items={[*/}
                      {/*    {label: 'Relevancy', value: 'products'},*/}
                      {/*    {label: 'Price (asc)', value: 'products/sort/price:asc'},*/}
                      {/*    {label: 'Price (desc)', value: 'products/sort/price:desc'},*/}
                      {/*  ]}*/}
                      {/*  defaultRefinement="products"*/}
                      {/*/>*/}
                    </div>
                  </div>
                </div>
              </div>
              <CustomHitsPerPage />
              <div className="row">
                <div className="col-sm">
                  <Pagination />
                </div>
              </div>
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

const MyHits = ({ hits }) => {
  const finalHits = hits.length > 100 ? hits.slice(0, 100) : hits;
  return <SearchTable companies={finalHits} />;
};

const CustomHitsPerPage = connectHits(MyHits);
