import Stack from "@mui/material/Stack";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Title } from "./Text";
import { conditionalParseJSON, prettyPrintLocation } from "../utilities/TextUtilities";
import { CollapsibleDescription } from "./collapsibleDescription";
import {
  Launch as LaunchIcon,
  Link as LinkIcon,
  Send as SendIcon,
  ThumbDown as ThumbDownIcon,
  ThumbUp as ThumbUpIcon,
} from "@mui/icons-material";
import { createInvestorLinkFromName, handleLinkClick } from "../utilities/navigationUtils";
import { CompanyCardChart } from "./charts";
import React, { useState } from "react";
import { PlaylistPickerModal } from "./PlaylistPicker";
import { CompanyCardChips } from "./CompanyCardChips";
import { ExternalCompanyLinks } from "./ExternalCompanyLinks";

const useStyles = makeStyles({
  companyCardRoot: {
    padding: 10,
    paddingLeft: 20,
    margin: 30,
    color: "black",
  },
  standardFont: {
    fontFamily: "Mukta",
    fontWeight: 400,
  },
  keywordChips: {
    marginBottom: 30,
  },
  nameWithLink: {
    display: "inline-flex",
    marginTop: 10,
    padding: 5,
  },
  chartsTitle: {
    width: "30%",
    margin: "auto",
    textAlign: "center",
    marginBottom: 20,
  },
  actionStack: {
    marginTop: 40,
  },
});

export const CompanyCard = ({ company, removeLeadsFromList }) => {
  const classes = useStyles();
  const [playlistModalOpen, setPlaylistModalOpen] = useState(false);
  const parsedInvestorList = parseJsonInvestorsIntoList(company.investors);
  const hasInvestors = parsedInvestorList.length > 0;

  return (
    <Card className={classes.companyCardRoot}>
      <CardHeader
        avatar={<Avatar alt={company.name} src={company.logo_url} />}
        action={
          <Button endIcon={<LaunchIcon />} onClick={handleLinkClick("/company/" + company.fid)}>
            {company.mercury_status}
          </Button>
        }
        title={<CompanyNameWithLink company={company} />}
        subheader={<i>{prettyPrintLocation(company)}</i>}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <div className={classes.keywordChips}>
              <CompanyCardChips company={company} maxCount={10} />
            </div>

            <CollapsibleDescription description={company.description} sliceLength={450} />

            {hasInvestors && (
              <div>
                <b>Investors: </b>
                <InvestorList investorList={parsedInvestorList} showCount={10} />
              </div>
            )}

            <div className={classes.actionStack}>
              <Stack direction="row" spacing={2}>
                <ExternalCompanyLinks
                  companyBasics={company}
                  removeLeadsFromList={removeLeadsFromList}
                  linkSpacing={2}
                />

                <IconButton aria-label="like">
                  <ThumbUpIcon />
                </IconButton>
                <IconButton aria-label="dislike">
                  <ThumbDownIcon />
                </IconButton>
              </Stack>
            </div>
          </Grid>

          <Grid item xs={5}>
            <div className={classes.chartsTitle}>
              <Title>
                <i>Last Year v. Today</i>
              </Title>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <CompanyCardChart company={company} statLabel={"ARR"} statFormat={"currency"} />
              </Grid>
              <Grid item xs={4}>
                <CompanyCardChart company={company} statLabel={"Total Raised"} statFormat={"currency"} />
              </Grid>
              <Grid item xs={4}>
                <CompanyCardChart company={company} statLabel={"Employees"} statFormat={"integer"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PlaylistPickerModal open={playlistModalOpen} setOpen={setPlaylistModalOpen} companies={[company]} />
      </CardContent>
    </Card>
  );
};

export const CompanyNameWithLink = ({ company }) => {
  const companyUrl = "https://" + company.domain;

  return (
    <Button
      endIcon={<LinkIcon />}
      sx={{ ml: 6, textTransform: "capitalize" }}
      color="black"
      aria-label="go to link"
      onClick={handleLinkClick(companyUrl)}
    >
      <Title>
        <b>{company.name}</b>
      </Title>
    </Button>
  );
};

export const parseJsonInvestorsIntoList = (investorsJSON) => {
  const parsedInvestors = !investorsJSON ? [] : conditionalParseJSON(investorsJSON);
  const goodInvestors = parsedInvestors.filter((word) => word.length > 2);
  return goodInvestors;
};

export const parseCommaSeparatedInvestorsIntoList = (investors) => {
  const parsedInvestors = !investors ? [] : investors.split(",");
  var goodInvestors = parsedInvestors.filter((word) => word.length > 2);
  goodInvestors = goodInvestors.map((investor) => investor.split(" (")[0].trim());
  goodInvestors = [...new Set(goodInvestors)];
  return goodInvestors;
};

export const InvestorList = ({ investorList, showCount }) => {
  const sliceKeywords = investorList.slice(0, showCount);

  return (
    <Typography variant={"body2"} color={"textSecondary"}>
      {sliceKeywords.map((investor, index) => [index > 0 && ", ", createInvestorLinkFromName(investor)])}
    </Typography>
  );
};
