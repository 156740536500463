import classNames from "classnames";
import { Paper, makeStyles } from "@material-ui/core";

import { AppHeader } from "../containers";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#D5DCE0",
  },
  rootSkinny: {
    width: "100vw",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#D5DCE0",
  },
  paper: {
    width: "100vw",
    borderRadius: 10,
    padding: 20,
    backgroundColor: "#D5DCE0",
  },

  paperSkinny: {
    margin: theme.spacing(2),
  },
}));

export function Page({ children, showHeader = true }) {
  const classes = useStyles();

  const rootClass = classNames({
    [classes.root]: showHeader,
    [classes.rootSkinny]: !showHeader,
  });
  const paperClass = classNames({
    [classes.paper]: showHeader,
    [classes.paperSkinny]: !showHeader,
  });

  return (
    <div className={rootClass}>
      {showHeader && <AppHeader />}

      <Paper className={paperClass} elevation={0}>
        {children}
      </Paper>
    </div>
  );
}
