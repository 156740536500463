import { CompanyDetailsPageComponent } from "../components/CompanyDetailsPageComponent";
import { CompanyCompetitorsPageComponent } from "../components/CompanyCompetitorsPageComponent";
import { useCallback, useEffect, useState } from "react";
import { fetchCompanyDeals, getCompanyBasics, handleResponseErrors } from "../utilities/ApiFetcher";
import { useSnackbar } from "notistack";
import { LoadingCircle } from "../components/loadingCircle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  companyDetailsHome: {},
  companyDetailsOverview: {
    marginBottom: "1vh",
  },
  companyDetailsCompetitors: {
    marginTop: "1vh",
  },
});

export function CompanyDetailsContainer({ fid }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [companyBasics, setCompanyBasics] = useState(null);
  const [companyDeals, setCompanyDeals] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const doGetCompanyBasics = useCallback(async () => {
    setIsLoading(true);
    getCompanyBasics(fid)
      .then((companyBasics) => {
        setCompanyBasics(companyBasics);
        setIsLoading(false);
        enqueueSnackbar("Successfully loaded summary info!", {
          variant: "success",
        });
        return companyBasics;
      })
      .catch((error) => {
        handleResponseErrors(error);
        setIsLoading(false);
        enqueueSnackbar("Had error loading summary info.", {
          variant: "error",
        });
      });
  }, [setCompanyBasics, enqueueSnackbar, fid]);

  useEffect(() => {
    doGetCompanyBasics();
  }, []);

  const doGetCompanyDeals = useCallback(async () => {
    setIsLoading(true);
    fetchCompanyDeals(fid)
      .then((companyDeals) => {
        setCompanyDeals(companyDeals);
        setIsLoading(false);
        enqueueSnackbar("Successfully loaded deal history!", {
          variant: "success",
        });
        return companyDeals;
      })
      .catch((error) => {
        handleResponseErrors(error);
        setIsLoading(false);
        enqueueSnackbar("Had error loading deal history.", {
          variant: "error",
        });
      });
  }, [setCompanyDeals, enqueueSnackbar, fid]);

  useEffect(() => {
    doGetCompanyDeals();
  }, []);

  if (isLoading) {
    return <LoadingCircle />;
  }

  return (
    <>
      {companyBasics && companyBasics.fid && (
        <div className={classes.companyDetailsHome}>
          <div className={classes.companyDetailsOverview}>
            <CompanyDetailsPageComponent companyBasics={companyBasics} companyDeals={companyDeals} />
          </div>

          <div className={classes.companyDetailsCompetitors}>
            <CompanyCompetitorsPageComponent companyBasics={companyBasics} />
          </div>
        </div>
      )}
    </>
  );
}
