import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import { actionsNames, ALL_COLUMNS, tableActions, updateTableDataWithApiActions } from "./tableTools";
import { DynamicTable } from "./dynamicTable";

const COLUMNS = [
  ALL_COLUMNS.COMPANY,
  ALL_COLUMNS.WEBSITE,
  ALL_COLUMNS.MERCURY_STATUS,
  ALL_COLUMNS.LOCATION,
  ALL_COLUMNS.NUM_EMPLS,
  ALL_COLUMNS.YOY_EMP_GROWTH,
  ALL_COLUMNS.TOTAL_RAISED,
  ALL_COLUMNS.LAST_RAISE_AMOUNT,
  ALL_COLUMNS.LAST_RAISE_DATE,
  ALL_COLUMNS.GROWTH_ESTIMATE,
  ALL_COLUMNS.REVENUE_ESTIMATE,
];

export const PlaylistDetailTable = ({ playlistCompanies, setPlaylistUpdated }) => {
  const { id: playlistId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const updateTableData = async (data, action) => {
    const fidArray = data.map(({ fid }) => fid);
    const params = { fidList: fidArray, enqueueSnackbar, action, playlistId };
    await updateTableDataWithApiActions(params);
    setPlaylistUpdated(true);
  };

  const actions = [
    tableActions[actionsNames.REMOVE_FROM_PLAYLIST](updateTableData),
    tableActions[actionsNames.NOT_INTERESTED](updateTableData),
    tableActions[actionsNames.SNOOZE](updateTableData),
  ];

  return (
    <DynamicTable updateTableData={updateTableData} columns={COLUMNS} data={playlistCompanies} actions={actions} />
  );
};
