import * as React from "react";
import MaterialTable from "material-table";
import { prettyPrintInFormat } from "../../utilities/TextUtilities";
import { ChevronRight } from "@material-ui/icons";
import { flattenDiagValuesAndSources } from "./RulesetDiagsTable";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Typography } from "@material-ui/core";

const prettyDataSourceName = (source_name) => {
  // eslint-disable-next-line default-case
  switch (source_name) {
    case "cb_company":
      return "Crunchbase";

    case "pb_company":
      return "Pitchbook";

    case "harmonic_new":
      return "LinkedIn";

    case "tracxn":
      return "Tracxn Data";

    case "company_snapshot_latest":
      return "Derived";

    case "google_geo":
      return "Google";

    case "sem_backlinks":
      return "SEMRush";

    case "sem_overview":
      return "SEMRush";

    case "sf_outputs":
      return "FLIP Salesforce";

    case "apollo":
      return "Apollo";
  }

  return source_name;
};

const getNodeColor = ({ tableData }) => {
  const { path } = tableData;
  const lastElement = path.slice(-1);
  if (lastElement % 2 === 0) {
    return "#e1e2e3";
  }

  return "#ffffff";
};

const getLeftPadding = ({ path }) => {
  const indentLevel = path.length - 1;
  return indentLevel * 30;
};

const FieldDiagsColumns = [
  {
    title: "Source",
    render: ({ source_name }) => prettyDataSourceName(source_name),
    width: "10%",
  },
  {
    title: "Field",
    field: "field_name",
    width: "20%",
  },
  {
    title: "Value",
    field: "value",
    width: "50%",
    render: ({ value }) => <HoverableValue value={value} maxChars={50} />,
  },
  {
    title: "Timestamp",
    type: "string",
    render: ({ timestamp }) => prettyPrintInFormat(timestamp, "date"),
    width: "20%",
  },
];

export function FieldDiagsTable({ diags, loading, error }) {
  const flattenedTableDataWithIds = flattenDiagValuesAndSources(diags);

  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <MaterialTable
      icons={{ DetailPanel: () => <ChevronRight /> }}
      columns={FieldDiagsColumns}
      data={flattenedTableDataWithIds}
      options={{
        selection: false,
        toolbar: false,
        showTitle: true,
        search: false,
        paging: false,
        sorting: false,
        draggable: false,
        filtering: false,
        exportButton: false,
        rowStyle: (rowData) => ({
          backgroundColor: getNodeColor(rowData),
        }),
        cellStyle: (rowDataArray, rowData) => {
          const paddingLeft = getLeftPadding(rowData.tableData);
          return { paddingLeft: paddingLeft };
        },
      }}
      parentChildData={(row, rows) => rows.find((a) => a.nodeId === row.parentId)}
    />
  );
}

const HoverableValue = ({ value, maxChars }) => {
  if (value === undefined || value === null) {
    return "";
  }

  const fullValueString = value.toString();
  const isLongDescription = fullValueString.length > maxChars;

  if (!isLongDescription) {
    return <Typography variant="body1">{fullValueString}</Typography>;
  }

  const shortText = !isLongDescription ? fullValueString : fullValueString.slice(0, maxChars) + "...";
  return (
    <CustomWidthTooltip title={fullValueString}>
      <Typography variant="body1">{shortText}</Typography>
    </CustomWidthTooltip>
  );
};

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: 12,
  },
});
