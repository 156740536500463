import React, { useCallback, useEffect, useState } from "react";
import { Page as PageComponent } from "../components/page";
import { Subtitle, Title } from "../components/Text";

import { useParams } from "react-router-dom";
import { getDealsForInvestorName, handleResponseErrors } from "../utilities/ApiFetcher";
import { useSnackbar } from "notistack";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { DealsTable } from "../components/tables";
import { prettyPrintDollarAmounts, titleCase } from "../utilities/TextUtilities";
import { InvestorDealsByYearBarChart, InvestorDealsHistogram } from "../components/charts";

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    paddingTop: 20,
    marginLeft: 40,
    margin: 20,
    fontSize: 20,
  },
  pageStatSummary: {
    paddingBottom: 40,
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  investorStats: {
    paddingTop: 60,
  },
  dealTable: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 40,
  },
  cardRoot: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "60%",
  },
  cardContent: {
    textAlign: "left",
    // padding: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    textAlign: "center",
  },
  outlined: {
    border: "1px solid black",
  },
  centered: {
    textAlign: "center",
    marginBottom: 20,
  },
}));

const getBasicInvestorStats = (deals) => {
  const num_deals = deals.length;
  const num_deals_in_past_two_years = deals.filter((deal) => {
    const today = new Date();
    const two_yrs_ago = today.setFullYear(today.getFullYear() - 2);
    return Date.parse(deal.deal_date) > two_yrs_ago;
  }).length;

  const max_deal_size = Math.max(...deals.filter((d) => d.deal_size).map((d) => d.deal_size));
  const avg_deal_size = deals.reduce((acc, deal) => acc + deal.deal_size, 0) / num_deals;
  const avg_investment_point = deals.reduce((acc, deal) => acc + (deal.raised_to_date - deal.deal_size), 0) / num_deals;

  const result = { num_deals, num_deals_in_past_two_years, avg_deal_size, avg_investment_point, max_deal_size };
  return result;
};

export function InvestorPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [investorDeals, setInvestorDeals] = useState([]);
  const { investor_name } = useParams();
  const cleanInvestorName = decodeURI(investor_name);
  const presentationInvestorName = titleCase(cleanInvestorName);

  const investorStats = getBasicInvestorStats(investorDeals);

  const doGetInvestorDeals = useCallback(async () => {
    getDealsForInvestorName(cleanInvestorName)
      .then(({ deals }) => {
        setInvestorDeals(deals);
        enqueueSnackbar("Successfully loaded investor info!", {
          variant: "success",
        });
      })
      .catch((error) => {
        handleResponseErrors(error);
        enqueueSnackbar("Had error loading deal info.", {
          variant: "error",
        });
      });
  }, [setInvestorDeals, enqueueSnackbar, cleanInvestorName]);

  useEffect(() => {
    doGetInvestorDeals();
  }, [doGetInvestorDeals]);

  return (
    <PageComponent showHeader={true} skinnyPage={false}>
      <div className={classes.pageTitle}>
        <Title>
          <b>{presentationInvestorName}</b> Deals:
        </Title>
      </div>

      <div className={classes.pageStatSummary}>
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <div className={classes.investorStats}>
              <InvestorStatsDisplay stats={investorStats} />
            </div>
          </Grid>
          <Grid item xs={5}>
            <InvestorDealsByYearBarChart deals={investorDeals} />
          </Grid>
          <Grid item xs={5}>
            <InvestorDealsHistogram deals={investorDeals} />
          </Grid>
        </Grid>
      </div>

      <div className={classes.dealTable}>
        <DealsTable deals={investorDeals} />
      </div>
    </PageComponent>
  );
}

export const InvestorStatsDisplay = ({ stats }) => {
  const classes = useStyles();
  const displayStats = [
    {
      value: stats?.num_deals,
      label: "# Total Deals",
    },
    {
      value: stats?.num_deals_in_past_two_years,
      label: "# Deals Past 2 Years",
    },
    {
      value: prettyPrintDollarAmounts(stats?.avg_deal_size),
      label: "Avg. Round Size",
    },
    {
      value: prettyPrintDollarAmounts(stats?.max_deal_size),
      label: "Max Round Size",
    },
    {
      value: prettyPrintDollarAmounts(stats?.avg_investment_point),
      label: "Avg. Raised @ Investment",
    },
  ];

  if (!stats || Object.keys(stats).length === 0) {
    return <div></div>;
  }

  return (
    <Grid container spacing={0}>
      {displayStats.map((stat, index) => (
        <>
          <Grid item xs={6}>
            <Typography variant="h1" className={classes.title}>
              <b>{stat.value}</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Subtitle text={stat.label.toString()} />
          </Grid>
        </>
      ))}
    </Grid>
  );
};
