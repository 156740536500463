import { makeStyles } from "@material-ui/core";
import { Page as PageComponent } from "../components/page";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRulesetLeads } from "../utilities/ApiFetcher";
import { LoadingCircle } from "../components/loadingCircle";
import { LeadsList } from "../components/leadsList";

export function RulesetLeadsPage() {
  return (
    <PageComponent>
      <RulesetLeadsComponent />
    </PageComponent>
  );
}

const RulesetLeadsComponent = () => {
  const { rulesets } = useParams();
  const unencodedRulesets = decodeURIComponent(rulesets);
  const rulesetsArray = unencodedRulesets.split("&");

  const [rulesetLeads, setRulesetLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const rulesetLeads = await getRulesetLeads(rulesetsArray, 100);
      setRulesetLeads(rulesetLeads);
      setIsLoading(false);
    })();
  }, []);

  const removeLeadsFromTable = (fids) => {
    const currentLeads = rulesetLeads;
    const filteredLeads = currentLeads.filter((l) => !fids.includes(l.fid));
    setRulesetLeads(filteredLeads);
  };

  if (isLoading) {
    return <LoadingCircle />;
  }

  if (!isLoading && error) {
    return <div>{error.message}</div>;
  }

  return <LeadsList listLeads={rulesetLeads} removeLeadsFromTable={removeLeadsFromTable} />;
};
