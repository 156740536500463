import { Bar } from "react-chartjs-2";
import { prettyPrintDollarAmounts } from "../../utilities/TextUtilities";

const options = {
  plugins: {
    title: {
      display: true,
      text: "Investment over Time",
    },
  },
  scales: {
    yAxisLeft: {
      id: "y-axis-left",
      type: "linear",
      position: "left",
      display: true,
      grid: { display: false },
    },
    yAxisRight: {
      id: "y-axis-right",
      type: "linear",
      position: "right",
      display: true,
      ticks: {
        beginAtZero: true,
        callback: function (tick, index, ticks) {
          return prettyPrintDollarAmounts(tick);
        },
        grid: { display: false },
      },
    },
  },
};

const getYearRange = (numYearsBack) => {
  const thisYear = new Date().getFullYear();
  const firstYear = thisYear - numYearsBack + 1; // Align correctly
  const years = Array.from({ length: numYearsBack }, (_, i) => firstYear + i);
  return years;
};

const getDealCountsByYear = (deals) => {
  const counts = {};
  const invested = {};
  for (const deal of deals) {
    const dealYear = deal.deal_year;
    const dealInvestment = deal.deal_size;
    counts[dealYear] = counts[dealYear] ? counts[dealYear] + 1 : 1;
    invested[dealYear] = invested[dealYear] ? invested[dealYear] + dealInvestment : dealInvestment;
  }

  return { deal_counts: counts, total_invested: invested };
};

export const InvestorDealsByYearBarChart = ({ deals }) => {
  const yearRange = getYearRange(10);
  const { deal_counts, total_invested } = getDealCountsByYear(deals);

  const chartData = {
    labels: yearRange,
    datasets: [
      {
        label: "# Deals",
        data: yearRange.map((y) => (deal_counts[y] ? deal_counts[y] : 0)),
        backgroundColor: "#6565ee",
        yAxisID: "yAxisLeft",
      },
      {
        label: "$ Invested",
        data: yearRange.map((y) => (total_invested[y] ? total_invested[y] : 0)),
        backgroundColor: "#9a45c1",
        yAxisID: "yAxisRight",
      },
    ],
  };

  return <Bar data={chartData} options={options} />;
};
