import { useState, useCallback, useEffect } from "react";

import { LoadingCircle } from "../../components/loadingCircle";
import { MarketTabDataChecker } from "../../components/companyTabs/companyTabComps";
import { requestCompanyCompetitors, handleResponseErrors } from "../../utilities/ApiFetcher";

const LOADING_STAGES = {
  LOADING_COMPS_LIST: 0,
  LOADED_COMPS_LIST: 1,
  FAILED: -1,
};

export function CompanyTabComps({ fid, companyBasics, skinnyPage }) {
  const [errorState, setErrorState] = useState(undefined);
  const [comps, setComps] = useState([]);
  const [compStats, setCompStats] = useState([]);
  const [loadingStage, setLoadingStage] = useState(LOADING_STAGES.LOADING_COMPS_LIST);

  const lazyGetCompetitorData = useCallback(async () => {
    setLoadingStage(LOADING_STAGES.LOADING_COMPS_LIST);

    requestCompanyCompetitors(fid)
      .then((response) => {
        const { competitors, stats } = response;
        setComps(competitors);
        setCompStats(stats);
        setLoadingStage(LOADING_STAGES.LOADED_COMPS_LIST);
      })
      .catch((error) => {
        const errorState = handleResponseErrors(error);
        setErrorState(errorState);
        setLoadingStage(LOADING_STAGES.FAILED);
      });
  }, [fid]);

  useEffect(() => {
    lazyGetCompetitorData();
  }, [lazyGetCompetitorData]);

  const isLoading = loadingStage === LOADING_STAGES.LOADING_COMPS_LIST;
  const readyToRender = comps && !isLoading;

  return (
    <>
      {isLoading && <LoadingCircle />}
      {readyToRender && (
        <MarketTabDataChecker
          companyBasics={companyBasics}
          comps={comps}
          compStats={compStats}
          loadingStage={loadingStage}
          errorState={errorState}
          skinnyPage={skinnyPage}
        />
      )}
    </>
  );
}
