import { useEffect, useState } from "react";
import { BarChart } from "../../components/charts/BarChart";
import { processLabelAndCountData } from "../../utilities/chartHelpers";
import { handleResponseErrors, requestDataSourceLastThreeMonthsUpdateCounts } from "../../utilities/ApiFetcher";

export const DataSourceLastThreeMonthsChartContainer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const getChartData = async () => {
    setLoading(true);
    try {
      const rawData = await requestDataSourceLastThreeMonthsUpdateCounts();
      setData(processLabelAndCountData(rawData));
      setLoading(false);
    } catch (err) {
      setError(handleResponseErrors(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    getChartData();
  }, []);

  return <BarChart data={data} error={error} isLoading={loading} options={options} />;
};
