import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { Title } from "../components/Text";
import { Page as PageComponent } from "../components/page";
import { CompanyUpdatedChartContainer } from "../containers/charts/CompanyUpdatedChartContainer";
import { DataSourceLastThreeMonthsChartContainer } from "../containers/charts/DataSourceLastThreeMonthsChartContainer";
import { DataSourceUpdatedChartContainer } from "../containers/charts/DataSourceUpdatedChartContainer";
import { CompanyCountsByStatusTable, FlagStatsTable } from "../components/tables";

const useStyles = makeStyles({
  item: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 40,
    height: "100%",
  },
});

export function StatsPage() {
  const classes = useStyles();

  return (
    <PageComponent>
      <Grid container={true}>
        <Grid item={true} xs={6} sx={6} md={6} lg={6} xl={4}>
          <div className={classes.item}>
            <Title text={"Company Statuses"} />
            <CompanyCountsByStatusTable />
          </div>
        </Grid>
        <Grid item={true} xs={6} sx={6} md={6} lg={6} xl={4}>
          <div className={classes.item}>
            <Title text={"Recommendation Flags"} />
            <FlagStatsTable />
          </div>
        </Grid>
        <Grid item={true} xs={6} sx={6} md={6} lg={6} xl={6}>
          <div className={classes.item}>
            <Title text={"Company Updates by Month"} />
            <CompanyUpdatedChartContainer />
          </div>
        </Grid>
        <Grid item={true} xs={6} sx={6} md={6} lg={6} xl={6}>
          <div className={classes.item}>
            <Title text={"Added To Data Sources in the Last 3 Months"} />
            <DataSourceLastThreeMonthsChartContainer />
          </div>
        </Grid>
        <Grid item={true} xs={2} sx={2} md={2} lg={2} xl={2}>
          <div className={classes.item}>
            <Title text={"Tracxn Updates"} />
            <DataSourceUpdatedChartContainer source={"tracxn"} />
          </div>
        </Grid>
        <Grid item={true} xs={2} sx={2} md={2} lg={2} xl={2}>
          <div className={classes.item}>
            <Title text={"Harmonic Updates"} />
            <DataSourceUpdatedChartContainer source={"harmonic"} />
          </div>
        </Grid>
        <Grid item={true} xs={2} sx={2} md={2} lg={2} xl={2}>
          <div className={classes.item}>
            <Title text={"Website Updates"} />
            <DataSourceUpdatedChartContainer source={"scraped"} />
          </div>
        </Grid>

        <Grid item={true} xs={2} sx={2} md={2} lg={2} xl={2}>
          <div className={classes.item}>
            <Title text={"Semrush Updates"} />
            <DataSourceUpdatedChartContainer source={"semrush"} />
          </div>
        </Grid>
        <Grid item={true} xs={2} sx={2} md={2} lg={2} xl={2}>
          <div className={classes.item}>
            <Title text={"Apollo Updates"} />
            <DataSourceUpdatedChartContainer source={"apollo"} />
          </div>
        </Grid>
        <Grid item={true} xs={2} sx={2} md={2} lg={2} xl={2}>
          <div className={classes.item}>
            <Title text={"Crunchbase Updates"} />
            <DataSourceUpdatedChartContainer source={"crunchbase"} />
          </div>
        </Grid>
      </Grid>
    </PageComponent>
  );
}
