import { useEffect, useState } from "react";

import { putPlaylist } from "../utilities/ApiFetcher";
import { EditPlaylistModal as EditPlaylistModalComponent } from "../components/EditPlaylistModal";

export const EditPlaylistModal = ({ open, setOpen, nameProp, playlistId, descriptionProp, setPlaylistUpdated }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName(nameProp);
    setDescription(descriptionProp);
  }, [nameProp, descriptionProp]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await putPlaylist(playlistId, { name, description });
    setPlaylistUpdated(true);
  };

  return (
    <EditPlaylistModalComponent
      open={open}
      setOpen={setOpen}
      handleSubmit={handleSubmit}
      description={description}
      setDescription={setDescription}
      name={name}
      setName={setName}
    />
  );
};
