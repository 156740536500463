import { createBaseSearchState, createURL } from "./typesenseUtils";
import { Link } from "@material-ui/core";
import { titleCase } from "./TextUtilities";
import React from "react";

export const handleLinkClick =
  (url, newTab = true) =>
  (event) => {
    event.preventDefault();
    if (url) {
      const target = newTab ? "_blank" : "_self";
      window.open(url, target);
    }
  };

export const createUrlLinkForKeyword = (keyword) => {
  const keywords = [keyword];
  const searchState = createBaseSearchState({ keywords });
  const urlLink = "/search" + createURL(searchState);
  return urlLink;
};

export const createInvestorLinkFromName = (name) => {
  return (
    <Link href={"/investor/" + encodeURIComponent(name.toLowerCase())} target="_blank">
      {titleCase(name)}
    </Link>
  );
};

export const createSalesforceUrlFromLeadId = (sfLeadId) => {
  return `https://fullin.lightning.force.com/lightning/r/Lead/${sfLeadId}/view`;
};
