import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, makeStyles } from "@material-ui/core";

import { emailToName } from "../utilities/TextUtilities";
import { EditPlaylistModal } from "../containers/EditPlaylistModal";
import { DeletePlaylistModal } from "../containers/DeletePlaylistModal";
import { LeadsList } from "./leadsList";

const useStyles = makeStyles((theme) => ({
  intro: {
    marginLeft: 30,
  },
  introRight: {
    marginRight: 30,
  },
  headerGrid: {
    paddingBottom: 40,
    alignItems: "center",
  },
  pagePadding: {
    padding: 40,
  },
  playlistName: {
    textAlign: "start",
    margin: 0,
    padding: 0,
  },
  playlistMeta: {
    textAlign: "start",
    margin: 0,
    padding: 0,
    fontSize: 18,
  },
  buttons: {
    display: "flex",
    justifyContent: "end",
  },
  descriptionHeader: {
    padding: 0,
    margin: 0,
  },
  descriptionText: {
    padding: 0,
    margin: 0,
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
}));

export const PlaylistDetail = ({
  playlist,
  playlistCompanies,
  isLoading,
  setPlaylistUpdated,
  removeLeadsFromTable,
}) => {
  const classes = useStyles();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const { id } = useParams();

  if (isLoading) {
    return null;
  }

  return (
    <div className={classes.pagePadding}>
      <DeletePlaylistModal open={deleteModalOpen} setOpen={setDeleteModalOpen} playlistId={id} />
      <EditPlaylistModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        playlistId={id}
        nameProp={playlist.name}
        descriptionProp={playlist.description}
        setPlaylistUpdated={setPlaylistUpdated}
      />
      <Grid container={true} className={classes.headerGrid}>
        <Grid item={true} xs={4} sx={4} md={4} lg={4} xl={4}>
          <div className={classes.intro}>
            <h2 className={classes.playlistName}>{playlist.name}</h2>
            <p className={classes.playlistMeta}>
              {emailToName(playlist.owner)} - Created {new Date(playlist["created_at"]).toLocaleDateString()}
            </p>
          </div>
        </Grid>
        <Grid item={true} xs={4} sx={4} md={4} lg={4} xl={4}>
          <div className={classes.intro}>
            <h3 className={classes.descriptionHeader}>Description</h3>
            <p className={classes.descriptionText}>{playlist.description}</p>
          </div>
        </Grid>{" "}
        <Grid className={classes.buttons} item={true} xs={4} sx={4} md={4} lg={4} xl={4}>
          <div className={classes.buttons}>
            <Button
              className={classes.leftButton}
              variant="contained"
              color="primary"
              onClick={() => setEditModalOpen(true)}
            >
              Edit Playlist
            </Button>
            <Button variant="contained" color="secondary" onClick={() => setDeleteModalOpen(true)}>
              Delete Playlist
            </Button>
          </div>
        </Grid>
      </Grid>

      <LeadsList listLeads={playlistCompanies} removeLeadsFromTable={removeLeadsFromTable} />
    </div>
  );
};
