import React from "react";
import MaterialTable from "material-table";
import { useTableStyles, tableIcons } from "./tableTools";

import { TableDetailPanel } from "./tableDetailPanel";

export const DEFAULT_TABLE_OPTIONS = {
  filtering: true,
  sorting: true,
  selection: true,
  exportButton: true,
  // toolbar: false,
  pageSize: 10,
  search: false,
  pageSizeOptions: [10, 25, 50, 100, 500],
};

export function DynamicTable({ columns, data, isLoading, actions, updateTableData }) {
  const classes = useTableStyles();
  const { table } = classes;

  return (
    <div className={table}>
      <MaterialTable
        icons={tableIcons}
        options={DEFAULT_TABLE_OPTIONS}
        isLoading={isLoading}
        actions={actions}
        columns={columns}
        data={data}
        title=""
        detailPanel={(rowData) => <TableDetailPanel company={rowData} updateTableData={updateTableData} />}
      />
    </div>
  );
}
