import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Send as SendIcon, NotInterested as NotInterestedIcon, Add as AddIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useSnackbar } from "notistack";

import { PlaylistPickerModal } from "./PlaylistPicker";
import { markUnqualified } from "../utilities/ApiFetcher";
import { createSalesforceUrlFromLeadId, handleLinkClick } from "../utilities/navigationUtils";

const useStyles = makeStyles({
  externalLinkedModule: {
    margin: "auto",
    width: "fit-content",
    borderRadius: 10,
    marginTop: 20,
    padding: 10,
  },
  standardButton: {
    fontFamily: "Mukta",
    fontWeight: 700,
    color: "fefefe",
    borderRadius: 10,
    width: "fit-content",
    fontSize: 12,
  },
  cbButton: {
    backgroundColor: "#0060ff",
  },
  liButton: {
    backgroundColor: "#0274b3",
  },
  apolloButton: {
    backgroundColor: "#faa960",
  },
  sfButton: {
    backgroundColor: "#1798c1",
  },
  unqualifiedButton: {
    backgroundColor: "#f05743",
  },
  playlistButton: {
    backgroundColor: "#6f42c1",
  },
});

export const ExternalCompanyLinks = ({ companyBasics, removeLeadsFromList, linkSpacing }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [playlistModalOpen, setPlaylistModalOpen] = useState(false);

  const { sf_lead_id, cb_url, linkedin_url, apollo_url } = companyBasics;

  const handleUnqualifiedClick = async (event, target) => {
    event.preventDefault();

    const fidList = [companyBasics.fid];
    await markUnqualified(fidList);
    enqueueSnackbar("Marked as Unqualified!", { variant: "success" });
    removeLeadsFromList(fidList);
  };

  return (
    <div className={classes.externalLinkedModule}>
      <Stack direction="row" spacing={linkSpacing}>
        <CrunchbaseButton cb_url={cb_url} />
        <LinkedInButton linkedin_url={linkedin_url} />
        <SalesforceButton sf_lead_id={sf_lead_id} />
        <ApolloButton apollo_url={apollo_url} />
        <UnqualifiedButton action={handleUnqualifiedClick} />
        <PlaylistButton action={setPlaylistModalOpen} />
      </Stack>

      <PlaylistPickerModal open={playlistModalOpen} setOpen={setPlaylistModalOpen} companies={[companyBasics]} />
    </div>
  );
};

export const CrunchbaseButton = ({ cb_url }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={[classes.standardButton, classes.cbButton]}
      disabled={!cb_url}
      onClick={handleLinkClick(cb_url)}
    >
      Crunchbase
    </Button>
  );
};

export const LinkedInButton = ({ linkedin_url }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={[classes.standardButton, classes.liButton]}
      disabled={!linkedin_url}
      onClick={handleLinkClick(linkedin_url)}
    >
      Linkedin
    </Button>
  );
};

export const SalesforceButton = ({ sf_lead_id }) => {
  const classes = useStyles();
  const sfUrl = sf_lead_id ? createSalesforceUrlFromLeadId(sf_lead_id) : undefined;

  return (
    <Button
      variant="contained"
      className={[classes.standardButton, classes.sfButton]}
      disabled={!sfUrl}
      onClick={handleLinkClick(sfUrl)}
    >
      Salesforce
    </Button>
  );
};

export const ApolloButton = ({ apollo_url }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      endIcon={<SendIcon />}
      onClick={handleLinkClick(apollo_url)}
      className={[classes.standardButton, classes.apolloButton]}
      disabled={!apollo_url}
    >
      Apollo
    </Button>
  );
};

export const UnqualifiedButton = ({ action }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      endIcon={<NotInterestedIcon />}
      onClick={action}
      className={[classes.standardButton, classes.unqualifiedButton]}
    >
      Unqualified
    </Button>
  );
};

export const PlaylistButton = ({ action }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      endIcon={<AddIcon />}
      onClick={action}
      className={[classes.standardButton, classes.playlistButton]}
    >
      Playlist
    </Button>
  );
};
