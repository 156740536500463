import { makeStyles, Typography } from "@material-ui/core";
import { Subtitle } from "../Text";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(5),
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(4),
    fontSize: 52,
  },
}));

export const RankTextDisplay = ({ original_co, stats }) => {
  const classes = useStyles();
  const { summary_stats } = stats;
  const { original_rank, num_comps, num_recommended_comps } = summary_stats;
  const { name } = original_co;

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        <b>{"#" + original_rank.toString()}</b>
      </Typography>
      <Subtitle>
        <b>{name + " Rank"}</b>
      </Subtitle>
      <Subtitle>
        <b>{num_comps}</b> Total Competitors Found{" "}
      </Subtitle>
      <Subtitle>
        <b>{num_recommended_comps}</b> Recommended Competitors Found{" "}
      </Subtitle>
    </div>
  );
};
