import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { Title } from "../components/Text";
import { Page as PageComponent } from "../components/page";
import { NewLeadsTable } from "../containers/NewLeadsTable";
import { MercuryMiniStats } from "../containers/MercuryMiniStats";
import { MercuryHitRateStats } from "../containers/MercuryHitRateStats";

const useStyles = makeStyles((_theme) => ({
  intro: {
    marginLeft: 30,
    paddingTop: 40,
  },
  introRight: {
    paddingTop: 40,
    marginRight: 30,
  },
}));

export function NewLeadsPage() {
  const classes = useStyles();

  return (
    <PageComponent>
      <Grid container={true}>
        <Grid item={true} xs={8} sx={8} md={8} lg={8} xl={8}>
          <div className={classes.intro}>
            <Title text={"Recommended Leads"} />
            <MercuryMiniStats />
          </div>
        </Grid>

        <Grid item={true} xs={4} sx={4} md={4} lg={4} xl={4}>
          <div className={classes.introRight}>
            <MercuryHitRateStats />
          </div>
        </Grid>
      </Grid>

      <NewLeadsTable />
    </PageComponent>
  );
}
