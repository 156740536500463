import { Route, Redirect } from "react-router-dom";

export function ProtectedRoute({ path, exact, isAuthenticated, children }) {
  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        return isAuthenticated ? children : <Redirect to={{ pathname: `/login` }} />;
      }}
    />
  );
}
