import { useEffect, useState } from "react";
import { TitledList } from "../components/TitledList";
import { getRulesetLeads } from "../utilities/ApiFetcher";

/**
 * A component that loads a list of companies from a ruleset or rulesets.
 * @param {string} title - The title of the list.
 * @param {array} rulesets - The list of rulesets to load companies from.
 * @returns {JSX.Element}
 *
 * @example
 * <RulesetFivePack title="Bootstrapped B2B SaaS" rulesets={["Bootstrapped B2B SaaS"]} />
 * <RulesetFivePack title="Bootstrapped Companies" rulesets={["Bootstrapped B2B SaaS", "Bootstrapped Marketplaces"]} />
 */
export function RulesetFivePack({ title, rulesets }) {
  const [companies, setCompanies] = useState([]);
  const encodedRulesetString = encodeURIComponent(rulesets.join("&"));

  useEffect(() => {
    (async () => {
      const result = await getRulesetLeads(rulesets);
      const resultWithIds = result.map((company) => ({ ...company, id: company.fid }));
      setCompanies(resultWithIds);
    })();
  }, []);

  return (
    <TitledList
      title={title}
      items={companies}
      typeSlug={"company"}
      showDescriptions={true}
      titleUrl={`/ruleset-leads/${encodedRulesetString}`}
    />
  );
}
