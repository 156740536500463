import { Link } from "react-router-dom";
import { Box, Card, Grid, makeStyles } from "@material-ui/core";

import { LoadingCircle } from "./loadingCircle";
import { emailToName } from "../utilities/TextUtilities";
import React from "react";
import { Subtitle } from "./Text";
import { PageSelectionComponent } from "./pageSelectionComponent";
import { PageSizeSelectionComponent } from "./pageSizeSelectionComponent";

const useStyles = makeStyles((theme) => ({
  containerGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "masonry",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  card: {
    backgroundColor: theme.palette.grey[100],
    width: "100%",
    borderRadius: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cardTitle: {
    color: theme.palette.common.white,
    fontSize: 20,
    marginTop: 0,
    marginBottom: 0,
    textAlign: "left",
  },
  cardOwner: {
    fontSize: 16,
    color: theme.palette.common.white,
    margin: 0,
    textAlign: "center",
  },
  cardBody: {
    padding: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
  },
  cardDescription: {
    fontSize: 16,
    textAlign: "left",
    gridColumnStart: 1,
    gridColumnEnd: 7,
  },
  cardLink: {
    textDecoration: "none",
  },
  pageSizeControl: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
    marginRight: 30,
  },
  pageControl: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
    marginTop: 20,
    marginRight: 30,
  },
  pageControlBottom: {
    width: "20%",
    margin: "auto",
    textAlign: "center",
    marginTop: 20,
    paddingBottom: 40,
  },
}));

export const PlaylistIndexCards = ({ playlists, isLoading, error }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageSize, setPageSize] = React.useState(30);

  const minIndex = (currentPage - 1) * currentPageSize; // Starting at 1
  const pagePlaylists = playlists.slice(minIndex, minIndex + currentPageSize);

  const maxPage = Math.ceil(playlists.length / currentPageSize);
  const pageRange = Array.from({ length: maxPage }, (_, i) => i + 1);
  const hasMoreLeads = currentPage < maxPage;

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (pageNum) => (event) => {
    setCurrentPage(pageNum);
  };

  if (isLoading) {
    return <LoadingCircle />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div className={classes.companyContainer}>
      <div className={classes.pageSizeControl}>
        <Box sx={{ minWidth: 120, maxWidth: 150 }}>
          <PageSizeSelectionComponent pageSize={currentPageSize} handlePageSizeChange={handlePageSizeChange} />
        </Box>
      </div>

      <div className={classes.pageControl}>
        <PageSelectionComponent pageRange={pageRange} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>

      <Grid container className={classes.containerGrid} spacing={4}>
        {pagePlaylists.map((playlist, index) => (
          <Grid item key={index}>
            <PlaylistIndexCard playlist={playlist} />
          </Grid>
        ))}
      </Grid>

      <div className={classes.pageControlBottom}>
        {hasMoreLeads && <Subtitle text={`See More`} />}
        <PageSelectionComponent pageRange={pageRange} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>
    </div>
  );
};

const PlaylistIndexCard = ({ playlist }) => {
  const classes = useStyles();
  const { id, name, owner, created_at, description } = playlist;
  const descriptionString = description
    ? description.length > 140
      ? description.slice(0, 140) + "..."
      : description
    : "";

  return (
    <Link to={`/playlists/${id}`} className={classes.cardLink}>
      <Card className={classes.card}>
        <div className={classes.cardHeader}>
          <h2 className={classes.cardTitle}>{name}</h2>
          <p className={classes.cardOwner}>
            {emailToName(owner)} - {new Date(created_at).toLocaleDateString()}
          </p>
        </div>

        <div className={classes.cardBody}>
          <p className={classes.cardDescription}>{descriptionString}</p>
        </div>
      </Card>
    </Link>
  );
};
