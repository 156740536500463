import { LinkSharp } from "@mui/icons-material";
import { Link } from "react-router-dom";

/**
 * A component that renders a list of companies or playlists
 * @param {string} title - The title of the list.
 * @param {array} items - The list of companies or playlists
 * @param {boolean} showDescriptions - Whether or not to show descriptions of the items.
 * @returns {JSX.Element}
 *
 * @example
 * <TitledList title="Bootstrapped Leads" items={companies} showDescriptions={true} />
 * <TitledList title="Interesting Lists" items={playlists} />
 */
export function TitledList({ title, items, typeSlug = "company", showDescriptions = false, titleUrl = null }) {
  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <h2 style={{ fontSize: "large" }}>
        {title}{" "}
        {titleUrl && (
          <Link to={titleUrl}>
            <LinkSharp />
          </Link>
        )}
      </h2>

      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        {items.map((item, index) => (
          <li key={index} style={{ marginBottom: 10 }}>
            <Link to={`/${typeSlug}/${item.id}`}>{item.name}</Link>
            {showDescriptions && (
              <span style={{ paddingLeft: 8 }}>
                {item.description?.slice(0, 75)}
                {item.description?.length > 75 && "..."}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
