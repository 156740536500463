import { Button, Dialog, InputLabel, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    padding: 20,
    height: 350,
    width: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  dialogTitle: {
    padding: 0,
    margin: 0,
    fontSize: 20,
    color: "white",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dialogHeader: {
    backgroundColor: theme.palette.primary.dark,
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
}));

export const EditPlaylistModal = ({ open, setOpen, name, setName, description, setDescription, handleSubmit }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <div className={classes.dialogHeader}>
        <h2 className={classes.dialogTitle}>Edit Playlist</h2>
      </div>
      <form className={classes.dialogBody} onSubmit={handleSubmit}>
        <InputLabel>Playlist Name</InputLabel>
        <TextField value={name} onChange={(e) => setName(e.target.value)} />
        <InputLabel>Playlist Description</InputLabel>
        <TextField multiline={true} value={description} onChange={(e) => setDescription(e.target.value)} />
        <Button type="submit" variant="contained" color="primary">
          Edit Playlist Metadata
        </Button>
      </form>
    </Dialog>
  );
};
