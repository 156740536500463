import { Button, Dialog, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    padding: 20,
    height: 150,
    width: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  dialogTitle: {
    padding: 0,
    margin: 0,
    fontSize: 20,
    color: "white",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dialogHeader: {
    backgroundColor: theme.palette.primary.dark,
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
}));

export const DeletePlaylistModal = ({ open, setOpen, handleSubmit }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <div className={classes.dialogHeader}>
        <h2 className={classes.dialogTitle}>Delete This Playlist?</h2>
      </div>
      <form className={classes.dialogBody} onSubmit={handleSubmit}>
        <Button type="submit" variant="contained" color="secondary">
          Delete Playlist
        </Button>
      </form>
    </Dialog>
  );
};
