import MaterialTable from "material-table";

import {
  ALL_COLUMNS,
  cellStyle,
  headerStyle,
  narrowCellStyle,
  narrowHeaderStyle,
  tableIcons,
  wideCellStyle,
  wideHeaderStyle,
} from "./tableTools";
import { prettyPrintDate, prettyPrintDollarAmounts } from "../../utilities/TextUtilities";
import { createInvestorLinkFromName } from "./MarketInvestorTable";

const getInvestorLinks = (investors) => {
  let tmp = investors.split(",");
  tmp = tmp.map((investor) => investor.trim());
  tmp = tmp.filter((investor) => investor !== "" && investor !== "nan");
  const result = tmp.map((investor, i) => [i > 0 && ",  ", createInvestorLinkFromName(investor)]);
  return result;
};

const COLUMNS = [
  ALL_COLUMNS.COMPANY,
  {
    title: "Deal Date",
    render: (row) => prettyPrintDate(row.deal_date),
    cellStyle,
    headerStyle,
  },
  {
    title: "Est. Invested",
    render: (row) => prettyPrintDollarAmounts(row.deal_size),
    cellStyle: narrowCellStyle,
    headerStyle: narrowHeaderStyle,
  },
  {
    title: "Currency",
    field: "currency",
    cellStyle: narrowCellStyle,
    headerStyle: narrowHeaderStyle,
  },
  {
    title: "Raised to Date",
    render: (row) => prettyPrintDollarAmounts(row.raised_to_date),
    cellStyle: narrowCellStyle,
    headerStyle: narrowHeaderStyle,
  },
  {
    title: "All Investors",
    render: (row) => getInvestorLinks(row.investors),
    cellStyle: wideCellStyle,
    headerStyle: wideHeaderStyle,
  },
];

export const DealsTable = ({ deals }) => {
  return (
    <MaterialTable
      icons={tableIcons}
      columns={COLUMNS}
      data={deals}
      options={{
        toolbar: false,
        draggable: false,
        filtering: false,
        exportButton: false,
        pageSize: 8,
        pageSizeOptions: [8, 10, 20],
      }}
    />
  );
};
